import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import canadaLogo from "../../../../assets/images/white-label/canada.png";
import usLogo from "../../../../assets/images/white-label/united-states.png";
import LocaleModal from "../../../modals/locale-modal";
import ReactTooltip from 'react-tooltip';
import Util from "../../../../util";
import "./locales.scss";

const Locales = (props) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [isTooltipOpen, setTooltipOpen] = React.useState(false)
  const cart = useSelector((state) => state.cartList.cart);
  const tooltipWrapperRef = React.useRef(null)
  const flagButtonStyles = {
    display: "flex",
    border: "none",
    background: "none",
    alignItems: "center",
    padding: "0px",
  };

  useEffect(() => {
    const languageTooltip = Util.getCookieByName("languageTooltip");
    const tooltipWrapperEl = tooltipWrapperRef.current;
    if (tooltipWrapperEl && !isTooltipOpen && languageTooltip !== "true") {
      ReactTooltip.show(tooltipWrapperEl);
      setTooltipOpen(true);
      setTimeout(() => {
        document.cookie="languageTooltip=true";
        ReactTooltip.hide(tooltipWrapperEl);
      }, 5000);
    }
  }, [isTooltipOpen]);

  // if clicks are detected outside the locale selector, close it and remove the listener
  const closeSelectListen = (e) => {
    if (e.target.className !== "locale-select-element") {
      setSelectOpen(false);
      document.removeEventListener("click", closeSelectListen);
    }
  };
  // if items are in cart, show the warning modal, otherwise redirect
  const handleLocaleSelect = (locale) => {
    setSelectedLocale(locale);
    if (cart.length) {
      setModalOpen(!modalOpen);
    } else {
      handleLocaleRedirect(locale);
    }
  };
  //redirect user based off selected locale. In the case of a click directly from the selector, we pass in the locale because of the async nature of setting state
  const handleLocaleRedirect = (locale) => {
    const localeToUse = locale ? locale : selectedLocale;
    let isProductURL = window.location.pathname.includes("/products/");
    let redirectPath = "";
    if (isProductURL) {
      redirectPath = window.location.pathname;
    }

    switch (localeToUse) {
      case "US":
        redirectPath = redirectPath.replace("/ca-gfsimpress-", "/gfs-");
        redirectPath = redirectPath.replace("/fr-gfsimpress-", "/gfs-");
        window.location.assign(`https://www.gfsimpress.com${redirectPath}`);
        break;
      case "CA":
        redirectPath = redirectPath.replace("/gfs-", "/ca-gfsimpress-");
        redirectPath = redirectPath.replace("/fr-gfsimpress-", "/ca-gfsimpress-");
        window.location.assign(`https://ca.gfsimpress.com${redirectPath}`);
        break;
      case "FR":
        redirectPath = redirectPath.replace("/ca-gfsimpress-", "/fr-gfsimpress-");
        redirectPath = redirectPath.replace("/gfs-", "/fr-gfsimpress-");
        window.location.assign(`https://fr.gfsimpress.com${redirectPath}`); 
        break;
    }
  };
  // because we need to know specifically which site we're on, not just the language, pull the locale from the url
  let currentLocale =
    window.location.href.indexOf("ca.gfsimpress.com") > -1
      ? "CA"
      : window.location.href.indexOf("fr.gfsimpress.com") > -1
      ? "FR"
      : "US";
  // TESTING NOTE: to test alternate locales, modify the currentLocale variable here.
  return (
    <>
      {!props.sidebar && <ReactTooltip 
        id="locale-container-tooltip" 
        multiline={true} 
        className="locale-tooltip" 
        backgroundColor="#B2B2B2" 
        arrowColor="#B2B2B2"
        effect="solid"
        place="bottom"
        borderColor="black"
        border={true}
        offset={{top: 15, left: 85}}
        html={true}
        /> }
      <li ref={tooltipWrapperRef}
        className={`onhover-div mobile-cart ${
          props.sidebar ? "locale-container-sidebar" : "locale-container"
        }`}
        data-for="locale-container-tooltip"
        data-tip="<p class='tooltip-text tooltip-english'>Select your region and language</p><p class='tooltip-text tooltip-french'><i>Sélectionnez votre région et votre langue</i></p>"
        data-html={true}
      >
        <div
          style={{
            position: "relative",
            fontSize: "12px",
            color: "#666666",
          }}
        >
          <button
            onClick={() => {
              
              const tooltipWrapperEl = tooltipWrapperRef.current;
              if (tooltipWrapperEl) {
                ReactTooltip.hide(tooltipWrapperEl);
              }
              setSelectOpen(!selectOpen);
              // since the select is like a small modal, we need to add a listener for clicks outside the selector
              document.addEventListener("click", closeSelectListen);
            }}
            style={flagButtonStyles}
          >
            <img
              style={{ height: "18px", display: "block" }}
              src={currentLocale === "US" ? usLogo : canadaLogo}
            ></img>
            <p
              style={{
                marginLeft: "4px",
                marginBottom: "0",
                lineHeight: "normal",
                fontSize: "12px",
              }}
            >
              {currentLocale === "FR" ? "FR" : "EN"}
            </p>
          </button>
          {selectOpen && (
            <div
              style={{
                position: "absolute",
                top: "-12px",
                right: props.sidebar ? "auto" : "0px",
                left: props.sidebar ? "0px" : "auto",
                background: "#FFFFFF",
                boxShadow: "0px 2px 4px 0px #00000026",
                border: "1px solid #DDDDDD",
                padding: "13px 16px",
                height: "110px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              className="locale-select-element"
            >
              <button
                onClick={() => {
                  handleLocaleSelect("US");
                }}
                style={flagButtonStyles}
                className="locale-select-element"
              >
                <img
                  className="locale-select-element"
                  style={{ height: "18px", display: "block" }}
                  src={usLogo}
                ></img>
                <span
                  className="locale-select-element"
                  style={{ marginLeft: "4px" }}
                >
                  English
                </span>
              </button>
              <button
                onClick={() => {
                  handleLocaleSelect("CA");
                }}
                style={flagButtonStyles}
                className="locale-select-element"
              >
                <img
                  className="locale-select-element"
                  style={{ height: "18px", display: "block" }}
                  src={canadaLogo}
                ></img>
                <span
                  className="locale-select-element"
                  style={{ marginLeft: "4px" }}
                >
                  English
                </span>
              </button>
              <button
                onClick={() => {
                  handleLocaleSelect("FR");
                }}
                style={flagButtonStyles}
                className="locale-select-element"
              >
                <img
                  className="locale-select-element"
                  style={{ height: "18px", display: "block" }}
                  src={canadaLogo}
                ></img>
                <span
                  className="locale-select-element"
                  style={{ marginLeft: "4px" }}
                >
                  {currentLocale === "FR" ? "Français" : "French"}
                </span>
              </button>
            </div>
          )}
        </div>
        <LocaleModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          currentLocale={currentLocale}
          selectedLocale={selectedLocale}
          handleLocaleRedirect={handleLocaleRedirect}
        />
      </li>
    </>
  );
};

export default Locales;
