let url = "https://designer.readysetprint.com/apps/design-editor/stable";
var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
async function uploadFile(data, user, token) {
    if (base64regex.test(user)) {
        user = atob(user).split("/")[4];
    }else {
        user = btoa(user);
    }
    let response = await fetch(url + "/api/Gallery/Private/"+user+"/files?OverwriteExistingFiles=true", {
        method:"POST",
        body: data,
        headers: {
            "X-CCToken": token
        },
        processData: false,
        contentType: false
    })
    let respData = await response.json();
    return respData;
}

async function getOrCreateUserToken(user) {
    if (base64regex.test(user)) {
        user = atob(user).split("/")[4];
    }else {
        user = btoa(user);
    }
    let getTokenResponse = await fetch(url + '/api/Auth/Users/' + user + '/Tokens', {
        method:"GET"
    });
    if (getTokenResponse.status !== 403) {
        let tokenResponseData = await getTokenResponse.json();
        if (tokenResponseData.tokens === undefined || tokenResponseData.tokens.length == 0) {
            let createTokenResponse = await fetch(url + '/api/Auth/Users/' + user + '/Tokens', {
                method:"POST"
            });
            if (createTokenResponse.status !== 403) {
                let createTokenResponseData = await createTokenResponse.json();
                return createTokenResponseData.tokenId;
            } else {
                return false;
            }
        } else {
            return tokenResponseData.tokens[0].tokenId;
        }
    } else {
        return false;
    }
}

async function initCanvasUpload(data, user) {
    let userToken = await getOrCreateUserToken(user);
    if (userToken) {
        let fileData = await uploadFile(data, user, userToken);
        return [fileData, userToken];
    } else {
        return [userToken];
    }
}

export const canvas = {
    uploadFile: uploadFile,
    getOrCreateUserToken: getOrCreateUserToken,
    initCanvasUpload: initCanvasUpload
}