import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import canadaLogo from "../../assets/images/white-label/canada.png";
import usLogo from "../../assets/images/white-label/united-states.png";

const LocaleModal = ({
  open,
  onClose,
  currentLocale,
  selectedLocale,
  handleLocaleRedirect,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          alignItems: "center",
          maxWidth: "280px",
          textAlign: "center",
        }}
      >
        <img
          style={{ height: "100px", width: "100px" }}
          src={selectedLocale === "US" ? usLogo : canadaLogo}
        />
        <p
          style={{
            fontSize: "20px",
            fontWeight: "600",
            marginTop: "20px",
            marginBottom: "8px",
          }}
        >
          {currentLocale === "FR"
            ? "Etes-vous sûr de vouloir changer de langue?"
            : "Are you sure you want to switch languages?"}
        </p>
        <p style={{ fontSize: "16px", fontWeight: "500" }}>
          {currentLocale === "FR"
            ? "Si vous changez de langue, le contenu de votre panier sera perdu - le contenu du panier ne sera pas transféré entre les sites."
            : "If you switch languages then your cart content will be lost - cart contents will not transfer between sites."}
        </p>
        <button
          className="btn btn-primary"
          style={{
            width: "250px",
            paddingTop: "18px",
            paddingBottom: "18px",
            fontSize: "16px",
          }}
          onClick={() => {
            handleLocaleRedirect();
          }}
        >
          {currentLocale === "FR" ? "CONFIRMER" : "CONFIRM"}
        </button>
      </div>
    </Modal>
  );
};

export default LocaleModal;
