import React, { Component } from 'react';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import { Link } from 'react-router-dom'

class Breadcrumb extends Component {
    render() {
        const { title, parent, subTitle, translate } = this.props;
        return (
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb" className="theme-breadcrumb">
                                <ol className="breadcrumb" style={{ paddingLeft: '0px' }}>

                                    <li className="breadcrumb-item">
                                        <Link to={"/"} > {translate("HOME")} </Link>
                                    </li>
                                    {parent ?
                                        <li className="breadcrumb-item" aria-current="page">{parent}</li> : ''}
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                    <li className="breadcrumb-item active" aria-current="page">{subTitle}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(Breadcrumb);
