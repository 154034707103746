import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import SiteSearchAPIConnector from "@elastic/search-ui-site-search-connector";
import { SearchProvider, WithSearch, Sorting} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import Breadcrumb from "../common/breadcrumb";
import Util from '../../util';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

const connector = new SiteSearchAPIConnector({
  documentType: "page",
  engineKey: "1eVvXmmSsbKbzJygtkxp"
});

class Search extends Component {

    constructor (props) {
        super (props);

        var hostSplits = window.location.host.split('.');
        const reseller = Util.getReseller();
        this.state = {
            sortOpen: false,
            reseller,
        }
    }

    sortCompontent({onChange, options, value}) {
        return(
            <div className="product-filter-content d-flex justify-content-end">
               <div className="product-page-filter">
                    <select style={{padding: '0px !important', border:0}} onChange={(e) => onChange(e.target.value)} >
                        {options.map((option, i) => {
                            return (<option value={option.value} key={option.value} defaultValue={value}>{option.label}</option>);
                        })}
                    </select>
                </div>
            </div>
        );
    }

    render (){
        const { translate } = this.props;
        return (
            <div>
            <Breadcrumb title={'Search'}/>
            { this.state.reseller != "" ? (
                <SearchProvider config={{ 
                    apiConnector: connector,
                    searchQuery: {
                        resultsPerPage: 999,
                        filters: [
                            { field: "reseller", values: [this.state.reseller], type: "all" }
                        ]
                    },
                }}>
                    <WithSearch mapContextToProps={({ searchTerm, isLoading, results }) => ({ searchTerm, isLoading, results})}>
                    {({ searchTerm, isLoading, results }) => {
                        return (
                            <div className="container mb-5 collection-product-wrapper">
                                {isLoading? (
                                    <section className="search-results category-page">
                                            <div className="main-contain">
                                                <div>
                                                    <h1 className="text-center mb-5">Searching results for "{searchTerm}"...</h1>
                                                </div>
                                            </div>
                                    </section>
                                ):(
                                    results.length?(
                                        <section className="search-results category-page">
                                            <div className="main-contain">
                                                <div>
                                                    <h1 className="text-center mb-5">Search Results for "{searchTerm}"</h1>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="product-top-filter" style={{border:0}}>
                                                <Sorting
                                                  sortOptions={[
                                                    {
                                                      name: "Relevance",
                                                      value: "",
                                                      direction: ""
                                                    },
                                                    {
                                                      name: "Title",
                                                      value: "title",
                                                      direction: "asc"
                                                    }
                                                  ]}
                                                  view={this.sortCompontent}
                                                />
                                                </div>
                                                <div className="row">
                                                    {results.map(r => {
                                                        return( 
                                                            <div className="col-6 col-md-4 col-lg-3" key={r.id.raw}>
                                                                <div className="product-box pb-5">
                                                                    <div className="img-wrapper pb-1">
                                                                        <div className="front">
                                                                            <Link to={`${process.env.PUBLIC_URL}/products/${r.product_handle.raw}`} ><img
                                                                                src={r.image.raw}
                                                                                className="img-fluid"
                                                                                alt={r.title.raw} /></Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-detail">
                                                                        <div>
                                                                            <Link to={`${process.env.PUBLIC_URL}/products/${r.product_handle.raw}`}>
                                                                                <h6>{r.title.raw}</h6>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </section>
                                    ):(
                                        <section className="search-results">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                                        <h3>Sorry! We couldn't find any results for "{searchTerm}"!!!    </h3>
                                                        <p>Please check if you have misspelt something or try searching with other words.</p>
                                                        <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">{translate("continue shopping")}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )
                                )}
                            </div>
                        );
                    }}
                </WithSearch>
                </SearchProvider>
            ):(<div></div>)}
          </div>
        )
    }
}

export default withTranslate(Search)