import React, { Component } from 'react';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

const allowedProperties = ['Size', 'Material', 'Sides', 'overlamination', 'foil_stamping', 'matte', 'tape', 'imprint', 'numbering'];

class ProductDescription extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { product, translate, specs } = this.props;
    const propNodes = Object.keys(product.properties).map(property => {
      if(allowedProperties.includes(property)){
        if(property === 'overlamination'){
          return product.properties[property] === true?(<p key={`prop-${property}`}>Overlamination: Yes </p>):'';
        }
        if(property === 'foil_stamping'){
          return product.properties[property] === true?(<p key={`prop-${property}`}>Foil Stamping: Yes </p>):'';
        }
        if(property === 'matte'){
          return product.properties[property] === true?(<p key={`prop-${property}`}>Matte Varnish: Yes </p>):'';
        }
        if(property === 'tape'){
          return product.properties[property] === true?(<p key={`prop-${property}`}>Double Sided Tape: Yes </p>):'';
        }
        if(property === 'imprint'){
          return product.properties[property] === true?(<p key={`prop-${property}`}>Back Imprint: Yes </p>):'';
        }
        if(property === 'numbering'){
          return product.properties[property] === true?(<p key={`prop-${property}`}>Numbering: Yes </p>):'';
        }
        if(property === 'Size'
          && product.properties[property] === 'custom'
          && Object.keys(product.properties).includes('customWidth') && Object.keys(product.properties).includes('customHeight')
          ){
          return <p key={`prop-${property}`}>{translate("Size")}: {product.properties['customWidth']}" x {product.properties['customHeight']}" </p>;
        }else{
          return (<p key={`prop-${property}`}>{translate(property)}: {product.properties[property]} </p>);
        }
      } else {
        return null;
      }
    });
    return (
      <div className="section-product-description">
        <div className="d-flex align-items-center">
          {
            (typeof product.productData.images != "undefined" && product.productData.images.edges.length > 0)?
              (<img src={
                product.properties.thumbnail ? product.properties.thumbnail : product.productData.images.edges[0].node.src
              } style={{width: '90px'}}/>):''
          }
          <div className="descrition-info ">
            <p className="info-title"><a href={"/products/"+product.productData.handle}>{product.productData.title}</a></p>
            { propNodes }
            <p>{translate('Production Time')}: {specs[translate('Production Time')]} </p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslate(ProductDescription);
