import React from 'react';
import { Link } from 'react-router-dom'

function LogoImage(props) {

    return <Link to={`${process.env.PUBLIC_URL}/`}>
        <img alt="Logo" src={props.logo.includes("http") ? props.logo : `${process.env.PUBLIC_URL}/assets/images/icon/${props.logo}`} className="img-fluid" id="logo"  />
    </Link>;
}

export default LogoImage;