import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Util from "../../../../util"

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' },
            primaryMenu: { items: [] }
        }
    }

    componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    componentDidMount = async () => {
        
        let menu = window.Menu;
        if (process.env.REACT_APP_RESELLER_ID) {
            menu = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Menu", "Menu");
        }

        if (menu) {
            const primaryMenu = menu.find(m => m.id === "primary-menu")

            this.setState({ primaryMenu })
        }

    }

    openNav() {
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    onMouseEnterHandler() {
    }

    handleSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if (event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else {
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
            document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if (event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else {
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    render() {
        const { primaryMenu } = this.state
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav" >
                        <ul className="nav-menu" style={{ right: '-410px' }}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)} >
                                <div className="mobile-back text-right">
                                    <span >Back</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>
                            {primaryMenu.items.map((item, index) => {
                                return <li key={`navbar-${index}`}>
                                    {item.link && item.link !== "" && 
                                        <>
                                            {item.link && item.link.includes("http") ? <a href={item.link} className="nav-link">{item.title}</a> :
                                                <Link to={item.link} className="nav-link" >
                                                    {item.title}
                                                </Link>
                                            }
                                        </>
                                    }
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(NavBar);
