import {
    RECEIVE_POPULAR_PRODUCTS } from "../constants/ActionTypes";


const initialState = {
    products: [],
    symbol: '$',
    product_details: []
};

const popularProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_POPULAR_PRODUCTS:
            return { ...state,
                products: action.products };
        default:
            return state;
    }
};
export default popularProductReducer;
