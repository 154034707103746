import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../common/breadcrumb';
import SortingBar from './common/sorting-bar';
import { client } from '../../index';
import * as storefrontQuerys from '../../graphql/querys';
import store from '../../store';
import { updateCategoryAndProducts, updateCategoryProducts } from '../../actions';
import ProductItem from '../layouts/fashion/components/product-item';
import { get } from 'lodash';
import Util from '../../util.js'
import { withRouter } from 'react-router-dom';
import Loader from '../loader/loader';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

const CategoryDetailsPage = ({ history, translate }) => {

    const params = useParams();
    const { handle } = params;

    const [, setLayoutColumns] = useState(3);
    const { products, category } = useSelector(state => ({
        products: state.category.products,
        category: state.category.category,
    }));
    const { sortBy } = useSelector(state => state.filters);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let { handle } = params;

        //Fetch reseller from URL
        const reseller = Util.getReseller();
        //Prepend reseller to handle for fetching category
        if (reseller !== "") handle = `${reseller}-${handle}`;
        client.send(storefrontQuerys.getCollectionByHandleQuery(handle)).then(({ model }) => {
            if (model.collectionByHandle) {
                const category = { title: model.collectionByHandle.title, description: model.collectionByHandle.description };
                const products = model.collectionByHandle.products;
                store.dispatch(updateCategoryAndProducts({ category, products: sortProducts(products) }));
                setTimeout(() => window.analytics.page(), 1000);
                setLoading(false);
            } else {
                history.push("/pages/404");
                return;
            }
        }).catch(e => {
            console.log(e);
        })
    }, [handle]);

    const LayoutViewClicked = (columns) => {
        setLayoutColumns(columns);
    };

    const sortProducts = (products) => {
        return products.slice().sort((p1, p2) => {
            //Get all available variants for each product to compare price
            let p1AvailableVariants = p1.variants.filter(p => p.available === true);
            let p2AvailableVariants = p2.variants.filter(p => p.available === true);

            //In the case there are no available variants, the price will be compared on the first variant.
            if (p1AvailableVariants.length <= 0) p1AvailableVariants = p1.variants;
            if (p2AvailableVariants.length <= 0) p2AvailableVariants = p2.variants;

            if (sortBy === 'HighToLow' && p1AvailableVariants.length > 0 && p2AvailableVariants.length > 0)
                return p2AvailableVariants[0].price - p1AvailableVariants[0].price;
            if (sortBy === 'LowToHigh' && p1AvailableVariants.length > 0 && p2AvailableVariants.length > 0)
                return p1AvailableVariants[0].price - p2AvailableVariants[0].price;
            if (sortBy === 'AscOrder')
                return p1.title.localeCompare(p2.title);
            if (sortBy === 'DescOrder')
                return p2.title.localeCompare(p1.title);
            if (sortBy === 'Newest')
                return p2.createdAt.localeCompare(p1.createdAt);
        });
    }


    const title = category ? Util.cropSEOTitle(category.title) : '';
    const description = category ? Util.cropSEODescription(category.description) : '';

    return (
        <div>
            {/*SEO Support*/}
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            {/*SEO Support End */}

            <Breadcrumb title={category.title} />
            <section className="section-b-space category-page">

                {loading ? <Loader /> :
                    <div className="collection-wrapper">
                        <div className="container">
                            <h1 className="text-center desktop-title">{category.title}</h1>
                            <h2 className="text-center mobile-title mb-5"
                                style={{ display: "none", fontSize: "150%" }}>{category.title}</h2>
                            <p className="text-center mb-5" style={{ maxWidth: 800, margin: "auto" }}>{category.description}</p>
                            <div className="row">
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter" style={{ border: "none" }}>
                                                            <div className="container-fluid" style={{ paddingTop: 30 }}>

                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <SortingBar
                                                                            onLayoutViewClicked={(colmuns) => LayoutViewClicked(colmuns)}
                                                                            products={products}
                                                                            onProductsSorted={(products) => store.dispatch(updateCategoryProducts(products))}
                                                                            sortProducts={sortProducts}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {products.map((product, index) =>
                                                                <ProductItem
                                                                    product={{
                                                                        imgSrc: get(product, 'images[0].src'),
                                                                        price: product.variants[0].price,
                                                                        name: product.title,
                                                                        id: product.id,
                                                                        handle: product.handle

                                                                    }}
                                                                    classNames="col-xl-3 col-lg-4 col-md-6 col-6 mb-3 mb-md-5"
                                                                    symbol={"$"}
                                                                    key={index} />)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </section>

        </div>
    )

};

export default withRouter(withTranslate(CategoryDetailsPage))
