import React, { useCallback, useRef, useState } from "react";
import { addToCart as action_addToCart } from '../../../../actions/index'
import { connect,useSelector } from "react-redux";
import { withRouter } from "react-router";
import axios from 'axios';
import Util from "../../../../util";
import { toPng } from 'html-to-image';

function ArtworkCard({ item, addToCart, history, showDeleteModal,hideConfirmationModal }) {
  let artwork = JSON.parse(item.value);
  let metafieldId = item.id;
  let customerId = item.owner_id;
  let thumbnail = artwork.thumbnail;
  let print_ready_image = artwork.proof;
  const { metafields } = useSelector(state => state.customer.loggedInUserDetails);
  // if (print_ready_image) {
  //   let fileName_Index = print_ready_image.lastIndexOf("/") + 1;
  //   fileName = print_ready_image.substr(fileName_Index).replace(/[\#\?].*$/, '');
  // }
  const inputref = useRef(null);
  const downloadFile = (artwork,url) => {
    if (inputref.current === null) {
      return
    }
    
    toPng(inputref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = artwork.project_name +'.png';
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const addToCartClicked = () => {

    Util.reorderLineItem(artwork, addToCart).then(() => history.push("/cart"));

  }

  const openinDesigner = () => {  
  localStorage.setItem('customers_canvas', JSON.stringify(artwork));
  window.location.href = `/products/${artwork.product_handle}`;
  }

  return (
    <React.Fragment>
      <div className={"mb-4  col-sm-6 pl-0 pr-4"} style={{ minWidth: 280 }}>
        <div ref={inputref} className="w-100  card theme-card  pl-4 pr-4 pt-4" style={{ minHeight: 330 }}>
          <img
            className="card-img-top"
            style={{ maxHeight: 200, margin: "auto", width: "auto", maxWidth: "100%" }}
            src={thumbnail ? thumbnail : ""}
            alt="Card image cap"
          />
          <div className="card-body">
            { artwork.project_name &&
              <p className="card-title text-center m-0 h6">
                <b>{artwork.project_name}</b>
              </p> 
            } 
            <p style={{ fontSize: "12px" }} className="card-title text-center m-0 h6">
              Size: {artwork.size} | Qty: {artwork.quantity} 
            </p>    
            <p style={{ fontSize: "12px" }} className="card-title text-center m-0 h6">
              Material: {artwork.material}
            </p>
            {artwork.product_name &&
              <p style={{ fontSize: "12px" }} className="card-title text-center m-0 h6">
                Product: {artwork.product_name}
              </p>
            }
          </div>
        </div>
        <div className={"d-flex justify-content-around pt-4 hidden-xs-down"}>
          <div className=" w-50 pl-1 pr-1">
          <button type="button" className="btn btn-primary rounded w-100" onClick={openinDesigner}>
              <small>FINISH EDITING</small>
            </button>
          </div>

          <div className=" w-50 pl-1 pr-1">
          <button type="button" className="btn btn-primary rounded w-100"  onClick={() => downloadFile(artwork,print_ready_image ? print_ready_image : null)}>
            <small>DOWNLOAD PROOF</small>
              </button>
          </div>

          <div className=" w-50 pl-1 pr-1">
            <button type="button" className="btn btn-primary rounded w-100" onClick={() => showDeleteModal(metafieldId,customerId)}>
              <small>DELETE</small>
            </button>
          </div>
        </div>

        <div className={"row pt-4 hidden-sm-up"}>
          <div className=" w-100 pl-1 pr-1 mb-2">
            <button type="button" className="btn btn-primary rounded w-100" onClick={addToCartClicked}>
              <small>REORDER</small>
            </button>
          </div>

          <div className=" w-100 pl-1 pr-1">
            <a
              // href={item.variant.hiResProof}
              onClick={() => downloadFile(print_ready_image ? print_ready_image : null)}
              target={"blank"}
              className="btn btn-light text-primary rounded border w-100  "
            >
              <small>DOWNLOAD</small>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (_state) => {
  return {}
}


export default withRouter(connect(mapStateToProps, { addToCart: action_addToCart })(ArtworkCard));
