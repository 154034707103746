import React from "react";
import SvgIcon from "./svg-icon";
// Copy

export const IconMenu = props => (
  <SvgIcon
    viewBox="0 0 32 32"
    style={{ height: "32px", width: "32px", verticalAlign: 'middle' }}
    {...props}
  >
    <path d="M 7,16l 18,0 C 25.552,16, 26,15.552, 26,15C 26,14.448, 25.552,14, 25,14l-18,0 C 6.448,14, 6,14.448, 6,15 C 6,15.552, 6.448,16, 7,16zM 7,10l 18,0 C 25.552,10, 26,9.552, 26,9C 26,8.448, 25.552,8, 25,8l-18,0 C 6.448,8, 6,8.448, 6,9 C 6,9.552, 6.448,10, 7,10zM 7,22l 18,0 c 0.552,0, 1-0.448, 1-1c0-0.552-0.448-1-1-1l-18,0 C 6.448,20, 6,20.448, 6,21 C 6,21.552, 6.448,22, 7,22z"></path>
  </SvgIcon>
);

export const IconClose = props => (
  <SvgIcon
    viewBox="0 0 32 32"
    style={{ height: "24x", width: "24px", marginRight: '-7px', verticalAlign: 'middle' }}
    {...props}
  >
    <path d="M 10.050,23.95c 0.39,0.39, 1.024,0.39, 1.414,0L 17,18.414l 5.536,5.536c 0.39,0.39, 1.024,0.39, 1.414,0 c 0.39-0.39, 0.39-1.024,0-1.414L 18.414,17l 5.536-5.536c 0.39-0.39, 0.39-1.024,0-1.414c-0.39-0.39-1.024-0.39-1.414,0 L 17,15.586L 11.464,10.050c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 15.586,17l-5.536,5.536 C 9.66,22.926, 9.66,23.56, 10.050,23.95z"></path>
  </SvgIcon>
);

export const IconFitlerBack = props => (
  <SvgIcon
    x="0px" y="0px"
    viewBox="0 0 110 110"
    style={{ height: "24x", width: "24px", verticalAlign: 'middle' }}
    {...props}
  >
    <path d="M84.3,33.2c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.8,0.7H75c0,1.4-0.5,2.5-1.5,3.5
		c-1,1-2.2,1.5-3.5,1.5h-5c-1.4,0-2.5-0.5-3.5-1.5c-1-1-1.5-2.2-1.5-3.5H27.5c-0.7,0-1.3-0.2-1.8-0.7S25,35.7,25,35
		c0-0.7,0.2-1.3,0.7-1.8s1.1-0.7,1.8-0.7H60c0-1.4,0.5-2.5,1.5-3.5c1-1,2.2-1.5,3.5-1.5h5c1.4,0,2.5,0.5,3.5,1.5
		c1,1,1.5,2.2,1.5,3.5h7.5C83.2,32.5,83.8,32.7,84.3,33.2z M84.3,53.2c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.2,1.3-0.7,1.8
		s-1.1,0.7-1.8,0.7H50c0,1.4-0.5,2.5-1.5,3.5c-1,1-2.2,1.5-3.5,1.5h-5c-1.4,0-2.5-0.5-3.5-1.5c-1-1-1.5-2.2-1.5-3.5h-7.5
		c-0.7,0-1.3-0.2-1.8-0.7S25,55.7,25,55c0-0.7,0.2-1.3,0.7-1.8s1.1-0.7,1.8-0.7H35c0-1.4,0.5-2.5,1.5-3.5c1-1,2.2-1.5,3.5-1.5h5
		c1.4,0,2.5,0.5,3.5,1.5c1,1,1.5,2.2,1.5,3.5h32.5C83.2,52.5,83.8,52.7,84.3,53.2z M84.3,73.2c0.5,0.5,0.7,1.1,0.7,1.8
		c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.8,0.7H65c0,1.4-0.5,2.5-1.5,3.5c-1,1-2.2,1.5-3.5,1.5h-5c-1.4,0-2.5-0.5-3.5-1.5
		c-1-1-1.5-2.2-1.5-3.5H27.5c-0.7,0-1.3-0.2-1.8-0.7S25,75.7,25,75c0-0.7,0.2-1.3,0.7-1.8s1.1-0.7,1.8-0.7H50c0-1.4,0.5-2.5,1.5-3.5
		c1-1,2.2-1.5,3.5-1.5h5c1.4,0,2.5,0.5,3.5,1.5c1,1,1.5,2.2,1.5,3.5h17.5C83.2,72.5,83.8,72.7,84.3,73.2z M45,52.5h-5v5h5V52.5z
		 M60,72.5h-5v5h5V72.5z M70,32.5h-5v5h5V32.5z"/>
  </SvgIcon>
);

export const IconClose2 = props => (
  <SvgIcon
    viewBox="0 0 32 32"
    style={{ height: "18.75px", width: "18.75px", verticalAlign: 'middle', fill: 'rgb(204, 204, 204)' }}
    {...props}
  >
    <path d="M 17,2C 8.716,2, 2,8.716, 2,17S 8.716,32, 17,32S 32,25.284, 32,17S 25.284,2, 17,2z M 23.95,11.464L 18.376,17.038 l 4.932,5.498c 0.39,0.39, 0.39,1.024,0,1.414s-1.024,0.39-1.414,0L 16.962,18.452l-5.498,5.498c-0.39,0.39-1.024,0.39-1.414,0 c-0.39-0.39-0.39-1.024,0-1.414l 5.574-5.574L 10.692,11.464c-0.39-0.39-0.39-1.024,0-1.414c 0.39-0.39, 1.024-0.39, 1.414,0 l 4.932,5.498l 5.498-5.498c 0.39-0.39, 1.024-0.39, 1.414,0C 24.34,10.44, 24.34,11.074, 23.95,11.464z"></path>
  </SvgIcon>
)

export const IconUser = props => (
  <SvgIcon
    viewBox="0 0 48 48"
    style={{ height: "18x", width: "18px", verticalAlign: 'middle' }}
    {...props}
  >
    <path d="M31.278,25.525C34.144,23.332,36,19.887,36,16c0-6.627-5.373-12-12-12c-6.627,0-12,5.373-12,12
		c0,3.887,1.856,7.332,4.722,9.525C9.84,28.531,5,35.665,5,44h38C43,35.665,38.16,28.531,31.278,25.525z M16,16c0-4.411,3.589-8,8-8
		s8,3.589,8,8c0,4.411-3.589,8-8,8S16,20.411,16,16z M24,28c6.977,0,12.856,5.107,14.525,12H9.475C11.144,33.107,17.023,28,24,28z"
    />
  </SvgIcon>
)

export const IconSearch = props => (
  <SvgIcon
    viewBox="0 0 48 48"
    style={{ height: "18x", width: "18px", verticalAlign: 'middle' }}
    {...props}
  >
    <path d="M18,32c3.144,0,6.036-1.049,8.373-2.799L40,42.829L42.828,40L29.201,26.373C30.951,24.036,32,21.144,32,18
		c0-7.732-6.268-14-14-14S4,10.268,4,18S10.268,32,18,32z M18,8c5.514,0,10,4.486,10,10c0,5.514-4.486,10-10,10
		c-5.514,0-10-4.486-10-10C8,12.486,12.486,8,18,8z"/>
  </SvgIcon>
)

export const IconCart = props => (
  <SvgIcon
    viewBox="0 0 48 48"
    style={{ height: "18x", width: "18px", verticalAlign: 'middle' }}
    {...props}
  >
    <path d="M14.857,28.896L9.923,40H20h12h8v-4H16.078l2.222-5h20.142l6.333-19H13.441l-2-6H4v4h4.559L14.857,28.896z M39.225,16
		l-3.667,11H18.441l-3.667-11H39.225z"/>
    <circle cx="20" cy="42" r="2" />
    <circle cx="32" cy="42" r="2" />
  </SvgIcon>
)

export const IconInformation = props => (
  <SvgIcon
    viewBox="0 0 50 50"
    style={{ enableBackground: "new 0 0 50 50", height: "13px", width: "13px", verticalAlign: 'middle', margin: "0 0 3px 3px" }}
    {...props}>
    <path d="M25,1C11.767,1,1,11.767,1,25s10.767,24,24,24s24-10.767,24-24S38.233,1,25,1z M25,47C12.869,47,3,37.131,3,25
		S12.869,3,25,3s22,9.869,22,22S37.131,47,25,47z"/>
    <rect x="24" y="10" width="2" height="3" />
    <polygon points="21,18 24,18 24,40 26,40 26,16 21,16 	" />
  </SvgIcon>
)