import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {getCustomerAccessTokenMutation} from '../../graphql/querys'
import Breadcrumb from "../common/breadcrumb";
import CustomCheckbox from "../common/custom-checkbox";
import {client} from "../../index";
import {CustomerAction} from "../../reducers/customer";
import store from "../../store";
import {toast} from 'react-toastify';
import Util from "../../util.js";
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import axios from 'axios';
var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
const Login = ({ translate }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [, setRememberMe] = useState(false);

    useEffect(() => {
        setTimeout(() => window.analytics.page(), 1000);
    }, [])

    let redirectUrl = "/";
    const history = useHistory();
    let params = new URLSearchParams(history.location.search);
    if (params.get("redirectUrl")){
        redirectUrl = params.get("redirectUrl");
    } else if (history.location.state && history.location.state.from){
        redirectUrl = history.location.state.from;
    }

    const onLoginClick = (event) => {
        event.preventDefault();
        // testEmail : test1@rangempls.com
        // testPassword : Password01
        const accessTokenMutation = getCustomerAccessTokenMutation(email, password);
        client.send(accessTokenMutation).then( async ({model}) => {
            if (model.customerAccessTokenCreate.customerAccessToken !== null) {
                // Login Successful
                const {accessToken, expiresAt } = model.customerAccessTokenCreate.customerAccessToken;
                store.dispatch(CustomerAction.updateCustomerAccessToken(accessToken, expiresAt));
                await CustomerAction.fetchCustomerDetails(accessToken);
                toast.success(translate('Logged in successfully'));
                // Segment Tracking
                const customerInfo = await CustomerAction.returnCustomerDetails(accessToken);
                const reseller = Util.getReseller();
                let resellerId;
                if (base64regex.test(customerInfo.customer.id)) {
                    resellerId = atob(customerInfo.customer.id).split("/")[4];
                }else {
                    resellerId = customerInfo.customer.id.split("/")[4];
                }
                window.analytics.identify(resellerId, {
                    company: { name: reseller },
                    email: email
                });
                
                //Check for unsaved artwork
                var unSavedArtwork = JSON.parse(localStorage.getItem('unauthenticated_saved_project'));
                if(unSavedArtwork){
                    var customerCanvas = JSON.parse(localStorage.getItem('unauthenticated_customers_canvas')); 
                    if(customerCanvas){
                        customerCanvas.customer_id = resellerId;
                        axios.post(
                            process.env.REACT_APP_TAGGING_API_URL + "/create-metafield",
                            customerCanvas,
                            { 'Content-Type': 'application/json' }
                          ) .then(({ data }) => {
                            if(data){
                              //console.log(this.props);
                              localStorage.removeItem('unauthenticated_saved_project');
                              localStorage.removeItem('unauthenticated_customers_canvas');
                              history.push("/account/artwork");
                            } 
                          });
                    }
                }
                history.push(redirectUrl);
            } 
            else if (email === "" && password === "") {
                toast.error(translate("Please Enter an Email and Password"));
            } 
            else if (email === "") {
                toast.error(translate("Please Enter a Valid Email"));
            } 
            else if (password === "") {
                toast.error(translate("Please Enter a Password"));
            } 
            
            else {
                toast.error(translate("Invalid Credentials"));
            }
        });
    };

    return (
      <div>
          <Breadcrumb title={translate('login')}/>

          {/*Login section*/}
          <section className="login-page section-b-space">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-6">
                          <h3 className="text-capitalize">{translate("login")}</h3>
                          <div className="theme-card">
                              <form className="theme-form">
                                  <div className="form-group">
                                      <input type="text" className="form-control" id="email" placeholder={translate("Email")}
                                             required="" onChange={e => setEmail(e.target.value)}/>
                                  </div>
                                  <div className="form-group">
                                      <input type="password" className="form-control" id="review"
                                             placeholder={translate("Password")} required="" onChange={e => setPassword(e.target.value)}/>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                      <div className="d-flex">
                                          <CustomCheckbox onChange={(e) => setRememberMe(e.target.checked)}/>
                                          <span className="text-remember-me">
                                                {translate("Remember Me")}
                                                </span>
                                      </div>
                                      <span className="text-forgot-pass">
                                            <Link to="/forget-password">{translate("Forgot your password?")}</Link>
                                        </span>
                                  </div>
                                  <button className="btn btn-primary text-uppercase" onClick={onLoginClick}>{translate("login")}</button>
                              </form>
                          </div>
                      </div>
                      <div className="col-lg-6 right-login">
                          <h3>{translate("New Customer")}</h3>
                          <div className="theme-card authentication-right">
                              <h6 className="title-font">{translate("Create an Account")}</h6>
                              <p>{translate("registration_description")}</p>
                              <Link className="btn btn-primary" to="/register">{translate("Create an Account")}</Link>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

      </div>
    )
};

export default withTranslate(Login);
