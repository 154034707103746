import produce from 'immer';
import {
  UPDATE_CATEGORIES_LIST,
  UPDATE_CATEGORY_AND_PRODUCTS,
  UPDATE_CATEGORY_PRODUCTS,
} from '../constants/ActionTypes';

const initialState = {
  category: {},
  products: [],
  categories: [],
};

const categoryReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case UPDATE_CATEGORY_AND_PRODUCTS:
      draft.products = action.payload.products;
      draft.category = action.payload.category;
      break;

    case UPDATE_CATEGORY_PRODUCTS:
      draft.products = action.payload.products;
      break;

    case UPDATE_CATEGORIES_LIST:
      draft.categories = action.payload.categories;
      break;

    default:
      break;
  }
});

export default categoryReducer;
