var isModuleEnabled = true;

var cloudinary_fileupload_options = {
    maxFileSize: 10000000,                // 10MB is an example value - no default
    loadImageMaxFileSize: 10000000,       // default is 10MB
    // This is used to allow the input file element hold the submitted file data
    // in order to use it to be sent in the add to cart request
    // replaceFileInput: false,
    acceptFileTypes: /(\.|\/)(gif|jpe?g|png|pdf|ai|eps|psd)$/i
}

var transformationBleeds = {
    'small': 0,
    'medium': 18,
    'large': 36
}

var cloudinaryConfig = function() {
    return {
        "cloudinary": {
            "cloud_name": "stomp-stickers-staging",
            "api_key": "371596279296139",
            "api_secret": "CgxCiG7Ivj4xzAOOtugEEOZn_K0",
            "upload_preset": "artworkupload",
            "secure": true,
        }
    }
}

var storageAvailable = function(type) {
	try {
		var storage = window[type],
			x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	}
	catch(e) {
		return false;
	}
};

var setupConfig = function(config) {
    window.$.cloudinary.config(config.cloudinary);
    if (!storageAvailable('localStorage')) {
        if (!storageAvailable('sessionStorage')) {
            throw 'Error: Not storage available. Cancel Cloundinary initialization';
        }
    }
};

async function cloudinaryDownload(url) {
    let filename = url.substring(url.lastIndexOf('/')+1);
    let fileResponse = await fetch(url)
    let fileBlob = await fileResponse.blob()
    const imageFile = new File([fileBlob], filename, { type: 'image/png' });
    return imageFile;
}

var cloudinaryDone = function(data,setUrlFunction, extension, xHighLimit) {
    let response = data.response();
    let imageID = response.result.public_id;
    let transformation = {
      transformation: [
        {effect: 'make_transparent'},
        {effect: 'trim'},
        {effect:'outline:fill:' + transformationBleeds.small, color: 'white'}
      ]
    }
    let imgWidth = response.result.width / 300;
    let imgHeight = response.result.height / 300;
    let imgSquare = imgWidth * imgHeight;
    if (imgSquare > 93) { // if the image square inches are greater than 93, need to find the first size within the aspect ratio that fits
        let newSquare = imgSquare;
        let newWidth = imgWidth;
        let newHeight = imgHeight;
        if (imgWidth > imgHeight) { // if the width is the larger value, check and assign that
            let ratio = imgHeight / imgWidth;
            while (newSquare >= 93) {
                newWidth = newWidth - .01;
                newHeight = ratio * newWidth;
                newSquare = newWidth * newHeight;
            }
            transformation.transformation.unshift({width: Math.trunc(newWidth * 300), crop: 'scale'}) // truncate the result. slightly less accurate but rounding could potentially return an image larger than allowed
        } else {
            let ratio = imgWidth / imgHeight;
            while (newSquare > 93) {
                newHeight = newHeight - .01;
                newWidth = ratio * newHeight;
                newSquare = newWidth * newHeight;
            }
            transformation.transformation.unshift({height: Math.trunc(newHeight * 300), crop: 'scale'})
        }
    }
    if (extension === 'ai' || extension === 'eps' || extension === 'pdf') {
        /* since vector files start hitting the data caps around 2500 px dimensions returned during upload, we have to 
        apply a scale to bring them down. We can no longer use the high limits, since in all likelihood that would cause
        the image to increase in size, so we use 9", which is the approx. the largest value allowed assuming a square */
        if (response.result.height >= 2500 || response.result.width >= 2500) {
          if (response.result.height >= response.result.width) {
            transformation.transformation.unshift({height: 2700, crop: 'scale'});
          } else {
            transformation.transformation.unshift({width: 2700, crop: 'scale'});
          }
        } else {
          if (extension === 'ai' || extension === 'eps') {
            // Set the DPI for AI and EPS files
            transformation.transformation.unshift({density: '300'});
          }
        }
    }
    let transformedURL = window.$.cloudinary.url(imageID + '.png', transformation);
    window.$('.cloudinary-fileupload').off('cloudinarydone');
    window.$('.cloudinary-fileupload').off('fileuploadadd');
    setUrlFunction(transformedURL);
};

var cloudinaryAdd = function(fileData, setUrlFunction, xHighLimit) {
    var index = fileData.files[0].name.lastIndexOf(".");
    var extension = fileData.files[0].name.substring(index+1).toLowerCase();
    if (fileData.files[0].size > 10000000 || extension !== "jpg" 
        && extension !== "jpeg" && extension !== "png" 
        && extension !== "pdf" && extension !== "psd" 
        && extension !== "ai" && extension !== "eps"
        && extension !== "svg") {
            window.$('.cloudinary-fileupload').off('cloudinarydone');
            fileData.submit().abort();
            fileUploadFail();
            return false;
    } else {
        window.$('.cloudinary-fileupload').on('cloudinarydone', function(e, data) {
            cloudinaryDone(data, setUrlFunction, extension, xHighLimit);
        });
    }
}

var fileUploadFail = function(e, message) {
    console.log(e, message);    
}

var initOnProductView = function(config, setUrlFunction, handleError, openModal, xHighLimit) {
    try {
        _initUndefined(config, setUrlFunction, handleError, openModal, xHighLimit);
        return true;
    } catch (e) {
        console.log("Upload Artwork Tool cannot be Initialized");
        handleError();
        console.log(e);
        return false;
    }
};

var _initUndefined = function(config, setUrlFunction, handleError, openModal, xHighLimit) {
    try {
        setupConfig(config);
        var formData = {
          upload_preset: config.cloudinary.upload_preset
        };
        window.$("input.cloudinary-fileupload[type=file]").attr('data-form-data', JSON.stringify(formData));
        window.$('.cloudinary-fileupload').on('fileuploadfail', function(e, message) {
            fileUploadFail(e, message);
        });
        window.$('.cloudinary-fileupload').on('fileuploadadd', function(e, data) {
            openModal();
            cloudinaryAdd(data, setUrlFunction, xHighLimit);
        });
        console.log("Upload Artwork Tool Initialized");
    } catch (e) {
        throw "Something went wrong trying to initialize UAT library";
        handleError();
    }
    if(window.$.fn.cloudinary_fileupload !== undefined) {
        window.$("input.cloudinary-fileupload[type=file]").cloudinary_fileupload(cloudinary_fileupload_options);
    } else {
        console.log("Could not initialize the file upload");
        handleError();
    }
}

export const cloudinaryMethods = {
    cloudinaryConfig: cloudinaryConfig,
    initOnProductView: initOnProductView,
    cloudinaryDownload: cloudinaryDownload
}