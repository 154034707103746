import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Util from "../../../../util";

const MainBackground = () => {

  const [backgroundStyle, setBackgroundStyle] = useState(null)
  const [homepageSettings, setHomepageSettings] = useState({})
  const [imageAnchor, setImageAnchor] = useState(null)

  useEffect(() => {
    let homepageSettings = window.homepageSettings;

    if (process.env.REACT_APP_RESELLER_ID) {
      Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Homepage Hero Image + Text", "homepageSettings").then(response => {
        setHomepageSettings(response)
      });
    } else {
      setHomepageSettings(homepageSettings);
    }
  });

  useEffect(() => {

    
    if (homepageSettings) {
      let style = {};
      if (homepageSettings.heroUrl) {
        style = {
          backgroundImage: `url(${homepageSettings.heroUrl})`
        }
      }
      let imageAnchor = {}
      switch (homepageSettings.horizontalAlignment) {
        case "left":
          imageAnchor = { justifyContent: "flex-start" }
          break
          case "right":
            imageAnchor = { justifyContent: "flex-end" }
            break
            case "center":
              imageAnchor = { justifyContent: "center" }
              break
            }
            switch (homepageSettings.verticalAlignment) {
              case "top":
                imageAnchor = { ...imageAnchor, alignItems: "flex-start", marginTop: "50px" }
                break
                case "bottom":
                  imageAnchor = { ...imageAnchor, alignItems: "flex-end", marginBottom: "10px" }
                  break
                  case "center":
                    imageAnchor = { ...imageAnchor, alignItems: "center" }
                    break
                  }
                  setImageAnchor(imageAnchor)
                  setBackgroundStyle(style)
                }
                
  }, [homepageSettings]);  

  let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  return (
    <section className="p-0">
      <div className="slid-1 home-slider">
        <div className="home home1 text-center" style={backgroundStyle}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="slider-contain" style={imageAnchor}>
                  {homepageSettings && homepageSettings.headlineAlignment && <div className={`text-${homepageSettings.headlineAlignment} pl-4 hero`}>
                    <h4 className="headline">{homepageSettings.label}</h4>
                    <h1 style={{ maxWidth: "450px" }}>
                      {homepageSettings.headline}
                    </h1>
                    {homepageSettings.ctaButtonLink && homepageSettings.ctaButtonLink != "" && homepageSettings.ctaButtonLabel && homepageSettings.ctaButtonLabel != "" &&
                      <>
                        {regex.test(homepageSettings.ctaButtonLink) ?
                          <a href={homepageSettings.ctaButtonLink}><button
                            style={{ border: 'none !important', padding: "13px 29px", marginTop: 20, borderRadius: "4px" }}
                            className="btn btn-primary"
                          >
                            {homepageSettings.ctaButtonLabel}
                          </button></a> : <Link
                            to={`${homepageSettings.ctaButtonLink}`}>
                            <button
                              style={{ border: 'none !important', padding: "13px 29px", marginTop: 20, borderRadius: "4px" }}
                              className="btn btn-primary"
                              href={homepageSettings.ctaButtonLink}>
                              {homepageSettings.ctaButtonLabel}
                            </button>
                          </Link>
                        }
                      </>
                    }
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainBackground;
