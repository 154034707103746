import axios from 'axios';
import produce from 'immer';
import * as querys from "../graphql/querys";
import { client } from "../index";
import store from "../store";

const initialState = {
  customerAccessToken: {
    accessToken: null,
    expiresAt: null,
  },
  loggedIn: false,
  recaptchaKey: null,
  loggedInUserDetails: {
    orders: [],
    metafields: []
  },
};

const customerReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case CustomerActionTypes.UPDATE_CUSTOMER_ACCESS_TOKEN:
      draft.customerAccessToken = { ...action.payload };
      draft.loggedIn = true;
      break;

    case CustomerActionTypes.UPDATE_USER_DETAILS:
      let {metafields} = state.loggedInUserDetails;
      draft.loggedInUserDetails = { ...action.payload, metafields};
      break;

    case CustomerActionTypes.UPDATE_CUSTOMER_ORDERS:
      draft.loggedInUserDetails.orders = action.payload;
      break;
    
    case CustomerActionTypes.UPDATE_CUSTOMER_METAFIELDS:
      draft.loggedInUserDetails.metafields = action.payload;
      break;
    case CustomerActionTypes.UPDATE_RECAPTCHA_KEY:
        draft.recaptchaKey = action.payload;
        break;
    case CustomerActionTypes.DELETE_CUSTOMER_METAFIELD:
      let meta = state.loggedInUserDetails.metafields.filter(item => item.id != action.payload.metafield_id);
      draft.loggedInUserDetails.metafields = meta;
      break;
    case CustomerActionTypes.CUSTOMER_LOG_OUT:
      draft.customerAccessToken = {
        accessToken: null,
        expiresAt: null,
      };
      draft.loggedIn = false;
      draft.loggedInUserDetails = {};
      draft.orders = [];
      localStorage.removeItem('customers_canvas');
      const getCustomerAccessTokenDeleteMutation = querys.getCustomerAccessTokenDeleteMutation(action.payload);
      client.send(getCustomerAccessTokenDeleteMutation).then(({ model }) => {
        //logged out
      }).catch((e) => {
        console.log("Error: ", e)
      });
      break;

    default:
      break;
  }
});

export class CustomerAction {
  static updateCustomerAccessToken = (accessToken, expiresAt) => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER_ACCESS_TOKEN,
    payload: { accessToken, expiresAt },
  });

  static updateLoggedInUserDetails = (customer) => ({
    type: CustomerActionTypes.UPDATE_USER_DETAILS,
    payload: { ...customer },
  });

  static updateCustomerOrders = (orders) => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER_ORDERS,
    payload: orders,
  });

  static updateCustomerMetafields = (metafields) => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER_METAFIELDS,
    payload: metafields,
  });

  static updateRecaptchaKey = (key) => ({
    type: CustomerActionTypes.UPDATE_RECAPTCHA_KEY,
    payload: key,
  });

  static refreshCustomerMetafieldAfterDelete = (metafield_id) => ({
    type: CustomerActionTypes.DELETE_CUSTOMER_METAFIELD,
    payload: {metafield_id},
  });

  static customerLogOut = (accessToken) => ({
    type: CustomerActionTypes.CUSTOMER_LOG_OUT,
    payload: accessToken,
  });

  static fetchCustomerOrders = async (accessToken,page) => {
    const getCustomerOrdersQuery = querys.getCustomerOrdersQuery(accessToken,page);
    let result = await client.send(getCustomerOrdersQuery)
      store.dispatch(CustomerAction.updateCustomerOrders(result.model.customer.orders));
      return result.data.customer.orders.pageInfo;
  };

  static fetchCustomerDetails = async (accessToken) => {
    const getCustomerQuery = querys.getCustomerQuery(accessToken);
    client.send(getCustomerQuery).then(({ model }) => {
      store.dispatch(CustomerAction.updateLoggedInUserDetails(model.customer));
    }).catch((e) => {
      console.log("Error: ", e)
    });
  };

  static returnCustomerDetails = async (accessToken) => {
    const getCustomerQuery = querys.getCustomerQuery(accessToken);
    let data = client.send(getCustomerQuery).then(({ model }) => {
      return model;
    }).catch((e) => {
      console.log("Error: ", e)
    });

    return data;
  };

  static fetchCustomerMetafields = async (customer_id) => {
    const url = `${process.env.REACT_APP_TAGGING_API_URL}/get-metafield`;
    const metafields = await axios.post(url, {customer_id});
    store.dispatch(CustomerAction.updateCustomerMetafields(metafields.data));
  };

  static deleteCustomerMetafield = async (metafieldId,customerId) => {
    let payload = {
      metafield_id: metafieldId,
      customer_id: customerId    
    };
    const url = `${process.env.REACT_APP_TAGGING_API_URL}/delete-metafield`;
    const result = await axios.post(
      url,
      payload,
      { 'Content-Type': 'application/json' }
    )
    store.dispatch(CustomerAction.refreshCustomerMetafieldAfterDelete(metafieldId));
  };
}

class CustomerActionTypes {
  static UPDATE_CUSTOMER_ACCESS_TOKEN = 'UPDATE_CUSTOMER_ACCESS_TOKEN';
  static UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
  static CUSTOMER_LOG_OUT = 'CUSTOMER_LOG_OUT';
  static UPDATE_CUSTOMER_ORDERS = 'UPDATE_CUSTOMER_ORDERS';
  static UPDATE_CUSTOMER_METAFIELDS = 'UPDATE_CUSTOMER_METAFIELDS';
  static DELETE_CUSTOMER_METAFIELD = 'DELETE_CUSTOMER_METAFIELD';
  static UPDATE_RECAPTCHA_KEY = 'UPDATE_RECAPTCHA_KEY';
}

export default customerReducer;
