import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Pace from 'react-pace-progress'
import { withTranslate } from 'react-redux-multilingual';

// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import Locales from "./common/locales";
import { IconMenu, IconSearch, IconUser } from '../svg-icons';

import { CustomerAction } from "../../../reducers/customer";

import Util from "../../../util"

const queryString = require('query-string');

const HeaderOne = ({ logoName, history, translate }) => {

	const { loggedIn, loggedInUserDetails, customerAccessToken } = useSelector(state => state.customer);

	const [displayName, setDisplayName] = useState("");
	const [isLoading] = useState(false);
	const [logoSrc, setLogoSrc] = useState(logoName)

	const [flexibleMessage, setFlexibleMessage] = useState()

	const targetRef = useRef();
	const [, setDimensions] = useState({ width: 0, height: 0 });

	useLayoutEffect(() => {
		if (targetRef.current) {
			setDimensions({
				width: targetRef.current.offsetWidth,
				height: targetRef.current.offsetHeight
			});
		}
	}, []);

	useEffect(() => {
		if (loggedInUserDetails.displayName) {
			setDisplayName(loggedInUserDetails.displayName);
		}
	}, [loggedInUserDetails]);

	useEffect(() => {
		document.querySelector(".loader-wrapper").style = "display: none";
		window.addEventListener('scroll', handleScroll);
	}, [targetRef.current]);

	const fetchNetlifyFlexibleSnippet = async () => {
		const results = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Flexible Message Area", "flexibleMessage");

		if (results) {
			setFlexibleMessage(results)
		}
	}

	useEffect(() => {
		if (process.env.REACT_APP_RESELLER_ID) {
			fetchNetlifyFlexibleSnippet();
		}
	}, [process.env.REACT_APP_RESELLER_ID]);


	useEffect(() => {
		if (process.env.REACT_APP_RESELLER_ID) {
			fetchNetlifyFlexibleSnippet();
		}
	}, [process.env.REACT_APP_RESELLER_ID]);


	useEffect( () => {
		var customLogoFile = window.logoUrl;
		let flexibleMessage = window.flexibleMessage;

		if (customLogoFile) {
			const logoSrc = customLogoFile;
			setLogoSrc(logoSrc)
		}

		if (flexibleMessage) {
			setFlexibleMessage(flexibleMessage)
		}

	}, [])

	const handleScroll = () => {
		var sticky = document.getElementById("sticky");
		var root = document.getElementById("root");
		var topHeader = document.getElementsByClassName("top-header");
		if (sticky) {
			if (window.innerWidth >= 576) {
				let height = sticky.offsetHeight;
				if (topHeader.length > 0) {
					height += topHeader[0].offsetHeight;
				}
				if (window.pageYOffset >= height) {
					root.style.paddingTop = `${height}px`;
					sticky.classList.add("fixed");
				} else {
					root.style.paddingTop = "0";
					sticky.classList.remove("fixed");
				}
			} else {
				root.style.paddingTop = "0";
				sticky.classList.remove("fixed");
			}
		}
	};


	const openNav = () => {
		var openmyslide = document.getElementById("mySidenav");
		if (openmyslide) {
			openmyslide.classList.add('open-side')
		}
	};

	const openSearch = () => {
		document.getElementById("search-overlay").style.display = "block";
	};

	const closeSearch = () => {
		document.getElementById("search-overlay").style.display = "none";
	}


	const onLogOut = () => {
		store.dispatch(CustomerAction.customerLogOut(customerAccessToken.accessToken));
		history.push('/login');
	};

	const getReseller = () => {
		var hostSplits = window.location.host.split('.');
		var reseller = "";
		if (hostSplits.length > 1 && (hostSplits.indexOf("whitelabellabels") > -1 || hostSplits.indexOf("readysetprint") > -1)) {
			reseller = hostSplits[0];
		} else if (typeof window.subdomain != "undefined") {
			reseller = window.subdomain;
		}
		return reseller;
	}

	const reseller = getReseller();

  const showLocales = window.location.href.indexOf("gfsimpress") > -1 
    || window.location.href.indexOf("localhost") > -1
    || window.location.href.indexOf("deploy-preview") > -1
    || window.location.href.indexOf("test-fr") > -1;

	return (
		<div>
			<header id="sticky" className="sticky" ref={targetRef}>
				{isLoading ? <Pace color="#27ae60" /> : null}
				<div className="mobile-fix-option"></div>
				{/*Top Header Component*/}
				{flexibleMessage && flexibleMessage !== "" && <TopBar text={flexibleMessage} />}

				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="main-menu">
								<div className="menu-left">
									<div className="navbar">
										<a href="javascript:void(0)" onClick={openNav}>
											<div className="bar-style" title={translate("Menu")}> <IconMenu /> </div>
										</a>
										{/*SideBar Navigation Component*/}
										<SideBar />
									</div>
									<div className="brand-logo">
										<LogoImage logo={logoSrc} />
									</div>
								</div>
								<div className="menu-right pull-right">
									{/*Top Navigation Bar Component*/}
									<NavBar />

									<div>
										<div className="icon-nav">
											<ul className="user-menu">
												{reseller !== "" ? (
													<li className="onhover-div mobile-search">
														<div>
															<IconSearch onClick={openSearch} />
														</div>
													</li>
												) : ''}
												<li className="onhover-div mobile-setting">
													<div>
														<Link to={
															{ pathname: `${process.env.PUBLIC_URL}/${loggedIn ? "account" : "register"}`, state: { from: history.location.pathname }}
														} title={`${loggedIn ? translate("Account") : translate("Register")}`}>
															<IconUser />
														</Link>
														<i className="fa fa-user"></i></div>
													{loggedIn &&
														<div className="show-div setting">
															<div>
																<h6>{translate("User Name")}</h6>
																<ul>
																	<li>{displayName}</li>
																</ul>
															</div>
															<a href="javascript:void(0)" onClick={onLogOut}><h6>{translate("Sign Out")}</h6></a>
														</div>
													}
												</li>
												{/*Header Cart Component */}
												<CartContainer />
                        {showLocales ? (
                          <Locales sidebar={false}/>
                        ) : ""}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			{reseller !== "" ? (
				<div id="search-overlay" className="search-overlay">
					<div>
						<span className="closebtn" onClick={closeSearch} title="Close Overlay">×</span>
						<div className="overlay-content">
							<div className="container">
								<div className="row">
									<div className="col-xl-12">
										<form method="GET" action="/search">
											<div className="form-group">
												<input type="text" required={true} defaultValue={queryString.parse(document.location.search).q} className="form-control" name="q" id="searchInput" placeholder={translate("Search Products")} />
											</div>
											<button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : ''}
			<div id="sticky-padding" style={{ display: "none" }}></div>
		</div>
	)
};

export default withRouter(withTranslate(HeaderOne));
