import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../../../util';

const CategoryItem = ({ title, id, handle  }) => {

  const [modifiedHandle, setModifiedHandle] = useState(handle);

  useEffect(() => {
    const reseller = Util.getReseller();
    if (reseller !== "") setModifiedHandle(handle.replace(`${reseller}-`, ""));

  }, [])

  return (
    <div
      className="category-item"
      key={id}
    >
      <Link to={`/category/${modifiedHandle}`} className="link">
        <button className="btn btn-primary btn-block m "
          style={{
            borderRadius: '8px',
            fontWeight: '700',
            height: '120px',
            fontFamily: 'var(--heading-font)',
            fontSize: '16px',
            padding: '0px 20px',
          }}
        >
          <div style={{ whiteSpace: 'normal', lineHeight: '1.2' }}>
              <span style={{ lineHeight: '2', letterSpacing: '1.3px', wordBreak: "normal" }}>{title}</span>
          </div>
        </button>
      </Link>
    </div>
  );
}

export default CategoryItem;
