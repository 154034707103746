import React, { Component } from 'react';

export default class ImageZoom extends Component {
    render() {
        const {image, alt} = this.props;

        return (
            <img src={`${image.src}`} alt={alt}  className="img-fluid image_zoom_cls-0" />
        );
    }
}