import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

class TopBar extends Component {

    render() {
        const {text} = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <span style={{letterSpacing: '0px', fontSize: 14}}>
                                {text}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);