import {combineReducers} from 'redux';
import {IntlReducer as Intl} from 'react-redux-multilingual';
// Import custom components
import productReducer from './products';
import popularProductReducer from './popularProducts';
import cartReducer from './cart';
import filtersReducer from './filters';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import categoryReducer from './category';
import customerReducer from './customer';
import orderOptionsReducer from './orderOptions';

const rootReducer = combineReducers({
    data: productReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    category: categoryReducer,
    customer: customerReducer,
    popularProducts: popularProductReducer,
    orderOption: orderOptionsReducer,
    Intl
});

export default rootReducer;
