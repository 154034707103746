import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {client} from '../../index.js';
import Breadcrumb from "../common/breadcrumb";
import {toast} from "react-toastify";
import validator from "validator";
import {useHistory} from "react-router";
import {createCustomerMutation} from "../../graphql/mutations";
import Util from '../../util.js'
import { Link } from 'react-router-dom';
import { translate } from 'react-redux-multilingual/lib/utils';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from 'react-redux';

const Register = ({ translate }) => {
    const customer = useSelector(state => state.customer);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => window.analytics.page(), 1000);
    }, [])

    const onChange = (value)=>{
        setRecaptchaVerified(value);
    }
    const validInputs = () => {
        if (firstName === "") {
            toast.error(translate("Enter your First Name"));
            return false;
        }
        if (lastName === "") {
            toast.error(translate("Enter your Last Name"));
            return false;
        }
        if (email === "") {
            toast.error(translate("Enter your e-mail"));
            return false;
        }
        if (!validator.isEmail(email)) {
            toast.error("Email is invalid");
            return false;
        }
        if (password === "") {
            toast.error(translate("Enter password"));
            return false;
        }
        if (confirmPassword === "") {
            toast.error(translate("Confirm Password is required"));
            return false;
        }
        if (password !== confirmPassword) {
            toast.error(translate("Password and Confirm Password do not match"));
            return false;
        }
        return true;
    };

    const onRegisterClick = (e) => {
        e.preventDefault();
        if (validInputs()) {
            if(!recaptchaVerified){
                toast.error('Please verify recaptcha');
                return;
            }
            const reseller = Util.getReseller();
            let tags = `end_user_of=${reseller}`
            if (window.customerServiceEmail) {
                tags += `,customer_service_email=${window.customerServiceEmail}`;
            }
            if (window.customSubdomain) {
                tags += `,custom_subdomain=${window.customSubdomain}`;
            }
            axios.post(
                process.env.REACT_APP_TAGGING_API_URL + "/create-customer",
                {
                    tags,
                    email,
                    password,
                    firstName,
                    lastName
                },
                { 'Content-Type': 'application/json' }
            )
                .then(({ data }) => {
                    const customerId = data.customer.id;
                    // Segment Tracking
                    window.analytics.identify(customerId, {
                        company: { name: reseller },
                        email: email
                    });
                    console.log("Customer created");
                    history.push({pathname: "/login", state: { from: history.location.state && history.location.state.from ? history.location.state.from : "/"}});
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        error.response.data.map(e => toast.error(e.charAt(0).toUpperCase() + e.slice(1)));
                    }
                })
                    
        }
    };

    return (
        <div>
            <Breadcrumb title={translate('create account')}/>

            {/*Regsiter section*/}
            <section className="register-page section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="text-capitalize">{translate("create account")}</h3>
                            <div className="theme-card">
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" id="fname"
                                                   placeholder={translate("First name")} required=""
                                                   onChange={e => setFirstName(e.target.value.trim())}/>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" id="lname"
                                                   placeholder={translate("Last Name")} required=""
                                                   onChange={e => setLastName(e.target.value.trim())}/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="email"
                                                   placeholder={translate("Email")} required=""
                                                   onChange={e => setEmail(e.target.value.trim())}/>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="password" className="form-control" id="review"
                                                   placeholder={translate("Enter your password")} required=""
                                                   onChange={e => setPassword(e.target.value.trim())}/>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="password" className="form-control" id="review1"
                                                   placeholder={translate("Confirm your password")} required=""
                                                   onChange={e => setConfirmPassword(e.target.value.trim())}/>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey={customer.recaptchaKey}
                                            onChange={onChange}
                                        />
                                            <div className="col-md-12">
                                            <button className="btn btn-primary text-uppercase" onClick={onRegisterClick}>
                                                {translate("CREATE ACCOUNT")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <br></br>
                            <p style={{textAlign: "center"}}>{translate("Already have an account?")} <Link to={
                                { pathname: "/login", state: {from : history.location.state && history.location.state.from ? history.location.state.from : "/" }}
                            }>{translate("Login here!")}</Link></p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
};

export default withTranslate(Register);
