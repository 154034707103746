import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AddOrUpdateAddressModal from "../../modals/AddOrUpdateAddressModal";
import { client } from "../../../index";
import { updateCustomerDefaultAddress, deleteCustomerAddress, updateCustomer } from "../../../graphql/mutations";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { CustomerAction } from "../../../reducers/customer";
import { getCustomerAccessTokenMutation } from "../../../graphql/querys";
import store from "../../../store";
import Modal from 'react-responsive-modal';
import { useForm } from 'react-hook-form';
import { Alert } from 'reactstrap';
import ContactInformationModal from "../../modals/contact-information-modal";
import axios from 'axios';
import withTranslate from "react-redux-multilingual/lib/withTranslate";

const EditPasswordComponent = ({translate}) => {
    const [isEditing, setIsEditing] = useState(false);
    const { customerAccessToken, loggedInUserDetails } = useSelector(state => state.customer);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const changePassword = () => {
        if (newPassword === "") {
            toast.error(translate("Enter a new password"));
            return false;
        }
        if (confirmPassword === "") {
            toast.error(translate("Enter password again"));
            return false;
        }
        if (newPassword !== confirmPassword) {
            toast.error(translate("Password and Confirm Password Do not match"));
            return false;
        }
        client.send(updateCustomer(customerAccessToken.accessToken, { "password": newPassword })).then(({ model }) => {
            if (model.customerUpdate && model.customerUpdate.customer) {
                toast.success("Password Changed Successfully");
                setNewPassword("");
                setConfirmPassword("");
                setIsEditing(false);
                const accessTokenMutation = getCustomerAccessTokenMutation(loggedInUserDetails.email, newPassword);
                client.send(accessTokenMutation).then(async ({ model }) => {
                    if (model.customerAccessTokenCreate.customerAccessToken !== null) {
                        const { accessToken, expiresAt } = model.customerAccessTokenCreate.customerAccessToken;
                        store.dispatch(CustomerAction.updateCustomerAccessToken(accessToken, expiresAt));
                    }
                });
            } else {
                toast.error(model.customerUpdate.customerUserErrors[0].message);
            }
        })
    };

    const ChangePasswordComponent = () => {
        return (
            <div>
                <div className="form-group">
                    <input type="password"
                        className="form-control"
                        placeholder="New Password"
                        required
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <input type="password"
                        className="form-control"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        required
                        onChange={e => setConfirmPassword(e.target.value)} />
                </div>
                <a href="javascript:void(0)" onClick={() => changePassword()}>Save Password</a>
                &nbsp; &nbsp;
                <a onClick={() => {
                    setNewPassword("");
                    setConfirmPassword("");
                    setIsEditing(false);
                }}>Cancel</a>
            </div>
        )
    };

    const ChangePasswordButton = () => {
        return (
            <h6>
                <Link to="#" onClick={() => setIsEditing(true)}>Change Password</Link>
            </h6>
        )
    };

    return (
        <div>
            {isEditing ? ChangePasswordComponent() : ChangePasswordButton()}
        </div>
    );
};

const AccountSettings = ({translate}) => {

    const { loggedInUserDetails, customerAccessToken } = useSelector(state => state.customer);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [editAddressId, setEditAddressId] = useState(null);
    const [taxModalOpen, setTaxModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [taxExempt, setTaxExempt] = useState("non-exempt");
    const [taxFormSubmittedText, setTaxFormSubmittedText] = useState("");
    const [taxFormSubmittedColor, setTaxFormSubmittedColor] = useState("");
    const [taxFormSubmittedAlertOpen, setTaxFormSubmittedAlertOpen] = useState(false);
    const [contactInformationModalOpen, setContactInformationModalOpen] = useState(false);
    const { register, handleSubmit, errors, setValue } = useForm({ mode: "all" });
    const [hideTaxExemption, setHideTaxExemption] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const customer = useSelector(state => state.customer);

    useEffect(() => {
        if (loggedInUserDetails.tags) {
            const taxExemptIndex = loggedInUserDetails.tags.findIndex(t => t.value.includes("tax_exempt="));
            if (taxExemptIndex > -1) {
                let _taxExempt = loggedInUserDetails.tags[taxExemptIndex];
                const _taxExemptValue = _taxExempt.value.split("=")[1];
                if (_taxExemptValue === "true") setTaxExempt("exempt");
            };
        };
        
        setTimeout(() => window.analytics.page(), 1000);
        const windowLocation = window.location.href;
    
        if (windowLocation.includes("ca-gfsimpress-readysetprint") || windowLocation.includes("fr-gfsimpress-readysetprint") || windowLocation.includes("ca.gfsimpress.com") || windowLocation.includes("fr.gfsimpress.com")) {
            setHideTaxExemption(true);
        }
    }, []);
    const onChange = (value)=>{
        setRecaptchaVerified(value);
    }

    const onAddOrEditAddress = (addressId) => {
        setEditAddressId(addressId);
        setIsAddressModalOpen(true);
    };

    const deleteAddress = (id) => {
        client.send(deleteCustomerAddress(customerAccessToken.accessToken, id)).then(({ model }) => {
            if (model.customerAddressDelete && model.customerAddressDelete.deletedCustomerAddressId) {
                toast.success("Address deleted");
                CustomerAction.fetchCustomerDetails(customerAccessToken.accessToken);
            } else {
                toast.error("An Error Occurred");
            }
        })
    };

    const setDefaultAddress = (id) => {
        client.send(updateCustomerDefaultAddress(customerAccessToken.accessToken, id)).then(({ model }) => {
            if (model.customerDefaultAddressUpdate && model.customerDefaultAddressUpdate.customer) {
                toast.success("Default Address Updated");
                CustomerAction.fetchCustomerDetails(customerAccessToken.accessToken);
            } else {
                toast.error(model.customerDefaultAddressUpdate.customerUserErrors[0].message);
            }
        })
    };

    const renderAddress = (address) => {
        return (
            <address>
                {address.firstName && `${address.firstName} `}
                {address.firstName && address.lastName && `${address.lastName}`}<br />
                {address.address1}<br />
                {address.address2}<br />
                {address.city && `${address.city},\n`}
                {address.province && `${address.province}\n`}
                {address.zip && `${address.zip}`} <br />
                {address.country && `${address.country}\n`}
                {address.country && <br />}
                <Link to='#' onClick={() => onAddOrEditAddress(address.id)}>{translate("Edit")}</Link><a>&nbsp;|&nbsp;</a>
                <Link to='#' onClick={() => deleteAddress(address.id)}>{translate("Delete")}</Link>
                {
                    (address.id !== loggedInUserDetails.defaultAddress.id) && (
                        <>
                            <a>&nbsp;|&nbsp;</a>
                            <Link to='#' onClick={() => setDefaultAddress(address.id)}>{translate("Set as Default")}</Link>
                        </>
                    )
                }
                <br />
            </address>
        )
    }

    const renderDefaultAddress = () => {  // renders list of addresses

        if (loggedInUserDetails.defaultAddress) {
            return (
                <div className="col-sm-6">
                    <h6>{translate(`Default Shipping Address`)}</h6>
                    {renderAddress(loggedInUserDetails.defaultAddress)}
                </div>
            )
        } else {
            return (
                <div className="col-sm-6">
                    <h6>No addresses found</h6>
                </div>
            )
        }
    };

    const renderAddresses = () => {
        let count = 1;
        return loggedInUserDetails.addresses.map((address) => {
            if (address.id !== loggedInUserDetails.defaultAddress.id) {
                count = count + 1;
                return (
                    <div className="col-sm-6" key={address.id}>
                        <h6>{translate("Shipping Address")}{` ${count}`}</h6>
                        {renderAddress(address)}
                        <br />
                    </div>
                );
            } else {
                return null;
            }
        }
        );
    };

    const onSubmit = (e) => {
        if(!recaptchaVerified){
            toast.error('Please verify recaptcha');
            return;
        }
        let data = { "form-name": "tax-form", ...e, file };
        setTaxModalOpen(false);
        setTaxFormSubmittedText(translate("Submitting tax form"));
        setTaxFormSubmittedColor("secondary");
        setTaxFormSubmittedAlertOpen(true);

        fetch("/", {
            method: "POST",
            body: encode(data)
        })
            .then(() => {
                return axios.post(
                    process.env.REACT_APP_TAGGING_API_URL + "/add-customer-tags",
                    {
                        customerId: loggedInUserDetails.id,
                        tags: ["tax_exempt_eligible"]
                    },
                    { 'Content-Type': 'application/json' }
                )
            })
            .then(() => {
                setTaxFormSubmittedText(translate("Tax form submitted"));
                setTaxFormSubmittedColor("success");
                const { accessToken } = customerAccessToken;
                if (accessToken) {
                    CustomerAction.fetchCustomerDetails(accessToken)
                }
            })
            .catch(error => {
                console.error(error);
                setTaxFormSubmittedText(`Form submission failed: ${error}`);
                setTaxFormSubmittedColor("danger");
            });

    };

    const encode = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((k) => {
            formData.append(k, data[k])
        });
        return formData
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type !== "application/pdf") {
            setValue("file", null, { shouldValidate: true });
        } else {
            setValue("file", e.target.files, { shouldValidate: true });
            setFile(file);
        }
    }

    const toggleAlert = () => {
        setTaxFormSubmittedAlertOpen(false);
    }

    const updateContactInformation = ({ firstName, lastName, email }) => {
        client.send(updateCustomer(customerAccessToken.accessToken, { firstName, lastName, email })).then(({ model }) => {
            if (model.customerUpdate && model.customerUpdate.customer) {
                toast.success("Contact information updated correctly!");
                setContactInformationModalOpen(false);
                CustomerAction.fetchCustomerDetails(customerAccessToken.accessToken);
            } else {
                toast.error(model.customerUpdate.customerUserErrors[0].message);
            }
        })
    };

    return (
        <>
            <AddOrUpdateAddressModal
                addressId={editAddressId}
                open={isAddressModalOpen}
                onClose={() => setIsAddressModalOpen(false)}
            />
            <ContactInformationModal
                modalFirstName={loggedInUserDetails.firstName}
                modalLastName={loggedInUserDetails.lastName}
                modalEmail={loggedInUserDetails.email}
                open={contactInformationModalOpen}
                onClose={() => setContactInformationModalOpen(false)}
                onSave={updateContactInformation}
            />
            <div className="col-lg-12">
                <div className="dashboard-right">
                    <div className="dashboard">
                        <div className="page-heading">
                            <h2>{translate("My Account")}</h2>
                        </div>
                        <div className="box-account box-info">
                            <div className="box-head">
                                <h4>{translate("Account Information")}</h4>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="box">
                                        <div className="box-title">
                                            <h3>{translate("Contact Information")}</h3>
                                            <Link to='#' onClick={() => setContactInformationModalOpen(true)}>{translate("Edit")}</Link>
                                        </div>
                                        <div className="box-content">
                                            <h6>{loggedInUserDetails.displayName}</h6>
                                            <h6>{loggedInUserDetails.email}</h6>
                                            <EditPasswordComponent />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="box">
                                    <div className="box-title">
                                        <h3>{translate("Address Book")}</h3>
                                        <Link to='#' onClick={() => onAddOrEditAddress(null)}>{translate("Add New Address")}</Link>
                                    </div>
                                    <div className="row">
                                        {renderDefaultAddress()}
                                        {loggedInUserDetails.addresses && renderAddresses()}
                                    </div>
                                </div>
                            </div>
                            {!hideTaxExemption && <div>
                                <div className="box">
                                    <div className="box-title">
                                        <h3>{translate("Tax Exempt Status")}</h3>
                                    </div>
                                    <div className="box-content">
                                        <div>{translate("Status")}: {translate(taxExempt)}</div>
                                        <h6>
                                            <Link to='#' onClick={() => setTaxModalOpen(true)}>{translate("Fill out tax form")}</Link>
                                        </h6>
                                        <Alert color={taxFormSubmittedColor} isOpen={taxFormSubmittedAlertOpen} toggle={toggleAlert.bind(this)}>{taxFormSubmittedText}</Alert>
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={taxModalOpen} center onClose={() => setTaxModalOpen(false)} classNames={{ modal: 'taxModal' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" style={{ margin: "auto" }}>
                    <div className="modal-content quick-view-modal">
                        <div className="modal-body" style={{ width: "90%" }}>
                            <div className="row">
                                <div className="col-12 rtl-text">
                                    <div className="product-right">
                                        <h2> {translate("Tax form")} </h2>
                                        <div className="border-product">
                                            <form name="tax-form" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group">
                                                    <input type="text" name="firstName" className="form-control"
                                                        placeholder={translate("First name")} required="" ref={register({ required: true })} />
                                                    {errors.firstName && <p style={{ color: "red" }}>{translate("First name is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="lastName" className="form-control"
                                                        placeholder={translate("Last Name")} required="" ref={register({ required: true })} />
                                                    {errors.lastName && <p style={{ color: "red" }}>{translate("Last name is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="companyName" className="form-control"
                                                        placeholder={translate("Company name")} required="" ref={register} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="ein" className="form-control"
                                                        placeholder="EIN XX-XXXXXXX" required="" ref={register({ required: true, pattern: /^\d{2}\-?\d{7}$/i })} />
                                                    {errors.ein && <p style={{ color: "red" }}>{translate("ein_error")}</p>}

                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="addressLine1" className="form-control"
                                                        placeholder={translate("Address Line 1")} required="" ref={register({ required: true })} />
                                                    {errors.addressLine1 && <p style={{ color: "red" }}>{translate("Address is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="addressLine2" className="form-control"
                                                        placeholder={translate("Address Line 2")} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="city" className="form-control"
                                                        placeholder={translate("City")} required="" ref={register({ required: true })} />
                                                    {errors.city && <p style={{ color: "red" }}>{translate("City is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="state" className="form-control"
                                                        placeholder={translate("State")} required="" ref={register({ required: true })} />
                                                    {errors.state && <p style={{ color: "red" }}>{translate("State is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="zip" className="form-control"
                                                        placeholder={translate("Zip")} required="" ref={register({ required: true, pattern: /^\d{5}(?:[-\s]\d{4})?$/i })} />
                                                    {errors.zip && <p style={{ color: "red" }}>{translate("A valid zip is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="country" className="form-control"
                                                        placeholder={translate("Country")} required="" ref={register({ required: true })} />
                                                    {errors.country && <p style={{ color: "red" }}>{translate("Country is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="phone" className="form-control"
                                                        placeholder={translate("Phone number")} required="" ref={register({ required: true, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i })} />
                                                    {errors.phone && <p style={{ color: "red" }}>{translate("A valid phone number is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" name="email" className="form-control" placeholder={translate("Email")}
                                                        required="" ref={register({ required: true })} />
                                                    {errors.email && <p style={{ color: "red" }}>{translate("Email is required")}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="file">{translate("Certificate of Exemption")}</label>
                                                    <input type="file" name="file" className="form-control" required="" ref={register({ required: true })}
                                                        onChange={() => handleFileChange} accept="application/pdf"
                                                    />
                                                    {errors.file && <p style={{ color: "red" }}>{translate("File is required and must be a PDF  ")}</p>}
                                                </div>
                                                <ReCAPTCHA
                                                    sitekey={customer.recaptchaKey}
                                                    onChange={onChange}
                                                />
                                                <div className="col-md-12 mt-3">
                                                    <button className="btn btn-solid text-capitalize" type="submit">{translate("submit")}</button>
                                                    <p>{taxFormSubmittedText}</p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default withTranslate(AccountSettings);
