import produce from 'immer';
import {
  UPDATE_ORDER_NOTES,
  UPDATE_SHOW_TEXTBOX,
  UPDATE_SHOW_RETURN_ADDRESS,
  UPDATE_RETURN_ADDRESS
} from '../constants/ActionTypes';

const initialState = {
    orderNotes: '',
    showTextBox: false,
    showAlternateReturnAddr: false,
    alternateReturnAddr: {
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Alabama',
        country: 'United States',      
        zipcode: ''

    }

};

const orderOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ORDER_NOTES:
            return { ...state, orderNotes: action.notes };
        case UPDATE_SHOW_TEXTBOX:
            return { ...state, showTextBox: action.value };
        case UPDATE_SHOW_RETURN_ADDRESS:
            return { ...state, showAlternateReturnAddr: action.value };
        case UPDATE_RETURN_ADDRESS:
            var {alternateReturnAddr} = state;
            var updatedAdd = {
                ...alternateReturnAddr,
                ...action.address
            }
            return { ...state, alternateReturnAddr: updatedAdd};
        default:
            return state;
    }
}

export default orderOptionsReducer;