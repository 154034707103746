import {useSelector} from "react-redux";
import React from "react";
import {useHistory} from "react-router";
import {Redirect, Route} from 'react-router-dom';

const ProtectedRoute = (props) => {

    const {customerAccessToken, loggedIn, loggedInUserDetails} = useSelector(state => state.customer);
    const {path, component, exact, key} = props;
    const loginPath = `${process.env.PUBLIC_URL}/login`;
    const history = useHistory();
    const isValidLogin = () => {
        if (!loggedIn || Object.keys(loggedInUserDetails).length === 0)  // return false if loggedIn is false
            return false;
        if (customerAccessToken && customerAccessToken.accessToken && customerAccessToken.expiresAt){
            const expireMillis = new Date(customerAccessToken.expiresAt).getTime();
            const currentMillis = Date.now();

            if (currentMillis > expireMillis) // if current time exceeds token expiration time, return false
                return false
        }
        return true;
    }
    if (!isValidLogin()){
        if (history.location.pathname === path)
            return <Redirect to={`${loginPath}?redirectUrl=${path}`} />
        else return <Route  path={path} component={component} exact={exact} key={key} />
    }else return (
        <Route  path={path} component={component} exact={exact} key={key} />
    )


}

export default ProtectedRoute;