import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import { SlideToggle } from "react-slide-toggle";
import classnames from "classnames";
import { IconClose } from "../../common/svg-icons";
import { Link, withRouter } from "react-router-dom";
import store from "../../../store";
import {CustomerAction} from "../../../reducers/customer";
import withTranslate from "react-redux-multilingual/lib/withTranslate";

const menus = [
  {
    title: "order history",
    url: "/orders",
    subMenus: [
      { title: "Orders", url: "/account/orders/summary" },
      { title: "Artwork", url: "/account/artwork" }
    ]
  },
];

class LeftAccountMenu extends Component {

  
  constructor(props) {
    super(props);
    
    this.state = {
      openFilter: false
    };
  }
  
  onLogOut = () => {
    store.dispatch(CustomerAction.customerLogOut());
    this.props.history.push('/login');
  };
  
  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -450px";
  };
  
  render() {
    const { translate } = this.props;
    return (
      <div className="collection-filter">
        <div className="collection-filter-block left-account-menu">
          {/*brand filter start*/}
          <div className="collection-collapse-block">
            <Link to="/account" className="text-secondary btn-logout my-account-link">
              <h3 className="collapse-block-title">{translate("My Account")}</h3>
            </Link>
          </div>

          {menus.map((mainMenuItem, index) => (
            <SlideToggle key={index}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <div className="collection-collapse-block">
                  <h3
                    className={classnames({
                      "collapse-block-title": true,
                      "collapse-block-title-expanded":
                        toggleState.search("EXPAND") >= 0,
                      "collapse-block-title-collapsed":
                        toggleState.search("COLLAP") >= 0
                    })}
                    onClick={onToggle}
                  >
                    {" "}
                    {translate(mainMenuItem.title)}{" "}
                  </h3>
                  <div
                    className="collection-collapse-block-content"
                    ref={setCollapsibleElement}
                  >
                    <div className="block-menu-content">
                      {mainMenuItem.subMenus.map((subMenuItem, index) => (
                        <div className="left-account-sub-menu" key={index}>
                          <Link
                            to={subMenuItem.url}
                            className={classnames({
                              "text-secondary":
                                window.location.href.search(subMenuItem.url) <
                                0,
                              "text-primary":
                                window.location.href.search(subMenuItem.url) >=
                                0
                            })}
                          >
                            {translate(subMenuItem.title)}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </SlideToggle>
          ))}
          <div className="collapse-block-title">
            <Link to="/" className="text-secondary btn-logout" onClick={this.onLogOut}>
              {translate("Logout")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslate(LeftAccountMenu));
