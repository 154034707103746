import { client } from "./index";
import * as storefrontQuerys from "./graphql/querys";
import axios from 'axios';

export default class Util {
  static getCurrencySymbolFromCode = (code) => {
    if (code === "USD" || code === "CAD")
      return "$"
    else return "EUR ";
  };

  static renderAmount(amount) {
    return parseFloat(amount).toFixed(2);
  }

  static cropSEODescription = (description) => {
    if (description) {
      if (description.length > 320) {
        return description.slice(0, 320);
      }
      return description;
    } else {
      return '';
    }
  };

  static cropSEOTitle = (title) => {
    if (title) {
      if (title.length > 70) {
        return title.slice(0, 70);
      }
      return title;
    }
    else {
      return '';
    }
  };

  static stripHtmlTags = (str) => {
    if ((str === null) || (str === '') || (str === undefined))
      return '';
    else
      str = str.toString();
    return str.replace(/(<[^>]*>)*(&nbsp;)*/g, '');
  };

  static getReseller = () => {
    const url = window.location.host;
    var hostSplits = url.split('.');
    var reseller = "";
    if (process.env.REACT_APP_RESELLER_ID) {
      return process.env.REACT_APP_RESELLER_ID;
    }
    if (hostSplits.length > 1 && (hostSplits.indexOf("whitelabellabels") > -1 || hostSplits.indexOf("readysetprint") > -1)) {
      reseller = hostSplits[0];
    } else if (typeof window.subdomain != "undefined") {
      reseller = window.subdomain;
    }
    return reseller;
  }

  static getPricingObject = (selectedVariantBarcode, priceJson) => { // generates an object from json file which contains discounted prices of product and addons, else returns blank object
    let priceObject = {
      msrp_markup_multiplier: {},
      prices: {},
      overlamination: {},
      foil: {},
      matte: {},
      tape: {},
      imprint: {},
      numbering: {}
    };
    if (selectedVariantBarcode) {
      const split = selectedVariantBarcode.split("_");
      const rowNumber = split[split.length - 1];
      if (parseInt(rowNumber) > 0 && selectedVariantBarcode != "" && typeof priceJson['quantity_breaks'] == "object") {
        priceJson['quantity_breaks'].forEach(function (qty, index) {
          if (typeof priceJson['msrp_markup_multiplier'] == "object") {
            priceObject.msrp_markup_multiplier[qty] = priceJson['msrp_markup_multiplier'][index];
          }
          if (typeof priceJson['net_row_' + rowNumber] == "object") {
            priceObject.prices[qty] = priceJson['net_row_' + rowNumber][index];
          }

          if (typeof priceJson['net_overlam_' + rowNumber] == "object") {
            priceObject.overlamination[qty] = priceJson['net_overlam_' + rowNumber][index];
          } else if (typeof priceJson['net_overlam_1'] == "object") {
            priceObject.overlamination[qty] = priceJson['net_overlam_1'][index];
          }

          if (typeof priceJson['net_foil_' + rowNumber] == "object") {
            priceObject.foil[qty] = priceJson['net_foil_' + rowNumber][index];
          } else if (typeof priceJson['net_foil_1'] == "object") {
            priceObject.foil[qty] = priceJson['net_foil_1'][index];
          }

          if (typeof priceJson['net_matte_varnish_' + rowNumber] == "object") {
            priceObject.matte[qty] = priceJson['net_matte_varnish_' + rowNumber][index];
          } else if (typeof priceJson['net_matte_varnish_1'] == "object") {
            priceObject.matte[qty] = priceJson['net_matte_varnish_1'][index];
          }

          if (typeof priceJson['net_double_sided_tape_' + rowNumber] == "object") {
            priceObject.tape[qty] = priceJson['net_double_sided_tape_' + rowNumber][index];
          } else if (typeof priceJson['net_double_sided_tape_1'] == "object") {
            priceObject.tape[qty] = priceJson['net_double_sided_tape_1'][index];
          }

          if (typeof priceJson['net_back_imprint_' + rowNumber] == "object") {
            priceObject.imprint[qty] = priceJson['net_back_imprint_' + rowNumber][index];
          } else if (typeof priceJson['net_back_imprint_1'] == "object") {
            priceObject.imprint[qty] = priceJson['net_back_imprint_1'][index];
          }

          if (typeof priceJson['net_numbering_' + rowNumber] == "object") {
            priceObject.numbering[qty] = priceJson['net_numbering_' + rowNumber][index];
          } else if (typeof priceJson['net_numbering_1'] == "object") {
            priceObject.numbering[qty] = priceJson['net_numbering_1'][index];
          }

        });
      }
    }
    priceObject = (Object.keys(priceObject.prices).length > 0) ? priceObject : false;
    return priceObject;
  }

  static myTruncate = (str) => {
    var ret = [];
    var strs = str.split('.');
    while (ret.join('. ').length < 350) {
      ret.push(strs.shift());
    }
    return ret.join('. ') + '.';
  }

  static reorderLineItem = (item, addToCart) => {
    if (item.variant) {
      // TAGS
      const lot_pricing_tag = "Lot Pricing";

      const splittedSku = item.variant.sku.split("-");
      const selectedVariantBarcode = splittedSku[splittedSku.length - 1];
      let lot_pricing = false;

      let productData = {};
      return client.send(storefrontQuerys.getProductByHandle(`${item.variant.product.handle}`)).then(({ data }) => {
        if (!data.productByHandle) return;
        data.productByHandle.desc = data.productByHandle.descriptionHtml.replace(/<li>.*<\/li>/gi, '');
        var matches = data.productByHandle.descriptionHtml.match(/<li>.*<\/li>/gi);
        if (matches) {
          data.productByHandle.specs = matches.join('');
        } else {
          data.productByHandle.specs = null;
        }
        data.productByHandle.descTruncated = Util.myTruncate(data.productByHandle.description);
        productData = data.productByHandle;
        let markup = 0;
        let markupTag = productData.tags.find(tag => tag.indexOf('markup=') > -1);
        if (markupTag && markupTag != "") {
          markup = markupTag.replace('markup=', '');
          markup = Number(markup);
          if (isNaN(markup)) {
            markup = 0;
          }
        }

        let price_file_url = false;
        let priceGridTag = productData.tags.find(tag => tag.indexOf('$=') > -1);
        if (priceGridTag && priceGridTag != "") {
          priceGridTag = priceGridTag.replace('$=', '');
          const currentEnvironment = process.env.REACT_APP_URL_PRICING_JSON ? process.env.REACT_APP_URL_PRICING_JSON : "https://designer.readysetprint.com/apps/shopify-json/pricing";
          price_file_url = currentEnvironment + "/" + priceGridTag + '.json';
        }
        lot_pricing = productData.tags.includes(lot_pricing_tag);
        if (price_file_url) {
          return axios.get(price_file_url)
            .then(res => {
              const priceObject = Util.getPricingObject(selectedVariantBarcode, res.data)

              let properties = {};

              for (let customAttribute of item.customAttributes) {
                properties[customAttribute.key] = customAttribute.value === "false" ? false : customAttribute.value;
              }

              let newCartItem = {
                ...item,
                lineItemId: Date.now(),
                id: item.variant.id,
                selectedVariant: item.variant,
                barcode: selectedVariantBarcode,
                properties,
                lot_pricing,
                priceObject,
                productData,
                markup
              };
              return addToCart(newCartItem, item.currentQuantity);

            });
        } else {
          console.error('pricing tag unavailable');
        }


      })
        .catch(err => { throw err })
    } else {
      return null;
    }
  }

  static validatePricingJson = (json, variants) => {
    let isJsonValid = true;
    let errorMessage = "";

    //Check if quantity_breaks element exists
    if (!json.quantity_breaks) {
      isJsonValid = false;
      errorMessage += "- quantity_breaks element is missing.";
    }
    //Check if msrp_markup_multiplier element exists
    if (!json.msrp_markup_multiplier) {
      isJsonValid = false;
      errorMessage += "\n- msrp_markup_multiplier element is missing.";
    }

    //Check that all net_[barcode] elements exists
    variants.forEach(variant => {
      const variantPrice = variant.node.price;
      const splittedSku = variant.node.sku.split("-");
      const variantBarcode = `net_${splittedSku[splittedSku.length - 1]}`;
      if (!json[variantBarcode]) {
        isJsonValid = false;
        errorMessage += `\n- ${variantBarcode} element is missing.`;
      } else {

        //Check that there are no elements in net_rows with higher pricing than the base variant
        const higherValueExists = json[variantBarcode].find(e => e > variantPrice);
        if (higherValueExists) {
          isJsonValid = false;
          errorMessage += `\n- There is an element in ${variantBarcode} with value ${higherValueExists} that is higher than the variant's based price ${variantPrice}`;
        }

        json[variantBarcode].forEach((barcode, index) => {

          const isInt = Number(barcode) === barcode && barcode % 1 === 0;
          const isFloat = Number(barcode) === barcode && barcode % 1 !== 0;

          if (!isInt && !isFloat) {
            isJsonValid = false;
            errorMessage += `\n- ${barcode} in position ${index + 1} of ${variantBarcode} is neither an integer or a float.`;
          }
        })
      }
    });

    //Check that all arrays are the same length
    let keyLength = -1;
    Object.keys(json).every(key => {
      if (keyLength !== -1) {
        if (json[key].length !== keyLength) {
          isJsonValid = false;
          errorMessage += `\n- Not all array elements have the same length`;
          return false;
        }
      }
      keyLength = json[key].length;
      return true;
    });

    //Check if quantity_breaks elements are integers
    if (json.quantity_breaks) {
      const quantity_breaks = json.quantity_breaks;
      quantity_breaks.every((quantity, index) => {
        const isInt = Number(quantity) === quantity && quantity % 1 === 0;
        if (!isInt) {
          isJsonValid = false;
          errorMessage += `\n- ${quantity} in position ${index + 1} of quantity_breaks is not an integer.`
          return false;
        }
        return true;
      });
    }

    //Check if msrp_markup_multiplier elements are integers or floats
    if (json.msrp_markup_multiplier) {
      const msrp_markup_multiplier = json.msrp_markup_multiplier;
      msrp_markup_multiplier.forEach((msrp, index) => {
        const isInt = Number(msrp) === msrp && msrp % 1 === 0;
        const isFloat = Number(msrp) === msrp && msrp % 1 !== 0;

        if (!isInt && !isFloat) {
          isJsonValid = false;
          errorMessage += `\n${msrp} in position ${index + 1} of msrp_markup_multiplier is neither an integer or a float.`
        }
      })
    }

    return {
      isJsonValid,
      errorMessage
    }
  }

  static getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  static fetchAndParseNetlifySnippet = async (reseller_id, title, windowName) => {
    const response = await axios.post(
      process.env.REACT_APP_TAGGING_API_URL + "/netlify-fetch-snippet",
      {
        reseller_id,
        title
      },
      { 'Content-Type': 'application/json' }
    );

    if (response && response.data && response.data.general) {
      const replacedData = response.data.general.replace("<script>", "").replace("</script>", "").replace(`window.${windowName}=`, "").replace(`window.${windowName} = `, "").replace(`window.${windowName} =`, "").replace('};', "}");
      try {
        const parsed = JSON.parse(replacedData)
        return parsed;
      } catch (err) {
        debugger
        return replacedData;
      }
    }

    return null;
  }
  static getSpecsObj(specs) {
    specs = specs.replaceAll('<li>', '').replaceAll('</li>',',').split(',');
    specs = specs.map(entry => entry.split(':'))
    specs = this.arrayToObj(specs);
    //console.log(specs);
    return specs;
  }
  static arrayToObj = function(arr) {
    let obj = {};
    arr.forEach((v) => {
        let key = v[0].trim();
        let value = v[1];
        obj[key] = value;
    });
    return obj;
  }

  static getRecaptchaKey = function() {
    if (process.env.REACT_APP_SITE_RECAPTCHA_KEY) {
      return process.env.REACT_APP_SITE_RECAPTCHA_KEY;
    }
    return null;
  }
}
