import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {Link, useHistory} from 'react-router-dom'
import {CustomerAction} from "../../../../reducers/customer";
import Locales from "../common/locales";
import store from "../../../../store";
import { IntlActions, withTranslate } from 'react-redux-multilingual';
import Util from "../../../../util"

const SideBar = (props) => {

    const loggedIn = useSelector(state => state.customer.loggedIn);
    const history = useHistory();
    const [primaryMenu, setPrimaryMenu] = useState({
        items: []
    });

    const fetchMenuSnippet = async () => {
        const results = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Menu", "Menu");
        if(results && results.length > 0){
            const primaryMenu = results.find(m => m.id === "hamburger-menu");
            setPrimaryMenu(primaryMenu);
        }
    }

    useEffect(() => {
        
        if (process.env.REACT_APP_RESELLER_ID) {
            fetchMenuSnippet();
        }

    }, [process.env.REACT_APP_RESELLER_ID]);

    useEffect(() => {
        
        let menu = window.Menu;
        
        if (menu) {
            const primaryMenu = menu.find(m => m.id === "hamburger-menu");
            setPrimaryMenu(primaryMenu);
        }

        if (window.location.href.includes("fr.gfsimpress") || window.location.href.includes("-test-fr") || window.location.href.includes("fr-gfsimpress-readysetprint") || window.location.href.includes("clara-staging-site")) {
            store.dispatch(IntlActions.setLocale('fr'));
            localStorage.setItem("locale", "fr");
        } else {
            store.dispatch(IntlActions.setLocale('en'));
            localStorage.setItem("locale", "en");
        }
    }, []);

    const closeNav = () => {
        const closemyslide = document.getElementById("mySidenav");
        if (closemyslide)
            closemyslide.classList.remove('open-side');
    };

    const onLogOut = () => {
       store.dispatch(CustomerAction.customerLogOut());
        history.push('/login');
    };

    const { translate } = props;

    const showLocales = window.location.href.indexOf("gfsimpress") > -1 
    || window.location.href.indexOf("localhost") > -1
    || window.location.href.indexOf("deploy-preview") > -1;

    return (
      <div id="mySidenav" className="sidenav">
          <a href="javascript:void(0)" className="sidebar-overlay" onClick={closeNav}></a>
          <nav>
              <a onClick={closeNav}>
                  <div className="sidebar-back text-left">
                      <i className="fa fa-angle-left pr-2" aria-hidden="true"></i> {translate("Close")}
                  </div>
              </a>
              <ul id="sub-menu" className="sidebar-menu">
                  {
                      primaryMenu.items.map((item, index) => (
                        <li key={index}>
                            {
                                item.link && item.link.includes("http")
                                  ? <a href={item.link} onClick={closeNav}>{item.title}</a>
                                  : (<Link className="menu-item" to={item.link} onClick={closeNav} >{item.title}</Link>)
                            }
                        </li>
                    ))
                  }
                  <li>
                      <Link to="/account" className="main-item" onClick={closeNav}>
                          {translate("MY ACCOUNT")}
                      </Link>
                  </li>
                  <li>
                      {
                          !loggedIn
                            ? (
                              <Link to={
                                  { pathname: "/login", state: { from: history.location.pathname } }
                                } className="main-item" onClick={closeNav}>
                                  {translate("Login / Signup")}
                              </Link>
                            ) : (
                              <Link to="#" className="main-item" onClick={onLogOut}>
                                  {translate("Logout")}
                              </Link>
                            )
                      }
                  </li>
                  {showLocales ? (
                    <Locales sidebar={true}/>
                  ) : ""}
              </ul>
          </nav>
      </div>

    )
};

export default withTranslate(SideBar);
