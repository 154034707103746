import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import * as storefrontQuerys from '../../graphql/querys';
import { client } from '../../index';
import {toast} from 'react-toastify';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

class ForgetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: null
        }
    }

    componentDidMount = () => {
        window.analytics.page();
    }

    submitForget = (ev) => {
        ev.preventDefault();

        if (this.state.email) {
        client.send(storefrontQuerys.recoverPasswordMutation(this.state.email))
            .then(({ data }) => {
                if (data.customerRecover && data.customerRecover.customerUserErrors.length) {
                    toast.error(this.props.translate('reset_password_error'), true);
                } else {
                    this.setState({
                        email: null
                    });
                    document.getElementById("email").value = "";
                    toast.success(this.props.translate('reset_password_check_email'));
                }
            })
            .catch(() => {
                toast.error(this.props.translate('reset_password_error'), true);
            })
        } else {
            toast.error(this.props.translate("Please enter an email"))
        }

    }

    render() {

        const { translate } = this.props;

        return (
            <div>
                <Breadcrumb title={'forget password'} />

                {/*Forget Password section*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Reset Your Password</h2>
                                <form className="theme-form" onSubmit={this.submitForget}>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="email" className="form-control" id="email"
                                                placeholder="Enter Your Email" required="" onChange={(ev) => this.setState({ email: ev.target.value })} />
                                        </div>
                                        <button type="submit " className="btn btn-solid text-capitalize">{translate("submit")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(ForgetPassword)