import React, { Component } from 'react';
import Util from "../../../util"

import {
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment
} from "../../../services/script"

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoBar: {}
        }
    }

    componentDidMount = async () => {
        let promoBar =  window.promoBar;
        if (process.env.REACT_APP_RESELLER_ID) {
            promoBar = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Promo Bar", "promoBar");
        }

        if (promoBar) {
            this.setState({ promoBar });
        }
    }

    render() {

        const { promoBar } = this.state

        return (
            <>
                {promoBar && promoBar.on &&
                    <div className="collection-filter-block ">
                        <div className="product-service">
                            {promoBar.slots.map((slot, index) => {
                                return <React.Fragment key={`${slot.headline}-${index}`}>
                                {slot.headline !== "" && slot.iconUrl !== null &&
                                    <div className="media" >
                                        <img src={slot.iconUrl} style={{ maxWidth: "50px", maxHeight: "50px", marginRight: "20px" }} />
                                        <div className="media-body">
                                            <h4 className="promo-bar-title">{slot.headline}</h4>
                                            <p className="promo-bar-subtitle">{slot.headlineSubtitle}</p>
                                        </div>
                                    </div>
                                }
                                </React.Fragment>
                            }
                            )}
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default Service;