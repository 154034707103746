import React, {Component} from 'react';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

import Breadcrumb from "../common/breadcrumb";

class PageNotFound extends Component {

    render (){

        const { translate } = this.props;

        return (
            <div>
                <Breadcrumb title={translate('404 Page')}/>

                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section not-found">
                                    <h1 style={{fontSize: 80}} className="mb-3">404</h1>
                                    <div className="d-flex flex-column align-items-center mb-3" style={{maxWidth: "460px", width: "90%", margin: "auto"}}>
                                        <p className="mb-3" style={{fontSize: 35, fontWeight: "bold", lineHeight: "1.2em"}}>{translate("404_error_title")}</p>
                                        <p>{translate("404_error_subtitle")}</p>
                                    </div>
                                    <a href="/" className="btn btn-solid">{translate("back to home")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withTranslate(PageNotFound)