import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    UPDATE_CART,
    DECREMENT_QTY,
    CLEAR_CART
} from "../constants/ActionTypes";
import store from "../store";




function isEquivalent(a, b) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

function checkIfInCart(item, state){
    const filteredItems = state.cart.filter(cartItem => cartItem.id == item.id && isEquivalent(cartItem.properties, item.properties));
    return filteredItems.length > 0;
}

export default function cartReducer(state = {
    cart: []
}, action) {

    switch (action.type) {
        case ADD_TO_CART:
            const currentItem = action.product;
            if (checkIfInCart(currentItem, state)) {
                let cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === currentItem.id && isEquivalent(currentItem.properties, product.properties)) {
                        cartAcc.push({ ...product, qty: product.qty+action.qty }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty }] }

        case UPDATE_CART:
            const cart = action.cart;
            return { ...state, cart }

        case DECREMENT_QTY:
            
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                let cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        cartAcc.push({ ...product, qty: product.qty-1, sum: (product.price*product.discount/100)*(product.qty-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: action.product.price*action.qty }] }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => item.lineItemId != action.product.lineItemId)
            }
        case CLEAR_CART:
            return {
                cart: []
            }

        default:
    }
    return state;
}

export class CartAction {
    static clearCart = () => {
        store.dispatch({
            type: CLEAR_CART
        })
    }
}
