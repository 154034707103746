import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Provider, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';
import {IntlProvider} from 'react-redux-multilingual';
import GraphQLClient from 'graphql-js-client';
import './index.scss';
import 'react-toastify/dist/ReactToastify.min.css';

// Import custom components
import store from './store';
import translations from './constants/translations';
import {getAllShopDetails} from './actions';

import typeBundle from './types/types';
// Layouts
import Fashion from './components/layouts/fashion/main';
import CategoryDetailsPage from './components/collection/category-details-page';
//Collection Pages
import CollectionNoSidebar from './components/collection/collection-no-sidebar';
import CollectionRightSidebar
    from './components/collection/collection-right-sidebar';
// Product Pages
import LeftSideBar from './components/products/left-sidebar';
// Features
import App from './components/app';
import Cart from './components/cart';
// Extra Pages
import page from './components/pages/page';
import PageNotFound from './components/pages/404';
import lookbook from './components/pages/lookbook';
import Login from './components/pages/login';
import Register from './components/pages/register';
import Search from './components/pages/search';
import Collection from './components/pages/collection';
import ForgetPassword from './components/pages/forget-password';
import Contact from './components/pages/contact';
import Dashboard from './components/pages/dashboard';
import Faq from './components/pages/faq';

import AccountLayout from './components/pages/account';
import {CustomerAction} from "./reducers/customer";
import ProtectedRoute from "./utils/ProtectedRoute";
import {CartAction} from "./reducers/cart";
import Util from './util';

export const client = new GraphQLClient(typeBundle, {
    // url: 'https://white-label-printing.myshopify.com/api/graphql',
    url: process.env.REACT_APP_SHOPIFY_GRAPHQL_STOREFRONT_API_URL,   // versioned to 2020-04
    fetcherOptions: {
        headers: {
            'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN
        }
    }
});

const Root = () => {

    const {customerAccessToken} = useSelector(state => ({
        customerAccessToken: state.customer.customerAccessToken,
        loggedIn: state.customer.loggedIn,
        loggedInUserDetails: state.customer.loggedInUserDetails
    }));

    // Fetch the Punchout2Go search query parameter to pass as a prop
    let params = new URLSearchParams(document.location.search.substring(1));
    let punchoutQueryParam = params.get("punchout") == "true" ? true : false;

    useEffect(() => {
        let cookie = document.cookie;
        let cookies = cookie.split(";");
        if (cookies.length > 0) {
            const _checkoutstarted = cookies.find(c => c.includes("_checkoutstarted=true"));
            const _checkoutcomplete = cookies.find(c => c.includes("_checkoutcomplete=true"));
            const localHostCheckoutStarted = localStorage.getItem('_checkoutstarted');
            const localHostCheckoutComplete = localStorage.getItem('_checkoutcompleted');
            if ((_checkoutstarted && _checkoutcomplete) || (localHostCheckoutStarted && localHostCheckoutComplete)) {
                //delete cart
                //delete cookies
                localStorage.removeItem('_checkoutstarted');
                localStorage.removeItem('_checkoutcompleted');
                document.cookie = `_checkoutstarted=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;`;
                document.cookie = `_checkoutcomplete=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;`;
                CartAction.clearCart();
            }
        }
        const reseller_id = Util.getReseller();
        store.dispatch(getAllShopDetails(reseller_id));
        const reCaptchaKey = Util.getRecaptchaKey();
        store.dispatch(CustomerAction.updateRecaptchaKey(reCaptchaKey));
    }, []);

    useEffect(() => {
        const {accessToken} = customerAccessToken;
        if (accessToken) {
            CustomerAction.fetchCustomerDetails(accessToken)
        }
    }, [customerAccessToken])

    return (
        <>
            <IntlProvider translations={translations}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <App isPunchout={punchoutQueryParam}>
                            <Switch>

                                {/*Routes For Layouts*/}
                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Fashion}/>
                                <Route path={`${process.env.PUBLIC_URL}/products/:id`}>
                                    <LeftSideBar isPunchout={punchoutQueryParam} />
                                </Route>
                                <Route exact path={`${process.env.PUBLIC_URL}/category/:handle`}
                                       component={CategoryDetailsPage}/>
                                <Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
                                <ProtectedRoute path={`${process.env.PUBLIC_URL}/account`} key="/account" component={AccountLayout}/>
                                <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                                <Route path={`${process.env.PUBLIC_URL}/register`} component={Register}/>


                                {/*Routes For Features (Product Collection) */}
                                <Route path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                                       component={CollectionNoSidebar}/>
                                <Route path={`${process.env.PUBLIC_URL}/right-sidebar/collection`}
                                       component={CollectionRightSidebar}/>
                                <Route path={`${process.env.PUBLIC_URL}/search`} component={Search}/>

                                {/*Routes For Extra Pages*/}
                                <Route path={`${process.env.PUBLIC_URL}/pages/404`} component={PageNotFound}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/lookbook`} component={lookbook}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/collection`} component={Collection}/>
                                <Route path={`${process.env.PUBLIC_URL}/forget-password`} component={ForgetPassword}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/contact`} component={Contact}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/dashboard`} component={Dashboard}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/:handle`} component={page}/>

                                <Route component={PageNotFound} />
                            </Switch>
                        </App>
                    </ScrollContext>
                </BrowserRouter>
            </IntlProvider>
            <ToastContainer/>
        </>
    );
}

ReactDOM.render(<Provider store={store}><Root client={client}/></Provider>, document.getElementById('root'));
