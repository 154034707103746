import { toast } from 'react-toastify';

export const punchout_addToCart = async (newCartItem, quantity, finalPrice) => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let punchoutReturnUrlQueryParam = params.get("return_url");

    if (punchoutReturnUrlQueryParam) {
        let properties = {};
        Object.keys(newCartItem.properties).map(key => {
            properties[key] = newCartItem.properties[key];
        })
        const order = {
            line_items: [
                {
                    id: newCartItem.lineItemId,
                    variant_id: atob(newCartItem.selectedVariant.id).replace("gid://shopify/ProductVariant/", ""),
                    quantity,
                    title: newCartItem.productData.title,
                    variant_title: newCartItem.selectedVariant.title,
                    vendor: newCartItem.productData.vendor,
                    product_id: atob(newCartItem.productData.id).replace("gid://shopify/Product/", ""),
                    name: newCartItem.productData.title + " - " + newCartItem.selectedVariant.title,
                    properties: Object.keys(newCartItem.properties).map(key => {
                        return {
                            name: key,
                            value: newCartItem.properties[key]
                        }
                    }),
                    price: finalPrice,
                }
            ]
        }

        fetch(punchoutReturnUrlQueryParam, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ order })
        }).then(async response => {
            if (response.ok) {
                return response.json();
            } else {
                const error = await response.json();
                throw new Error(`Status: ${response.status}. Message: ${error.error}`);
            }
        })
            .then(response => {
                const redirect_url = response.redirect_url;
                if (redirect_url) {
                    window.location.href = redirect_url;
                } else {
                    toast.error(`Error while adding the item to your Punchout cart. No redirect_url.`);
                }
            })
            .catch(err => {
                toast.error(`Error while adding the item to your Punchout cart. ${err}`);

            });

    } else {
        toast.error(`Error adding the item to your cart: no return_url found. Please contact support if this issue persists.`);
    }
}
