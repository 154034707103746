import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom'
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

class DetailsTopTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeShow: true,
        }
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }

    render() {
        const { item, productData, translate } = this.props;

        return (
            <section className="tab-product m-0 pt-0">
                <div className="product-description row">
                    <div className="col-12">
                        <Tabs>
                            <TabList className="nav nav-tabs tab-coupon" >
                                <Tab className="nav-link sub-heading" onClick={(e) => this.clickActive(e)}>
                                    {translate("description")}</Tab>
                                {productData.specs
                                    ? <Tab className="nav-link specs sub-heading" onClick={(e) => this.clickActive(e)}>{translate("specs")}</Tab>
                                    : null
                                }
                            </TabList>

                            <TabPanel className="desc">
                                <span dangerouslySetInnerHTML={{ __html: productData.desc }} />
                            </TabPanel>
                            {productData.specs
                                ? <TabPanel className="specs">
                                    <p dangerouslySetInnerHTML={{ __html: productData.specs }} />
                                </TabPanel>
                                : null
                            }
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslate(DetailsTopTabs);