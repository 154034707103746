import React, { useEffect, useState } from "react";
import ArtworkCard from "./card";
import MobileSwitcher from "./mobile-switcher";
import { useSelector } from "react-redux";
import { CustomerAction } from "../../../../reducers/customer";
import DeleteConfirmation from "../../../modals/deleteConfirmationModal";
var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
const ArtworkList = () => {
  const { id,metafields } = useSelector(state => state.customer.loggedInUserDetails);
  const [formattedArtworks, setFormattedArtworks] = useState([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [metafieldId, setMetafieldId] = useState(0);
  const [customerId, setcustomerId] = useState(0);
  useEffect(() => {    
    if(id)
    {
      if (base64regex.test(id)) {
        CustomerAction.fetchCustomerMetafields(atob(id).split("/")[4]);
      }else {
        CustomerAction.fetchCustomerMetafields(id.split("/")[4]);
      }
      setTimeout(() => window.analytics.page(), 1000);
    }
  }, []);

  useEffect(() => {
    let formattedArtworks = [];
    if(metafields){
        for (let item of metafields) {
        formattedArtworks.push(item);
        
      }
      setFormattedArtworks(formattedArtworks);
    }
  }, [metafields])

  // Handle the displaying of the modal based on type and id
  const showDeleteModal = (metafieldId,customerId) => {
    setMetafieldId(metafieldId);
    setcustomerId(customerId);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setDisplayConfirmationModal(false);
    deleteArtwork(metafieldId,customerId);
  };
  const deleteArtwork = (metafieldId,customerId) => {
    CustomerAction.deleteCustomerMetafield(metafieldId,customerId);
  }
  return (
    <div className="section-order-summary ">
      <MobileSwitcher />
      <div className="dashboard-right ">
        <div className="dashboard rounded">
          <p className="h3 text-dark mb-5">Artwork</p>
          <div className={"d-flex align-content-center flex-wrap"}>
            {formattedArtworks.length <= 0 && <p>Your Artwork will appear here</p>}
            {formattedArtworks.map((el, index) => (
              <ArtworkCard item={el} key={`${el.canvasState}-${index}`} showDeleteModal={showDeleteModal} hideConfirmationModal={hideConfirmationModal}/>
            ))}
          </div>
        </div>
      </div>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal}  />
    </div>
  );
}

export default ArtworkList;
