import React, { Component } from "react";
import axios from 'axios';
import withTranslate from "react-redux-multilingual/lib/withTranslate";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Tooltip } from 'reactstrap';
import { cloudinaryMethods } from "../../services/cloudinary/cloudinary";
import isEqual from "lodash/isEqual"
var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
var editor = null;

//JSON Object returned from the finishProductDesign callback.
var renderData = null;
class ProductCanvasModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      diecutError: false,
      customDefinition: null,
      customTemplate: null,
      customShapeLoaded: false,
      currentCustomImagePath: false,
      customSizeIsSelected: false,
      inApprovalFlow: false,
      sizeOptions: [],
      qtyOptions: [],
      lastCustomHeight: 0,
      lastCustomWidth: 0,
      landscape: false,
      bleedTooltip: false,
      cutTooltip: false,
      cautionTooltip: false,
      bleedTooltipText: 'Bleed is the distance the ink extends past the finished edge of the product before final cutting.',
      cutTooltipText: 'Cut margin is the minimum space required between elements of art and the finished edge of the product.',
      cautionTooltipText: 'Area within the cut/trim line where you can be sure important text and graphics will not be trimmed off.',
      iOS: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
      isSaveArtwork: false,
      projectName: '',
      nameRequiredError: false,
      isDownloadProof: false
    };
  }

  componentDidMount() {
    if (!this.props.modalProps.customShape) {
      this.openCanvas();
    }
    this.setSizeAndQuantityOptions();
    this.handleLandscape();
    window.addEventListener("resize", this.handleLandscape.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (!this.state.customShapeLoaded) {
      if (this.props.modalProps.customShape) {
        if (this.props.modalProps.uploadedImagePath) {
          this.setMainHeightAndWidth(parseFloat((this.props.modalProps.uploadedImageWidth / 300).toFixed(2)), parseFloat((this.props.modalProps.uploadedImageHeight / 300).toFixed(2)));
          this.setState(
            {
              customSizeIsSelected: true,
              customShapeLoaded: true,
              customShapeUrls: this.props.modalProps.customShapeUrls,
              lastCustomWidth: parseFloat((this.props.modalProps.uploadedImageWidth / 300).toFixed(2)),
              lastCustomHeight: parseFloat((this.props.modalProps.uploadedImageHeight / 300).toFixed(2)),
              xHigher: this.props.modalProps.uploadedImageWidth >= this.props.uploadedImageHeight
            },
            () => {
              this.openCanvas();
            }
          );
        }
      }
    }
    if (!this.props.modalProps.customShape) {
      if (this.props.modalProps.canvasTemplate) {
        if (
          prevProps.modalProps.canvasTemplate && 
          this.props.modalProps.canvasTemplate.ID !==
          prevProps.modalProps.canvasTemplate.ID
        ) {
          this.handleTemplateChange();
        }
      }
    }
    if (this.props.canvasError && this.state.loading) {
      this.setState({
        loading: false
      })
    }

    if(!isEqual(prevProps.modalProps.productData,this.props.modalProps.productData)){
      this.initEditor();
    }
  }

  handleLandscape = () => {
    if (window.innerHeight < 385 && !this.state.landscape) {
      if(document.getElementById('saveAndNextButtonsWrapper')){
        document.getElementById('saveAndNextButtonsWrapper').classList.add('landscape');
      }
      this.setState({
        landscape: true
      })
    }
    if (this.state.landscape) {
      if (window.innerHeight >= 385) {
        if(document.getElementById('saveAndNextButtonsWrapper')){
          document.getElementById('saveAndNextButtonsWrapper').classList.remove('landscape');
        }
        this.setState({
          landscape: false
        })
      }
    }
  }

  setSizeAndQuantityOptions = () => { // On page load, set custom dropdown options based off whether custom sizes were selected
      let sizeString = ''
    if (this.props.modalProps.selectedCustomSize) {
        sizeString = `${this.props.modalProps.selectedCustomSize[0]}" x ${this.props.modalProps.selectedCustomSize[1]}"`
    }
    const sizeOptions = this.props.modalProps.selectedCustomSize ? [...this.props.sizeOptions, {label: sizeString, value: 'custom'}] : this.props.sizeOptions;
    let qtyOptions = [...this.props.qtyOptions];
    let selectedCustomQty = null;
    if (this.props.selectedQty === 'custom') {
        selectedCustomQty = document.getElementsByName('quantity')[0].value
        qtyOptions[qtyOptions.length - 1].label = selectedCustomQty;
    } else {
      if (qtyOptions.length > 0 && qtyOptions[qtyOptions.length - 1].value === 'custom') {
        qtyOptions.pop();
      }
    }
    this.setState({
        sizeOptions: sizeOptions,
        qtyOptions: qtyOptions,
        selectedCustomQty: selectedCustomQty
    })
  }

  openCanvas() {
    if (this.props.modalProps.customShape) {
      const {xHighLimit, xLowLimit, yHighLimit, yLowLimit } = this.props
      const { lastCustomWidth, lastCustomHeight } = this.state;
      let newCustomWidth = 0;
      let newCustomHeight = 0;
      const setDimensions = () => {
        this.setMainHeightAndWidth(newCustomWidth, newCustomHeight);
        this.setState({
          lastCustomWidth: newCustomWidth,
          lastCustomHeight: newCustomHeight
        })
        
      }
      if (lastCustomWidth >= lastCustomHeight) {
        if (lastCustomWidth > xHighLimit && lastCustomHeight < yLowLimit) { // if an image is too wide and not tall enough, it can't be scaled to print dimensions, throw error
          let errorMessage = 'The maximum width for this product is ' + xHighLimit + '" and the minimum height is ' + yLowLimit + '". The uploaded artwork cannot be scaled to those dimensions';
          console.log(errorMessage);
          this.props.handleError();
        } else if (lastCustomWidth < xLowLimit) { // if uploaded image width is too small, adjust image to min
          newCustomHeight = ((lastCustomHeight / lastCustomWidth) * xLowLimit).toFixed(2);
          if (newCustomHeight >= yLowLimit) {
            newCustomWidth = xLowLimit;
          } else {
            newCustomWidth = ((lastCustomWidth / lastCustomHeight) * yLowLimit).toFixed(2)
            newCustomHeight = yLowLimit;
          }
          setDimensions();
        } else if (lastCustomWidth > xHighLimit) { // if uploaded image width is too large, adjust image to max
          newCustomHeight = ((lastCustomHeight / lastCustomWidth) * xHighLimit).toFixed(2);
          if (newCustomHeight <= yHighLimit) {
            newCustomWidth = xHighLimit;
          } else {
            newCustomWidth = ((lastCustomWidth / lastCustomHeight) * yHighLimit).toFixed(2)
            newCustomHeight = yHighLimit;
          }
          setDimensions();
        } else if (lastCustomHeight > yHighLimit) { // handling for width in bounds but height too small
            newCustomWidth = ((lastCustomWidth / lastCustomHeight) * yHighLimit).toFixed(2)
            newCustomHeight = yHighLimit;
            setDimensions();
        } else if (lastCustomHeight < yLowLimit) { // same error as above, image cannot be scaled to fit
            newCustomWidth = ((lastCustomWidth / lastCustomHeight) * yLowLimit).toFixed(2)
            newCustomHeight = yLowLimit;
            if (newCustomWidth > xHighLimit) {
              let errorMessage = 'The maximum width for this product is ' + xHighLimit + '" and the minimum height is ' + yLowLimit + '". The uploaded artwork cannot be scaled to those dimensions';
              console.log(errorMessage);
              this.props.handleError();
            } else {
              setDimensions();
            }
        }
      } else {
        if (lastCustomHeight > xHighLimit && lastCustomWidth < yLowLimit) { // if an image is too wide and not tall enough, it can't be scaled to print dimensions, throw error
          let errorMessage = 'The maximum height for this product is ' + xHighLimit + '" and the minimum width is ' + yLowLimit + '". The uploaded artwork cannot be scaled to those dimensions';
          console.log(errorMessage);
          this.props.handleError();
        } else if (lastCustomHeight < xLowLimit) { // if uploaded image height is too small, adjust image to min
          newCustomWidth = ((lastCustomWidth / lastCustomHeight) * xLowLimit).toFixed(2);
          if (newCustomWidth >= yLowLimit) {
            newCustomHeight = xLowLimit;
          } else {
            newCustomHeight = ((lastCustomHeight / lastCustomWidth) * yLowLimit).toFixed(2)
            newCustomWidth = yLowLimit;
          }
          setDimensions();
        } else if (lastCustomHeight > xHighLimit) { // if uploaded image height is too large, adjust image to max
          newCustomWidth = ((lastCustomWidth / lastCustomHeight) * xHighLimit).toFixed(2);
          if (newCustomWidth <= yHighLimit) {
            newCustomHeight = xHighLimit;
          } else {
            newCustomHeight = ((lastCustomHeight / lastCustomWidth) * yHighLimit).toFixed(2)
            newCustomWidth = yHighLimit;
          }
          setDimensions();
        } else if (lastCustomWidth > yHighLimit) { // added proper handling for images with height in bounds but width too small
            newCustomHeight = ((lastCustomHeight / lastCustomWidth) * yHighLimit).toFixed(2)
            newCustomWidth = yHighLimit;
            setDimensions();
        } else if (lastCustomWidth < yLowLimit) { // same error as above, this image cannot be scaled to fit
            newCustomHeight = ((lastCustomWidth / lastCustomHeight) * yLowLimit).toFixed(2)
            newCustomWidth = yLowLimit;
            if (newCustomHeight > xHighLimit) {
              let errorMessage = 'The maximum height for this product is ' + xHighLimit + '" and the minimum width is ' + yLowLimit + '". The uploaded artwork cannot be scaled to those dimensions';
              console.log(errorMessage);
              this.props.handleError();
            } else {
              setDimensions();
            }
        }
      }
      this.buildDiecutDefinition();
    } else if (this.props.modalProps.selectedCustomSize) {
      this.buildCustomDefinition();
    } else {
      if (this.props.modalProps.canvasTemplate) {
        this.initEditor();
      } else {
        this.props.handleError();
      }
    }
  }

  validateTemplate = (definition) => { // checks for empty, or improperly formatted templates
    let allPass = true;
    if (definition) {
      for (let i = 0; i < definition.surfaces.length; i++) {
        if (!definition.surfaces[i].printAreas) {
          allPass = false;
        }
      }
    } else {
      allPass = false;
    }
    return allPass;
  };

  async initEditor() { // the bulk of the heavy lifting for the editor...initializes the iframe and sets the product definition and configuration based off product type
    const { modalProps } = this.props;
    const { customDefinition } = this.state;
    let currentLanguage = (window.location.href.includes("fr.gfsimpress") || window.location.href.includes("-test-fr")) ? "fr" : "en";
    const configuration = {
      userId: base64regex.test(modalProps.customerId) ? modalProps.customerId : btoa(modalProps.customerId),
      tokenId: modalProps.token,
      customStyle: modalProps.customShape
        ? "readysetprint-custom"
        : "readysetprint",
      initialMode: "Advanced",
      defaultLanguage: currentLanguage,
      rendering: modalProps.customShape
        ? { hiResOutputDpi: 300, proofImageFileFormat: "png" }
        : modalProps.canvasTemplate.Rendering,
      violationWarningsSettings: {
        safetyLineViolationWarningEnabled: false,
      },
      widgets: {
        BottomToolbar: {
          fullScreenButtonEnabled: false
        }
      }
    };
    if (modalProps.customShape) {
      configuration.widgets.ItemMenu = {"objectManipulationEnabled": false};
      configuration.widgets.TopToolbar = {"imageSelectButtonEnabled": false, "imageEditButtonEnabled": false};
      configuration.widgets.Toolbox = {"buttons": [
        {
          translationKey: "Toolbox.TEXT",
          translationKeyTitle: "Toolbox.TITLE_ADD_TEXT",
          iconClass: "cc-icon-add-text",
          buttons: ["Text", "RichText"]
        },
        {
          iconClass: "cc-icon-qr-code",
          buttons: ["QrCode", "LinearBarcode"]
        }, 
        {
          iconClass: "cc-icon-rectangle",
          buttons: ["Line", "Rectangle", "Ellipse"]
        },  
      ]};
      configuration.canvas = {"floatingItemToolbarEnabled": false};
      configuration.imageEditorEnabled = false;
    }
    let productDefinition = modalProps.customShape
      ? customDefinition
      : modalProps.selectedCustomSize
      ? customDefinition
      : modalProps.canvasTemplate.ProductDefinition;
    if (this.validateTemplate(productDefinition)) {
      const iframe = document.getElementById("editorFrame");
      var canvasData = JSON.parse(localStorage.getItem('customers_canvas'));
        try {
          if(canvasData){
          localStorage.removeItem('customers_canvas');
          configuration.quantity = canvasData.quantity;
          configuration.size = canvasData.size;
          }
          editor = await window.CustomersCanvas.IframeApi.loadEditor(
            iframe,
            canvasData ? canvasData.cc_state_id :  productDefinition,
            configuration
          );
          if(canvasData){
            this.props.handlePropertyDropdownChange({label: canvasData.size, value: canvasData.custom_size ? 'custom' : canvasData.size},
              {
                  action: "select-option",
                  name: "Size",
                  option: undefined
              });
          }        
          
        
        } catch (e) {
          console.log('The editor failed to load. The likely cause is a missing mockup file.');
          this.props.handleError();
        }
        if (modalProps.customShape) {
          editor = await window.CustomersCanvas.IframeApi.loadEditor(
            iframe,
            canvasData ? canvasData.cc_state_id :  productDefinition,
            configuration
          );
          let product = await editor.getProduct();
          await product.getProductModel();
          let Model = window.CustomersCanvas.DesignAtoms.ObjectModel;
          let surface = product.currentSurface;
          const imageItem = new Model.ImageItem(
            null,
            new Model.PointF(0, 0),
            modalProps.uploadedImageWidth,
            modalProps.uploadedImageHeight
          );
          imageItem.alignment = Model.TextAlignment.Center;
          let RectangleF = Model.RectangleF;
          imageItem.sourceRectangle = new RectangleF(
            0,
            0,
            surface.width,
            surface.height
          );
          imageItem.name = "Image";
          surface.insertItem(imageItem);
          let data = {};
          data.Image = modalProps.uploadedImagePath;
          await editor.loadUserInfo(data);
          document
            .getElementById("saveAndNextButtonsWrapper")
            .classList.add("loaded");
          this.toggleLoading();
        } else {
          document
            .getElementById("saveAndNextButtonsWrapper")
            .classList.add("loaded");
          this.toggleLoading();
        }
    }
  }

  toggleLoading = (updating = false) => { // controls the state of the loading animation
    if (updating) {
      this.setState({
        updating: this.state.updating ? false : true,
      });
    } else {
      this.setState({
        loading: this.state.loading ? false : true,
      });
    }
  };

  buildDiecutDefinition = async () => {
    const { lastCustomWidth, lastCustomHeight } = this.state;
    this.setState(
      {
        customDefinition: {
          surfaces: [{
              width: lastCustomWidth * 72,
              height: lastCustomHeight* 72,
              printAreas: [{
                  bounds: {
                      x: 0,
                      y: 0,
                      width: lastCustomWidth * 72,
                      height: lastCustomHeight * 72
                  },
                  safetyLines: [{
                      margin: 1,
                      color: 'rgba(196,196,196,0.6)',
                      stepPx: 2,
                      widthPx: 1
                  }]
              }],
          }]
        }
      }, 
      () => {
        this.initEditor();
      }
    )
  }

  buildCustomDefinition = async () => { // for custom die cuts and custom sizes, builds a custom template
    const { modalProps, isStaging } = this.props;
    const url =
      `https://${isStaging ? "designerqa" : "designer"}.readysetprint.com/apps/design-editor/stable`;
    if (modalProps.canvasTemplate && !modalProps.customShape) { // if a canvas template is present, the product is a custom size standard shape, so we download the available template and resize it in cloudinary
      let designFile = false;
      if (modalProps.canvasTemplate.ProductDefinition.surfaces) { // the following three if statements validate for correct template structure
        if (modalProps.canvasTemplate.ProductDefinition.surfaces[0].printAreas) {
          if (modalProps.canvasTemplate.ProductDefinition.surfaces[0].printAreas[0].designFile) {
            designFile = modalProps.canvasTemplate.ProductDefinition.surfaces[0].printAreas[0]
                .designFile;
            fetch(`${url}/api/ProductTemplates/designs/${designFile}?mode=File`)
              .then((data) => {
                data.blob().then((blobData) => {
                  const templateFile = new File([blobData], "customtemplate", {
                    type: "image/psd",
                  });
                  let formData = new FormData();
                  formData.append("file", templateFile);
                  let config = cloudinaryMethods.cloudinaryConfig();
                  formData.append("upload_preset", config.cloudinary.upload_preset);
                  let cloudUrl =
                    "https://api.cloudinary.com/v1_1/" +
                    config.cloudinary.cloud_name +
                    "/image/upload";
                  fetch(cloudUrl, {
                    method: "POST",
                    body: formData,
                    processData: false,
                    contentType: false,
                  }).then((response) => {
                    response.json().then((responseBody) => {
                      let sizingString =
                        "upload/w_" +
                        this.props.modalProps.selectedCustomSize[0] * 300 +
                        ",h_" +
                        this.props.modalProps.selectedCustomSize[1] * 300 +
                        ",c_scale/";
                      let imageUrl = responseBody.secure_url.replace(
                        "upload/",
                        sizingString
                      );
                      let imageResp = cloudinaryMethods.cloudinaryDownload(imageUrl);
                      imageResp
                        .then((cloudData) => {
                          let imgFormData = new FormData();
                          imgFormData.append("attachmentName", cloudData);
                          fetch(
                            `${url}/api/ProductTemplates/designs/custom-templates/${this.props.modalProps.anonymousUser ? 'anonymous' : btoa(this.props.modalProps.customerId)}`,
                            {
                              method: "POST",
                              body: imgFormData,
                              processData: false,
                              contentType: false
                            }
                          )
                            .then((response) => {
                              response.json().then((body) => {
                                this.setState(
                                  {
                                    customTemplate: body,
                                    customDefinition: {
                                      surfaces: [
                                        {
                                          printAreas: [
                                            {
                                              designFile: body,
                                            },
                                          ],
                                          mockup: null,
                                        },
                                      ],
                                      defaultSafetyLines: null,
                                    },
                                  },
                                  () => {
                                    this.initEditor();
                                  }
                                );
                              });
                            })
                            .catch(() => {
                              console.log("Unable to create custom template");
                              this.props.handleError();
                            });
                        })
                        .catch(() => {
                          console.log("Error uploading default template");
                          this.props.handleError();
                        });
                    });
                  });
                });
              })
              .catch(() => {
                console.log("Unable to fetch default template");
                this.props.handleError();
              });
          } else {
            this.props.handleError();
          }
        } else {
          this.props.handleError();
        }
      } else {
        this.props.handleError();
      }
    } else { // if it's a custom die cut, build a template based off the user input 
      this.setState(
        {
          customDefinition: {
            surfaces: [
              {
                width: this.props.modalProps.selectedCustomSize[0] * 72,
                height: this.props.modalProps.selectedCustomSize[1] * 72,
                printAreas: [
                  {
                    bounds: {
                      x: 0,
                      y: 0,
                      width: this.props.modalProps.selectedCustomSize[0] * 72,
                      height: this.props.modalProps.selectedCustomSize[1] * 72,
                    },
                    safetyLines: [
                      {
                        margin: 1,
                        color: "rgba(196,196,196,0.6)",
                        stepPx: 2,
                        widthPx: 1,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        () => {
          this.initEditor();
        }
      );
    }
  };

  handleApproval = async (saveFlag,downloadFlag) => { // get the thumbnails and the high res proofs
    if(saveFlag){
      this.setState({isSaveArtwork: true});
    }else{
      this.setState({isSaveArtwork: false});
    }

    if(downloadFlag){
      this.setState({isDownloadProof: true});
    }else{
      this.setState({isDownloadProof: false});
    }
    if (!this.state.customSizeIsSelected) {
      let productDefinition = this.props.modalProps.canvasTemplate.ProductDefinition;
      let product = await editor.getProduct();
      let newMockups = [];
      for (let i = 0; i < productDefinition.surfaces.length; i++) {
        newMockups.push({
          surface: product.surfaces[i],
          previewMockups: productDefinition.surfaces[i].previewMockups,
        });
      }
      product.setMockups(newMockups);
    }
    const _this = this;
    editor
      .getProofImages()
      .then(function(result) {
        editor
          .finishProductDesign()
          .then(function(result) {
            renderData = result;
            let properties = {
              print_ready_image: renderData.hiResOutputUrls[0],
              customer_canvas_state: renderData.stateId,
              thumbnail: renderData.proofImageUrls[0][0],
            };
            if (renderData.violationWarningData.length) {
              if (
                renderData.violationWarningData[0].qualityViolationState > 1
              ) {
                properties["low_dpi"] = true;
              }
            }
            _this.props.updateVariantProperties(properties);
            _this.openApprovalOverlay(renderData);
          })
          .catch(function(error) {
            renderData = error;
          });
      })
      .catch(function() {
      });
  };

  openApprovalOverlay = (renderData) => { // once we have the approval data, open the approval window
    this.setState({
        inApprovalFlow: true
    }, () => {
        document.getElementById('canvas-approval-proof-image-one').src = renderData.proofImageUrls[0][0]
        if (renderData.proofImageUrls.length > 1) {
          let imageTwo = document.getElementById('canvas-approval-proof-image-two');
          imageTwo.src = renderData.proofImageUrls[1][0];
          imageTwo.style.display = 'block';
        }
        document.getElementById('canvas-approval-size').textContent = this.props.modalProps.customShape ? `${this.state.lastCustomWidth}" x ${this.state.lastCustomHeight}"` : this.props.modalProps.selectedCustomSize ? `${this.props.modalProps.selectedCustomSize[0]}" x ${this.props.modalProps.selectedCustomSize[1]}"` : this.props.selectedSize;
    })
  };

  // save Artwork
  saveArtwork = async(e) => {   

    if(!this.state.projectName){
      this.setState({nameRequiredError: true});
      return;
    }else{
      this.setState({nameRequiredError: false});
    }
    
     let result = await editor.saveProduct(renderData.stateId)
     editor.getProofImages()
     // If the links to proof images were generated successfully.
     .then((renderData) => {
         // Get the links from the promise properties.
         //console.log('saveartwork',this.props);
        let width,height;
         if(this.props.selectedSize=='custom'){
            [width,height] = [...this.props.modalProps.selectedCustomSize];
         }
         let payload = {
            customer_id: this.props.customerId ? this.props.customerId.split('/')[4] : null,
            cc_state_id: result.stateId,
            custom_size: this.props.selectedSize=='custom',
            custom_qnty: this.props.selectedQty=='custom',
            product_id: this.props.modalProps.productData.id ? base64regex.test(this.props.modalProps.productData.id) ? this.props.modalProps.productData.id : btoa(this.props.modalProps.productData.id) : "",
            product_handle: this.props.modalProps.productData.handle,
            project_name: this.state.projectName,
            quantity: this.props.selectedQty=='custom' ? this.props.modalProps.selectedCustomQnty : this.props.selectedQty,
            size: this.props.selectedSize=='custom' ? `${width}" x ${height}"` : this.props.selectedSize,
            proof: this.props.selectedVariantProperties.print_ready_image,
            thumbnail: this.props.selectedVariantProperties.thumbnail,
            material: this.props.selectedVariantProperties.Material,
            product_name: this.props.modalProps.productTitle,
            white_halo: this.props.white_halo
          };
          if(!this.props.customerId){
            localStorage.setItem('unauthenticated_customers_canvas', JSON.stringify(payload));
            localStorage.setItem('unauthenticated_saved_project', true);
            this.props.history.push("/login");
          }
          else{ 
          axios.post(
            process.env.REACT_APP_TAGGING_API_URL + "/create-metafield",
            payload,
            { 'Content-Type': 'application/json' }
          ) .then(({ data }) => {
            if(data){
              if(this.state.isDownloadProof){
                this.downloadImage(payload.project_name,payload.proof);
              }
              this.props.history.push("/account/artwork");
            } 
          });
        }
     })
     // If there was an error thrown while getting links to proof images.
     .catch( (error) => {
         console.error("Getting proof images failed with exception: ", error);
     });
        
    // // If there was an error thrown while saving the product.
    // .catch(function (error) {
    //     console.error("Saving product failed with exception: ", error);
    // });
  };


  cancelApproval = () => {
      this.setState({
          inApprovalFlow: false,
          isSaveArtwork: false
      })
      if (this.props.clearBackground) {
        this.handleTemplateChange();
      }
  }
  downloadImage = (projectName,url) => {
    let fileName = `${projectName}.pdf`;
 
     if (url) {
       var xhr = new XMLHttpRequest(),
         a = document.createElement('a'), file;
 
       xhr.open('GET', url, true);
       xhr.responseType = 'blob';
       xhr.onload = function () {
         file = new Blob([xhr.response], { type: 'application/octet-stream' });
         a.href = window.URL.createObjectURL(file);
         a.download = fileName;
         a.click();
       };
       xhr.send();
     }
      
   }
  handleImageChange = async (e) => { // for custom die cuts, this switches between padding sizes. if a user has already selected that padding, it pulls the image from CC, otherwise processes through cloudinary
    if (!e.target.classList.contains('updateSelected')) {
      const { currentCustomImagePath, customShapeUrls } = this.state;
      let urlArr = customShapeUrls;
      this.toggleLoading(true);
      Array.from(document.getElementsByClassName("updateImageButton")).forEach(
        (button) => {
          if (button.classList.contains("updateSelected")) {
            button.classList.remove("updateSelected");
          }
        }
      )
      e.target.classList.add("updateSelected");
      let pad = e.target.value;
      let path = "";
      if (pad == "None") {
        path = urlArr[0];
        if (path != currentCustomImagePath) {
          this.updateCustomImagePath(path, urlArr);
          this.updateImage(path);
        }
      } else if (pad == "Small") {
        if (urlArr && !urlArr[1].includes("user:")) {
          const data = await cloudinaryMethods.cloudinaryDownload(urlArr[1]);
          const response = await this.cloudCanvasUpload(data);
          path = response.items[0].path;
          urlArr[1] = path;
          this.updateCustomImagePath(path, urlArr);
          this.updateImage(path);
        } else {
          path =urlArr && urlArr[1];
          if (path && path != currentCustomImagePath) {
            this.updateCustomImagePath(path, urlArr);
            this.updateImage(path);
          }
        }
      } else if (pad == "Large") {
        debugger;
        if ( urlArr && !urlArr[2].includes("user:")) {
          const data = await cloudinaryMethods.cloudinaryDownload(urlArr[2]);
          const response = await this.cloudCanvasUpload(data);
          path = response.items[0].path;
          urlArr[2] = path;
          this.updateCustomImagePath(path, urlArr);
          this.updateImage(path);
        } else {
          path = urlArr && urlArr[2];
          if (path && path != currentCustomImagePath) {
            this.updateCustomImagePath(path, urlArr);
            this.updateImage(path);
          }
        }
      }
    };
  };

  updateImage = async (path) => {
    let product = await editor.getProduct();
    await product.getProductModel();
    let data = {};
    data.Image = path;
    await editor.loadUserInfo(data);
    this.toggleLoading(true);
  };

  updateCustomImagePath = (path, urlArr) => {
    this.setState({
      currentCustomImagePath: path,
      customShapeUrls: urlArr,
    });
  };

  handleTemplateChange = async () => { // handles the logic behind the changing template when a user selects a different size
    let product = await editor.getProduct();
    let productDefinition = this.state.customSizeIsSelected ? {surfaces: [{printAreas: [{"designFile": this.state.customTemplate}]}]} : this.props.modalProps.canvasTemplate.ProductDefinition;
    let indexOfCurrentSurface = product.surfaces.lastIndexOf(product.currentSurface);
    let model = await product.getProductModel();
    // model.getAllItems pulls all 
    let currentItems = model.getAllItems();
    let sides = [];
    let currentSide = [];
    let newSideFound = false;
    // RSP only ever calls this from in the editor, so we need to run the logic to save the user changes to the template and apply them to the updated surfaces
    currentItems.forEach((item, index) => {
      // The first item on each surface is a blank PlaceholderItem
      if (!item.name && item.type === "PlaceholderItem") {
        newSideFound = true;
      // The unnamed Vector Smart Object is the checkerboard background generated by CC, leaving this in creates duplicate backgrounds
      } else if (item.name && item.name !== "Vector Smart Object") {
        currentSide.push(item);
        if (index === currentItems.length - 1) {
          sides.push(currentSide);
        }
      } else if (newSideFound && !item.name) {
        sides.push(currentSide);
        currentSide = [];
        newSideFound = false;
      } 
    })
    product.setSurfaces(productDefinition.surfaces)
    .then (function(newProduct) {
      for (let i = 0; i < productDefinition.surfaces.length; i++) {
        newProduct.surfaces[i].setPrintAreas(productDefinition.surfaces[i].printAreas, {updateSurfaceSize: true})
        newProduct.switchTo(newProduct.surfaces[indexOfCurrentSurface]);
        if (sides.length) {
          for (let j = 0; j < sides[i].length; j++) {
            newProduct.surfaces[i].insertItem(sides[i][j]);
          }
        }
      }
    })
    .catch (function(error) {
      console.log('Unable to update surface size');
      this.props.handleError();
    })
  };

  cloudCanvasUpload = async (data) => { // separate CC image upload for the die cut padding
    const { isStaging } = this.props;
    var overwrite = this.props.modalProps.customShape
      ? "?OverwriteExistingFiles=false"
      : "?OverwriteExistingFiles=true";
    let formData = new FormData();
    formData.append("attachmentName", data);
    const userid = base64regex.test(this.props.modalProps.customerId) ? this.props.modalProps.customerId.split('/')[4] :  btoa(this.props.modalProps.customerId);
    let url = `https://${isStaging ? "designerqa" : "designer"}.readysetprint.com/apps/design-editor/stable/api/Gallery/Private/${userid}/files${overwrite}`;
    let response = await fetch(
      url,
      {
        method: "POST",
        body: formData,
        headers: {
          "X-CCToken": this.props.modalProps.token,
        },
        processData: false,
        contentType: false,
      }
    );
    let respData = await response.json();
    return respData;
  };

  processDiecutSizeChange = async () => {
    const { lastCustomWidth, lastCustomHeight, currentCustomImagePath } = this.state;
    var newDefinition = { // create new template
      surfaces: [{
          width: lastCustomWidth * 72,
          height: lastCustomHeight * 72,
          printAreas: [{
              bounds: {
                  x: 0,
                  y: 0,
                  width: lastCustomWidth * 72,
                  height: lastCustomHeight * 72
              },
              safetyLines: [{
                  margin: 1,
                  color: 'rgba(196,196,196,0.6)',
                  stepPx: 2,
                  widthPx: 1
              }]
          }],
      }]
    };
    // the below logic removes the exisiting image from the editor and replaces it with the same image at the new scale
    let product = await editor.getProduct(); 
    product.removeItems(item => item.name === "Image");
    await product.surfaces[0].setPrintAreas(newDefinition.surfaces[0].printAreas, {updateSurfaceSize: true, preserveUserChanges: true});
    let productModel = await product.getProductModel();
    let Model = window.CustomersCanvas.DesignAtoms.ObjectModel;
    let surface = product.currentSurface;
    const imageItem = new Model.ImageItem(null, new Model.PointF(0,0), lastCustomWidth * 72, lastCustomHeight * 72);
    imageItem.alignment = Model.TextAlignment.Center;
    let RectangleF = Model.RectangleF;
    imageItem.sourceRectangle = new RectangleF(0, 0, lastCustomWidth * 72, lastCustomHeight * 72);
    imageItem.name = "Image";
    surface.insertItem(imageItem);
    let data = {};
    data.Image = currentCustomImagePath ? currentCustomImagePath : this.props.modalProps.uploadedImagePath;
    await editor.loadUserInfo(data);
  }

  debounce = (func, interval) => {
    let lastCall = -1;
    return function () {
      clearTimeout(lastCall);
      let args = arguments;
      lastCall = setTimeout(function () {
          func.apply(this, args);
      }, interval);
    };
  }

  handleDiecutError = (newVal, target, customMessage) => {
    if (customMessage) {
      this.setState({
        diecutError: customMessage
      })
    } else {
      let message = '';
      let elId = target.id || target.getAttribute('id');
      if (newVal < parseFloat(target.getAttribute('data-min'))) {
        message = `${elId === 'editor-width' ? 'Width' : 'Height'} cannot be less than ${target.getAttribute('data-min')}"`;
      } else if (newVal > parseFloat(target.getAttribute('data-max'))) {
        message = `${elId === 'editor-width' ? 'Width' : 'Height'} cannot be more than ${target.getAttribute('data-max')}"`;
      }
      this.setState({
        diecutError: message
      })
    }
  }

  setMainHeightAndWidth = (width, height) => {
    let variantProps = this.props.selectedVariantProperties;
    variantProps.customWidth = width;
    variantProps.customHeight = height;
    this.props.handleCustomSizeInputChange(variantProps, width);
  }

  handleDiecutSizeSelect = (event) => { // because of event pooling in react, the debounce is handled slightly differently than Stomp
    let lastCustomWidth = parseFloat((this.props.modalProps.uploadedImageWidth / 300).toFixed(2));
    let lastCustomHeight = parseFloat((this.props.modalProps.uploadedImageHeight / 300).toFixed(2));
    let newVal = parseFloat(event.target.value) ? parseFloat(event.target.value) : '';
    let decSplit = event.target.value.toString().split('.'); // determine if a user has entered more than two decimal places and reasign the value if that is the case
    if (decSplit[1] && decSplit[1].length > 2) {
      newVal = parseFloat(event.target.value.toString().split('').slice(0, 4).join(''));
    }
    let error = false;
    if (!newVal || (newVal < parseFloat(event.target.getAttribute('data-min')) || newVal > parseFloat(event.target.getAttribute('data-max')))) { // if the value is out of bounds, trigger error message
      error = true;
      this.handleDiecutError(newVal, event.target);
    }
    let squareTarget = parseFloat((event.target.id === 'editor-width' ? (lastCustomHeight / lastCustomWidth) * newVal : (lastCustomWidth / lastCustomHeight) * newVal).toFixed(2));
    let square = newVal * squareTarget;
    if (square > 93) {
      error = true;
      this.handleDiecutError(newVal, event.target, 'Maximum size is 93 sq. in.')
    }
    let _ = this;
    event.persist();
    if (!this.sizeDebounce) {
      this.sizeDebounce =  _.debounce(() => {
         this.processDiecutSizeChange();
      }, 200);
    }
    if (event.target.id === 'editor-width') {
      let newHeight = parseFloat(((lastCustomHeight / lastCustomWidth) * newVal).toFixed(2)); // adjust height to maintain aspect ratio
      this.setMainHeightAndWidth(newVal, newHeight);
      if (error) {
        this.setState({
          lastCustomWidth: newVal,
          lastCustomHeight: newVal ? newHeight : ''
        })
      } else {
        if (newHeight > parseFloat(document.getElementById('editor-height').getAttribute('data-max')) || newHeight < parseFloat(document.getElementById('editor-height').getAttribute('data-min'))) {
          if (!error) {            
            this.handleDiecutError(newHeight, document.getElementById('editor-height'));
          }
          this.setState({
            lastCustomWidth: newVal,
            lastCustomHeight: newVal ? newHeight : ''
          })
        } else {
          this.setState({
            diecutError: '',
            lastCustomWidth: newVal,
            lastCustomHeight: newHeight
          }, () => {
            this.state.iOS ? this.processDiecutSizeChange() : this.sizeDebounce();
          })
        }
      }
    } else {
      let newWidth = parseFloat(((lastCustomWidth/ lastCustomHeight) * newVal).toFixed(2)); // adjust height to maintain aspect ratio
      this.setMainHeightAndWidth(newWidth, newVal);
      if (error) {
        this.setState({
          lastCustomHeight: newVal,
          lastCustomWidth: newVal ? newWidth : ''
        })
      } else {  
        if (newWidth > parseFloat(document.getElementById('editor-width').getAttribute('data-max')) || newWidth < parseFloat(document.getElementById('editor-width').getAttribute('data-min'))) {
          if (!error) {
            this.handleDiecutError(newWidth, document.getElementById('editor-width'));
          }
          this.setState({
            lastCustomHeight: newVal,
            lastCustomWidth: newVal ? newWidth : ''
          })
        } else {         
          this.setState({
            diecutError: '',
            lastCustomHeight: newVal,
            lastCustomWidth: newWidth
          }, () => {
            this.state.iOS ? this.processDiecutSizeChange() : this.sizeDebounce();
          })
        }  
      }
    }
  }

  handleSizeSelect = (selection, node) => { // updates the pricing and dropdowns on the main page based off actions in the modal
    if (selection.value === 'custom') {
        this.setState({
            customSizeIsSelected: true
        }, () => {
            this.handleTemplateChange();
        })
      } else {
          if (this.state.customSizeIsSelected) {
              this.setState({
                customSizeIsSelected: false
              }) 
          }
      }
      this.props.handlePropertyDropdownChange(selection, node);
  }

  onProjectNameChange = (e) => {
    this.setState({projectName: e.target.value, nameRequiredError:false});
  }

  handleQuantitySelect = selection => { // same as above, but for quantity
      if (selection.value === 'custom') {
        this.props.handleQtyChange({value: 'custom', label: 'Custom Quantity'});
        this.props.setStateAndPricing({quantity: parseInt(selection.label)});
      } else {
          this.props.handleQtyChange(selection);
      }
  }

  toggleTooltip = (e) => {
    let tooltipId = '';
    if (e.target.classList.contains('legend-item-inner')) {
      tooltipId = e.target.id;
    } else {
      tooltipId = e.target.parentElement.id;
    }
    this.setState((state) => ({
        [tooltipId]: !state[tooltipId]
    }))
  }

  render() {
    const {
      inApprovalFlow,
      loading,
      updating,
      sizeOptions,
      qtyOptions,
      cutTooltip,
      bleedTooltip,
      cautionTooltip,
      cutTooltipText,
      bleedTooltipText,
      cautionTooltipText,
      landscape,
      lastCustomHeight,
      lastCustomWidth,
      diecutError,
      iOS,
      isSaveArtwork
    } = this.state;
    const {
      modalProps,
      canvasError,
      addToCartClicked,
      IndicatorSeparator,
      selectedSize,
      selectedQty,
      formattedFinalPrice,
      formattedInclusiveUnitPrice,
      xHigher,
      xHighLimit,
      xLowLimit,
      yHighLimit,
      yLowLimit,
      translate,
    } = this.props;
    let sizeString = ''
    if (modalProps.selectedCustomSize) {
        sizeString = `${modalProps.selectedCustomSize[0]}" x ${modalProps.selectedCustomSize[1]}"`
    }
    return (
      <div style={{width:"100%", height:"100%"}}>
        {inApprovalFlow && (
            <div className="canvas-approval-overlay">
            <div className="canvas-approval-inner">
              <div className="canvas-save-header">
              <p>{isSaveArtwork ? translate("Save Project") : translate("Review Design")}</p>
              </div>
              <div id="canvas-approval-proof" style={{ backgroundColor: modalProps.customShape ? "#E8E8E8" : "#FFFFFF"}}>
                <img id="canvas-approval-proof-image-one" src="" alt="Your Proof"/>
                <img id="canvas-approval-proof-image-two" src="" alt="Your Proof"/>
                <p id="canvas-approval-product">{modalProps.productTitle}</p>
                <p id="canvas-approval-size"></p>
              </div>
              { !isSaveArtwork &&
                <div id="canvas-approval-body">
                  <p id="canvas-approval-body-content">{translate("approve_artwork_printing")}</p>
                    <button id="canvas-approve-button" className="btn-primary" onClick={addToCartClicked}>{translate("ADD TO CART")}</button>
                    {/* <button id="canvas-approve-button" className="btn-primary" onClick={() => { this.handleApproval(true,true);}}>{translate("DOWNLOAD PROOF")}</button> */}
                    <button id="canvas-cancel-button" onClick={this.cancelApproval}>{translate("GO BACK TO DESIGN")}</button>
                </div>
              }
              { isSaveArtwork &&
                <div id="canvas-save-body">              
                  <p id="canvas-save-body-content">Not ready to checkout? Give your project a name to save your progress and continue designing later.</p>
                  <span className="canvas-save-input-label">{translate("Project Name")}<span>*</span></span>
                  <input id="canvas-save-input" className={`${this.state.nameRequiredError ? 'name-required' : ''}`} onChange={(e)=>this.onProjectNameChange(e)} placeholder="Your project name" type="text" maxLength="40"/>
                   {this.state.nameRequiredError && <span className="required-error">Please provide a name</span>}
                  <button id="canvas-save-button" className="btn-primary" onClick={this.saveArtwork}>{ modalProps.customerId ? translate("SAVE PROJECT")  : 'SIGN IN TO SAVE PROJECT'}</button>
                  <button id="canvas-save-cancel-button" onClick={this.cancelApproval} >{translate("CANCEL")}</button>
                </div>
              }
            </div>
          </div>
        )}
        {(loading || updating) && (
          <div
            id="product-canvas-loader"
            className={updating ? "updating" : ""}
          >
            <svg style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" r="37.8387" fill="none" strokeWidth="5">
                <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s"></animate>
                <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s"></animate>
              </circle>
              <circle cx="50" cy="50" r="20.6086" fill="none" strokeWidth="5">
                <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline"></animate>
                <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline"></animate>
              </circle>
            </svg>
          </div>
        )}
        <div className={iOS ? "modal-product-canvas ios" : "modal-product-canvas"}>
          <div id="editorPage" className="area">
            <div id="iframeWrapper">
              {canvasError && (
                <div className="iframe-error-wrapper">
                  <div className="iframe-error-wrapper-inner">
                    <span className="iframe-error-text">
                      {translate("canvas_error")}
                    </span>
                    <input
                      id="errorButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={addToCartClicked}
                      value={translate("Add to Cart")}
                    />
                  </div>
                </div>
              )}
              
              <iframe id="editorFrame" width="100%" height="100%"></iframe>
            </div>
            <div
              id="saveAndNextButtonsWrapper"
              className={
                modalProps.customShape ? "customButtonsWrapper" : ""
              }
            >
              {!modalProps.customShape && (
                <div className="save-and-next-legend">
                  <div id="legend-caution" className="legend-item">
                    <Tooltip wrapperStyle={{background: "#FFFFFF"}} className="legend-tooltip" placement="top" isOpen={cautionTooltip} target={'cautionTooltip'} toggle={this.toggleTooltip}>
                      {cautionTooltipText}
                    </Tooltip>
                    <div id={'cautionTooltip'} className="legend-item-inner">
                      <div className="legend-item-inner-line" id="legend-caution-line">--  --  --</div>
                      <span className="legend-item-inner-title">{translate("Caution Zone")}</span>
                    </div>
                  </div>
                  <div id="legend-cut" className="legend-item">
                    <Tooltip className="legend-tooltip" placement="top" isOpen={cutTooltip} target={'cutTooltip'} toggle={this.toggleTooltip}>
                      {cutTooltipText}
                    </Tooltip>
                    <div id={'cutTooltip'} className="legend-item-inner">
                      <div className="legend-item-inner-line" id="legend-cut-line">----------</div>
                      <span className="legend-item-inner-title">{translate("Cut")}</span>
                    </div>
                  </div>
                  <div id="legend-bleed" className="legend-item">
                    <Tooltip className="legend-tooltip" placement="top" isOpen={bleedTooltip} target={'bleedTooltip'} toggle={this.toggleTooltip}>
                      {bleedTooltipText}
                    </Tooltip>
                    <div id={'bleedTooltip'}className="legend-item-inner">
                      <div className="legend-item-inner-line" id="legend-bleed-line">--  --  --</div>
                      <span className="legend-item-inner-title">{translate("Bleed")}</span>
                    </div>
                  </div>
                </div>
              )}
              {modalProps.customShape && (
                <div className="cut-selection-wrapper-outer">
                  <label htmlFor="cut-selection-wrapper">Border</label>
                  <div className="cut-selection-wrapper">
                    <input
                      className="updateImageButton btn btn-primary updateSelected"
                      type="button"
                      value="None"
                      onClick={(e) => {
                        this.handleImageChange(e);
                      }}
                    />
                    <input
                      className="updateImageButton btn btn-primary"
                      type="button"
                      value="Small"
                      onClick={(e) => {
                        this.handleImageChange(e);
                      }}
                    />
                    <input
                      className="updateImageButton btn btn-primary"
                      type="button"
                      value="Large"
                      onClick={(e) => {
                        this.handleImageChange(e);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="saveAndNextUpper">
                <div className="saveAndNextSelects">
                  <div className="col-md-6">
                    {modalProps.customShape ? (
                      <div id="editor-custom-sizes" className="form-group custom-sizes">
                        <label className="editor-custom-size-label">
                          Width
                          <input 
                            type="number" 
                            name="customWidth"
                            step="0.01" 
                            id="editor-width" 
                            autoComplete="off" 
                            value={lastCustomWidth} 
                            onChange={this.handleDiecutSizeSelect}
                            data-min={xHigher ? xLowLimit : yLowLimit}
                            data-max={xHigher ? xHighLimit : yHighLimit}
                          />
                          <span>in</span>
                        </label>
                        <span className="custom-sizes-x"> x </span>
                        <label className="editor-custom-size-label">
                          Height
                          <input 
                            type="number"
                            name="customHeight" 
                            step="0.01" 
                            id="editor-height" 
                            autoComplete="off" 
                            value={lastCustomHeight} 
                            onChange={this.handleDiecutSizeSelect}
                            data-min={xHigher ? yLowLimit : xLowLimit}
                            data-max={xHigher ? yHighLimit : xHighLimit}
                          />
                          <span>in</span>
                        </label>      
                      </div>
                    ) : sizeOptions ? (
                      <div className="form-group">
                        <label> {translate("Size")} </label>
                        <Select
                          name="Size"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "40px",
                              minHeight: "34px",
                            }),
                            indicatorSeparator: () => {}, // removes the "stick"
                            dropdownIndicator: (defaultStyles) => ({
                              ...defaultStyles,
                              color: "black", // your changes to the arrow
                            }),
                            menuList: (base, state) => ({
                              ...base,
                              backgroundColor: "white",
                            }),
                          }}
                          value={modalProps.selectedCustomSize ? {label: sizeString, value: 'custom'} : sizeOptions.filter(
                            ({ value }) =>
                              value ==
                              (modalProps.selectedCustomSize
                                ? "custom"
                                : selectedSize)
                          )}
                          onChange={this.handleSizeSelect}
                          options={sizeOptions}
                          components={IndicatorSeparator}
                          isSearchable={false}
                        />
                      </div>
                    ) : ("")}
                    {diecutError && (
                      <span id="editor-size-error">{diecutError}</span> 
                    )}  
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> {translate("Quantity")} </label>
                      <Select
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            height: "40px",
                            minHeight: "30px",
                          }),
                          indicatorSeparator: () => {}, // removes the "stick"
                          dropdownIndicator: (defaultStyles) => ({
                            ...defaultStyles,
                            color: "black", // your changes to the arrow
                          }),
                          menuList: (base, state) => ({
                            ...base,
                            backgroundColor: "white",
                          }),
                        }}
                        value={qtyOptions.filter(
                          ({ value }) => value == selectedQty
                        )}
                        onChange={this.handleQuantitySelect}
                        options={qtyOptions}
                        components={IndicatorSeparator}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="saveAndNextText">
                  <p className="info-tip">{translate("Order more, save more!")}</p>
                  <div className="info-price">
                    <span className="info-price-main">
                      {formattedFinalPrice}
                    </span>
                    <span className="info-price-sub">
                      {translate("$## /unit", {value: formattedInclusiveUnitPrice})}
                    </span>
                  </div>
                </div>
              </div>
              <div className="next-button-wrapper">
                <input
                  id="nextButton"
                  onClick={() => this.handleApproval()}
                  disabled={diecutError}
                  type="button"
                  className="btn btn-lg btn-primary"
                  value={translate("Review Design")}
                />         
              </div>
              <div className="save-button-wrapper">
                <input id="saveButton" onClick={() => this.handleApproval(true,false)} type="button" value={translate("Save Artwork")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(ProductCanvasModal);