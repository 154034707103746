import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

// Custom Components
import HeaderOne from './common/headers/header-one';
import FooterOne from "./common/footers/footer-one";
import { withRouter } from 'react-router';
import {Helmet} from "react-helmet";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isGFS: (window.location.hostname === 'fr.gfsimpress.com' 
            || window.location.hostname === 'ca.gfsimpress.com' 
            || window.location.hostname === 'gfsimpress.com'),
            
            isGFSFrenchSite: window.location.hostname === 'fr.gfsimpress.com',

            GFSFrenchLink: "https://www.fr.gfsimpress.com/",
            GFSUsLink: "https://www.gfsimpress.com/",
            GFSCaLink: "https://www.ca.gfsimpress.com/"
        }   
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        if (this.state.isGFS){
            if (document.location.pathname.includes("/products/")) {
                switch(window.location.hostname) {
                    case "fr.gfsimpress.com":
                    const newFrenchLink = window.location.href;
                    if (newFrenchLink !== this.state.GFSFrenchLink) {
                        this.setState({
                            GFSFrenchLink: newFrenchLink,
                        })
                    } 
    
                    let frToCaHref = window.location.href.replace("/products/fr-gfsimpress-", "/products/ca-gfsimpress-");
                    frToCaHref = frToCaHref.replace("fr.gfsimpress.com", "ca.gfsimpress.com");
                    const newCanadaLink =frToCaHref;
                    if (newCanadaLink !== this.state.GFSCaLink) {
                        this.setState({
                            GFSCaLink: newCanadaLink,
                        })
                    } 
                    
                    let frToEnHref = window.location.href.replace("/products/fr-gfsimpress-", "/products/gfs-");
                    frToEnHref = frToEnHref.replace("fr.gfsimpress.com", "gfsimpress.com");
                    const newEnglishLink = frToEnHref;
                    if (newEnglishLink !== this.state.GFSUsLink) {
                        this.setState({
                            GFSUsLink: newEnglishLink,
                        })
                    }

                break;
                case "ca.gfsimpress.com": 
                    const newCanadaLink2 = window.location.href;
                    if (newCanadaLink2 !== this.state.GFSCaLink) {
                        this.setState({
                            GFSCaLink: newCanadaLink2,
                        })
                    }
    
                    let caToFrHref = window.location.href.replace("/products/ca-gfsimpress-", "/products/fr-gfsimpress-");
                    caToFrHref = caToFrHref.replace("ca.gfsimpress.com", "fr.gfsimpress.com");
                    const newFrenchLink2 =  caToFrHref;
                    if (newFrenchLink2 !== this.state.GFSFrenchLink) {
                        this.setState({
                            GFSFrenchLink: newFrenchLink2,
                        })
                    }
    
    
                    let caToEnHref = window.location.href.replace("/products/ca-gfsimpress-", "/products/gfs-");
                    caToEnHref = caToEnHref.replace("ca.gfsimpress.com", "gfsimpress.com");
                    const newEnglishLink2 = caToEnHref;
                    if (newEnglishLink2 !== this.state.GFSUsLink) {
                        this.setState({
                            GFSUsLink: newEnglishLink2,
                        })
                    }

                    break;
                    case "gfsimpress.com": 
                    const newEnglishLink3 = window.location.href;
                    if (newEnglishLink3 !== this.state.GFSUsLink) {
                        this.setState({
                            GFSUsLink: newEnglishLink3,
                        })
                    }
    
                    let enToCaHref = window.location.href.replace("/products/gfs-", "/products/ca-gfsimpress-");
                    enToCaHref = enToCaHref.replace("gfsimpress.com", "ca-gfsimpress.com");
                    const newCanadaLink3 =  enToCaHref;
                    if (newCanadaLink3 !== this.state.GFSCaLink) {
                        this.setState({
                            GFSCaLink: newCanadaLink3,
                        })
                    }
    
                    let enToFrHref = window.location.href.replace("/products/gfs-", "/products/fr-gfsimpress-");
                    enToFrHref = enToFrHref.replace("gfsimpress.com", "fr-gfsimpress.com");
                    const newFrenchLink3 = enToFrHref;
                    if (newFrenchLink3 !== this.state.GFSFrenchLink) {
                        this.setState({
                            GFSFrenchLink: newFrenchLink3,
                        })
                    }
                break;
                }
            } else {
                if (this.state.GFSUsLink !== "https://www.gfsimpress.com/") {
                    this.setState({
                        GFSUsLink: "https://www.gfsimpress.com/"
                    })
                }
                if (this.state.GFSFrenchLink !== "https://www.fr.gfsimpress.com/") {
                    this.setState({
                        GFSFrenchLink: "https://www.fr.gfsimpress.com/",
                    })
                }
                if (this.state.GFSCaLink !== "https://www.ca.gfsimpress.com/") {
                    this.setState({
                        GFSCaLink: "https://www.ca.gfsimpress.com/",
                    })
                }
            }
        }
    }

    render() {
        const {isPunchout } = this.props;
        if(isPunchout) {
            // User is accessing RSP from Punchout2Go platform, so don't render the header and footer
            document.querySelector(".loader-wrapper").style = "display: none"; // Also, disable the loader (usually done in HeaderOne) 
            return (
                <div>
                    {this.props.children}
                </div>
            );
        } else {
            // Standard user is accessing RSP, so render the header and footer
            return (
                <div>
                    <Helmet htmlAttributes={{ lang : this.state.isGFSFrenchSite ? "fr" : "en" }}>
                        { this.state.isGFS && <link hreflang="en-us" rel="alternate" href={this.state.GFSUsLink} /> }
                        { this.state.isGFS && <link hreflang="en-ca" rel="alternate" href={this.state.GFSCaLink} /> }
                        { this.state.isGFS && <link hreflang="fr" rel="alternate" href={this.state.GFSFrenchLink} /> }
                    </Helmet>
                    <HeaderOne logoName={'default-logo.png'}/>
                    {this.props.children}
                    <FooterOne logoName={'default-logo.png'}/>
                </div>
            );
        }
    }
}

export default withRouter(withTranslate(App));
