import React, { useState } from 'react';

const CustomCheckBox  = ({ onChange, label }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const isChecked = checked;
    setChecked(!isChecked);
    onChange(event, label);
  };

  return(
    <div
      style={{
        height: '15px',
        position: 'sticky',
        paddingLeft: '3px',
        marginTop: '15px',
        paddingBottom: '5px'
      }}
      className="d-flex align-items-center">
      <label className="custom-tick-checkbox">
        <input type="checkbox" checked={checked} onChange={e => handleChange(e)}/>
        <span className="checkmark" />
        <span className="hidebox" />
      </label>
      <label style={{
        paddingTop: '11.5px',
        fontSize: '14px',
        fontFamily: 'Roboto',
        letterSpacing: '0px',
        color: checked? "black" : "#777777"
      }}>
        {label}
      </label>
    </div>
  )
};

export default CustomCheckBox;
