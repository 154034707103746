import React, { useState, useEffect } from 'react';
import { useSelector} from 'react-redux'
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';
import classnames from 'classnames';
import store from '../../../store'
import { IconClose } from '../../common/svg-icons';
import { filterBrand } from '../../../actions';
import CustomCheckBox from "../../common/custom-checkbox";

const Filter = () => {

  const {products} = useSelector( state => ({
    filters: state.filters,
    products: state.data.products,
  }));

  // Total brands, colors, prices from all products
  const [brands] = useState(
    ["Indoor", "Short-Term Outdoor", "Outdoor"]);

  const [activeBrands, setActiveBrands] = useState([]);

  useEffect(() => {
    // set Options for the filters

    // setBrands(getBrands(products));
    // setColors(getColors(products));
    // setPrices(getMinMaxPrice(products));

  }, [products]);

  const closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -450px";
  };

  const clickBrandHandle = (event, label) => {
    // label is the name of item checked/unchecked

    const checkedBrands = activeBrands;
    const index = checkedBrands.indexOf(label);
    if (event.target.checked)
      checkedBrands.push(label); // push in array checked value
    else
      checkedBrands.splice(index, 1); // removed in array unchecked value

    console.log(">>>>>>brands", checkedBrands);

    setActiveBrands(checkedBrands);
    store.dispatch(filterBrand(checkedBrands));
  };


  return (
    <div className="collection-filter-block">
      {/*brand filter start*/}
      <div className="collection-mobile-back">
        <div className="d-flex justify-content-between">
          <span>FILTER BY</span>
          <IconClose onClick={(e) => closeFilter(e)} />
        </div>
      </div>
      <SlideToggle>
        {({onToggle, setCollapsibleElement, toggleState}) => (
          <div className="collection-collapse-block">
            <h3 className={classnames({
              "collapse-block-title": true,
              "collapse-block-title-expanded": toggleState.search("EXPAND") >= 0,
              "collapse-block-title-collapsed": toggleState.search("COLLAP") >= 0
            })}  onClick={onToggle}>Brands</h3>
            <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
              <div className="intended-use-selector">
                {
                  brands.map((brandLabel, index) =>
                    <CustomCheckBox label={brandLabel} key={index} onChange={clickBrandHandle}/>
                  )
                }
                {/*<CustomCheckBox label="Indoor" key={1} onChange={clickBrandHandle}/>*/}
                {/*<CustomCheckBox label="Short-Term Outdoor" key={2} onChange={clickBrandHandle}/>*/}
                {/*<CustomCheckBox label="Outdoor" key={3} onChange={clickBrandHandle}/>*/}
              </div>
            </div>
          </div>
        )}
      </SlideToggle>

      {/*color filter start here*/}
      <SlideToggle>
        {({onToggle, setCollapsibleElement, toggleState}) => (
          <div className="collection-collapse-block">
            <h3 className={classnames({
              "collapse-block-title": true,
              "collapse-block-title-expanded": toggleState.search("EXPAND") >= 0,
              "collapse-block-title-collapsed": toggleState.search("COLLAP") >= 0
            })}  onClick={onToggle}>Material Group</h3>
            <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
              <div className="color-selector">

              </div>
            </div>
          </div>
        )}
      </SlideToggle>
      {/*price filter start here */}

      <SlideToggle>
        {({onToggle, setCollapsibleElement, toggleState}) => (
          <div className="collection-collapse-block open">
            <h3 className={classnames({
              "collapse-block-title": true,
              "collapse-block-title-expanded": toggleState.search("EXPAND") >= 0,
              "collapse-block-title-collapsed": toggleState.search("COLLAP") >= 0
            })}  onClick={onToggle}>Adhesive</h3>
            <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>

            </div>
          </div>
        )}
      </SlideToggle>
      <SlideToggle>
        {({onToggle, setCollapsibleElement, toggleState}) => (
          <div className="collection-collapse-block open">
            <h3 className={classnames({
              "collapse-block-title": true,
              "collapse-block-title-expanded": toggleState.search("EXPAND") >= 0,
              "collapse-block-title-collapsed": toggleState.search("COLLAP") >= 0
            })} onClick={onToggle}>Material Color </h3>
            <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>

            </div>
          </div>
        )}
      </SlideToggle>
      <button className="btn btn-outline-primary d-block clear-filter-btn">
        <span> Clear filters </span>
      </button>
    </div>
  )
};

export default Filter;

