import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CategoryItem from './category-item';
import PropTypes from 'prop-types';
import store from '../../../../store'
import Util from '../../../../util';
import { getAllShopDetails } from '../../../../actions';
const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
// const categories = [
//   'CLINGS',
//   'DECALS',
//   'FOLDING CARTONS',
//   'BANERS',
//   'SIGNS',
//   'PAPER PRODUCTS'];

const PopularCategories = () => {

  const [categoryGrid, setCategoryGrid] = useState({})
  const [displayedCategories, setDisplayedCategories] = useState([])
  const { categories } = useSelector(state => {
    return ({
      categories: state.category.categories,
    });
  })

  useEffect(() => store.subscribe(() => {
    displayCategoryGrid();

  })
    , []);

  useEffect(() => {
    if (categories.length <= 0) {
      const reseller_id = Util.getReseller();
      store.dispatch(getAllShopDetails(reseller_id));
    } else {
      displayCategoryGrid();
    }
  }, []);

  const displayCategoryGrid = async () => {
    const categories = store.getState().category.categories
    let grid = window.categoryGrid;
    if (process.env.REACT_APP_RESELLER_ID) {
      if (displayedCategories.length === 0) {
        grid = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Category Grid", "categoryGrid");
      }
    }

    if (grid) {
      const collections = grid.collections

      if (collections.findIndex(c => c.categoryId !== "") > -1) {

        setCategoryGrid(grid)

        let displayedCategories = []
        for (let cat of collections) {
          const existingCategory = categories.find(c => {
            if(cat && cat.categoryId){
              return  base64regex.test(cat.categoryId) ? c.id === atob(cat.categoryId) : c.id === cat.categoryId;
            }else {
             return  false;
            }
          });
          if (existingCategory) {
            displayedCategories.push(existingCategory)
          }
        }

        setDisplayedCategories(displayedCategories)

      }

    }
  }

  return (
    <>
      {displayedCategories.length > 0 &&
        <div className="popular-categories section-t-space">
          <h2 className="text-center">{categoryGrid.headline}</h2>
          <div className="container mb-5">
            <div className="row" style={{ justifyContent: "center" }}>
              {displayedCategories.map(({ title, handle }, index) => (
                <CategoryItem
                  key={index}
                  title={title}
                  id={index}
                  handle={handle}
                />
              ))
              }
            </div>
          </div>
        </div>
      }
    </>
  );
}

PopularCategories.propTypes = {
  title: PropTypes.string,
}
export default PopularCategories;
