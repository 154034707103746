import React, { Component } from 'react';
import { Helmet } from 'react-helmet'

import MainBackground from './components/main-background';
import PopularProducts from './components/popular-products';
import PromoBar from './components/promo-bar';
import PopularCategories from './components/popular-categories';

import Util from "../../../util";

class Fashion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Home",
            metaDescription: ""
        }
    }

    async componentDidMount() {
        let footer = window.Footer;
        if (process.env.REACT_APP_RESELLER_ID) {
            footer = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Footer", "Footer");
        }

        if (footer) {
            if (footer.legalCopy) {
                this.setState({
                    pageTitle: footer.legalCopy
                });
            }
            if (footer.companyDescription) {
                this.setState({
                    metaDescription: footer.companyDescription
                });
            }
        }

        setTimeout(() => window.analytics.page(), 1000);
    }

    render() {

        const { pageTitle, metaDescription } = this.state;

        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={metaDescription} />
                </Helmet>
                {/*Home Slider*/}
                <MainBackground />
                <div className="fashion-home">
                    <PromoBar />
                    <PopularCategories />
                    <PopularProducts />
                </div>
            </div>
        )


    }
}

export default Fashion;