import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Select, { components } from 'react-select';
import axios from 'axios';

import { cloudinaryMethods } from '../../../../services/cloudinary/cloudinary';
import { canvas } from '../../../../services/canvas';

import ProductCanvasModal from '../../../modals/product-canvas';
import { Tooltip } from 'reactstrap';

import { client } from '../../../../index';
import * as storefrontQuerys from '../../../../graphql/querys';
import { IconInformation } from '../../../common/svg-icons';
import isEqual from "lodash/isEqual"
import jQuery from 'jquery';
import Util from "../../../../util";
import { toast } from 'react-toastify';
import { punchout_addToCart } from '../../../../utils/punchout';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

window.$ = window.jQuery = jQuery;
require('blueimp-file-upload');
require('cloudinary-jquery-file-upload');

const IndicatorSeparator = (props) => {
    return (
        <components.IndicatorSeparator {...props}>
            <span> XX</span>
        </components.IndicatorSeparator>
    );
};

// TAGS
const overlamination_tag = "OE=Overlamination";
const foil_stamping_tag = "OE=Foil Stamping";
const matte_tag = "OE=Matte Varnish";
const tape_tag = "OE=Double Sided Tape";
const imprint_tag = "OE=Back Imprint";
const numbering_tag = "OE=Numbering";
const lot_pricing_tag = "Lot Pricing";
const custom_size_tag = "Custom Size";
const custom_die_cut_tag = "Custom Die-Cut";
const custom_shape_tag = "Shape=Custom";
const customizable_tag = "Customizable";
// this is added to one product currently, may change if the approved tag is different
const clear_background_tag = "Clear Background";
const halo_tag = "OE=White Halo";

const productTypeTitles = {
    "Overlamination": "overlaminationTooltipText",
    "Foil Stamping": "foilStampingTooltipText",
    "Matte Varnish": "matteVarnishTooltipText",
    "Double Sided Tape": "doubleSidedTapeTooltipText",
    "Back Imprint": "backImprintTooltipText",
    "Numbering": "numberingTooltipText"
};

const customSizeOption = { label: 'Custom Size', value: 'custom' };

class DetailsWithPrice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            quantity: 1,
            stock: 'InStock',
            nav3: null,
            image_path: null,
            customShape: false,
            customShapeUrls: [],
            customShapeSize: false,
            selectedCustomSize: null,
            hasOverlamination: false,
            hasFoilStamping: false,
            hasMatte: false,
            hasTape: false,
            hasImprint: false,
            hasNumbering: false,
            lot_pricing: false,
            priceJson: false,
            priceObject: false,
            templateJson: false,
            selectedVariantTemplate: false,
            noTemplateFound: false,
            cartItems: {},
            formattedFinalPrice: '',
            formattedInclusiveUnitPrice: '',
            unitPrice: '',
            overlaminationPrice: '',
            foilPrice: '',
            mattePrice: '',
            tapePrice: '',
            imprintPrice: '',
            numberingPrice: '',
            inclusivenUnitPrice: '',
            qtyerror: '',
            sizeerror: '',
            disableAddToCart: false,
            qtyOptions: [],
            showCustomQtyField: false,
            selectedVariant: false,
            selectedVariantBarcode: '',
            selectedQty: '',
            selectedVariantProperties: { overlamination: false, foil_stamping: false, matte: false, tape: false, imprint: false, numbering: false, numbering: false },
            overlaminationQty: 0,
            foilStampingQty: 0,
            matteQty: 0,
            tapeQty: 0,
            imprintQty: 0,
            numberingQty: 0,
            markup: 0,
            productData: {},
            discountApplied: false,
            customSize: false,
            availableForSale: true,
            canvasError: false,
            anonymousUser: false,
            customizable: false,
            sizeOptions: [],
            overlaminationTooltip: false,
            foilStampingTooltip: false,
            matteVarnishTooltip: false,
            doubleSidedTapeTooltip: false,
            backImprintTooltip: false,
            numberingTooltip: false,
            quantityTooltip: false,
            materialTooltip: false,
            whiteHaloTooltip: false,
            overlaminationTooltipText: "",
            foilStampingTooltipText: "",
            matteVarnishTooltipText: "",
            doubleSidedTapeTooltipText: "",
            backImprintTooltipText: "",
            numberingTooltipText: "",
            quantityTooltipText: "",
            finalPrice: '',
            cadConversion: false,
            white_halo : false,
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.handlePropertyDropdownChange = this.handlePropertyDropdownChange.bind(this);
        this.handleQtyChange = this.handleQtyChange.bind(this);
        this.changeQty = this.changeQty.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateVariantProperties = this.updateVariantProperties.bind(this);
        this.handleError = this.handleError.bind(this);
        this.getTemplate = this.getTemplate.bind(this);
        this.setStateAndPricing = this.setStateAndPricing.bind(this);
        this.handleCustomSizeInputChange = this.handleCustomSizeInputChange.bind(this);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        let currentProperties = this.state.selectedVariantProperties; // if the canvas is closed with image data, on the variant, clear the data
        if (currentProperties.print_ready_image) {
            delete currentProperties.print_ready_image;
            delete currentProperties.customer_canvas_state;
            delete currentProperties.thumbnail;
            if (currentProperties.low_dpi) {
                delete currentProperties.low_dpi;
            }
        }
        this.updateVariantProperties(currentProperties);
        if (this.state.qtyOptions[this.state.qtyOptions.length - 1].value === 'custom') {
            let qtyOptions = [...this.state.qtyOptions];
            if (this.state.qtyOptions[this.state.qtyOptions.length - 1].label !== 'Custom Quantity') {
              qtyOptions[qtyOptions.length - 1].label = 'Custom Quantity';
            }
            this.setState({
                open: false,
                qtyOptions: qtyOptions,
                image_path: null,
                customShapeUrls: [],
                image_height: null,
                image_width: null,
                canvasError: false
            })
        } else {
            if (this.state.disableAddToCart) {
                currentProperties.customWidth = this.state.xLowLimit;
                currentProperties.customHeight = this.state.yLowLimit;
                this.handleCustomSizeInputChange(currentProperties, this.state.xLowLimit); // more efficiant method to clear the errors and unlock the design now button. if the modal was closed in an error state, set the custom sizes under the hood to the low limits for each
                this.setState({ 
                    open: false,
                    image_path: null,
                    customShapeUrls: [],
                    image_height: null,
                    image_width: null,
                    canvasError: false 
                });
            } else {
                this.setState({ 
                    open: false,
                    image_path: null,
                    customShapeUrls: [],
                    image_height: null,
                    image_width: null,
                    canvasError: false 
                });
            }
        }
    };

    handleError = () => {
        this.setState({ canvasError: true })
    }

    componentWillMount() {
        this.setCartItems();
    }

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
        this.setProductProperties();
        this.setVariant();
        this.setUser();
        setTimeout(() => window.analytics.page(), 1000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            setTimeout(() => window.analytics.page(), 1000);
        }
        if (!isEqual(prevProps.productDropdownOptions,this.props.productDropdownOptions )) {
            this.preselectDropdowns();
        }
        if (!isEqual(prevProps.productData,this.props.productData) || prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ productData: this.props.productData }, () => { 
                this.setProductProperties();
                this.setVariant();
                this.setUser();
                var canvasData = JSON.parse(localStorage.getItem('customers_canvas'));
                setTimeout(()=>{
                    if(canvasData){
                        let { selectedVariantProperties } = this.state;
                        var sizeSplit;
                        sizeSplit = canvasData.size.replace(/"/g, '').split(' x ');                     
                        selectedVariantProperties.customWidth = Number(sizeSplit[0]);
                        selectedVariantProperties.customHeight = Number(sizeSplit[1]);
                        selectedVariantProperties.Size = canvasData.custom_size ? "custom"  : canvasData.size;                     
                        selectedVariantProperties.white_halo = canvasData.white_halo;                      
                        this.setState({ 
                            selectedQty: canvasData.custom_qnty ? "custom" : canvasData.quantity,
                            showCustomQtyField: canvasData.custom_qnty,
                            selectedVariantProperties,     
                            quantity: canvasData.quantity,
                            white_halo: canvasData.white_halo,                                                                                                      
                        },()=>{
                            this.setQtyOptions();
                            
                        });
                        
                        this.setStateAndPricing({qty: canvasData.size, quantity: canvasData.quantity });        
                    }  
                },1000);
                setTimeout(()=> {
                    if(canvasData){
                        this.onOpenModal();
                    }
                },1500);

             });   
        }
    }

    //Checks if the shopify currency conversion script is loaded, then sets a boolean
    conversionCheck() {
        if (typeof window.Currency != "undefined" && process.env.REACT_APP_CURRENCY) {
            this.setState({cadConversion: true});
        } else {
            this.setState({cadConversion: false});
        }
    }

    setUser = () => {
        this.setState({
            customerId: this.props.customerId || this.createUUID()
        })
    }

    createUUID() {
        this.setState({
            anonymousUser: true
        })
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    changeQty = (e) => {
        let qty = e.target.value;
        if (isNaN(qty) || qty == "") {
            qty = 0;
        } else if (qty > 9999999){
            qty = 9999999;
        } else {
            qty = parseInt(qty);
        }
        this.setStateAndPricing({ quantity: qty });
    }

    getTemplate = () => {
        let { templateJson, selectedVariant } = this.state;
        let variantSku = selectedVariant.sku;
        const split = variantSku ? variantSku.split('-row') : null;
        if (split && split.length > 1) {
            variantSku = split[0];
        }
        if (!this.state.selectedCustomSize) {
            if (!templateJson) {
                let productType = this.state.productData.productType;
                const currentEnvironment = process.env.REACT_APP_URL_TEMPLATES_JSON ? process.env.REACT_APP_URL_TEMPLATES_JSON : "https://designer.readysetprint.com/apps/shopify-json/templates";
                let templateJsonUrl = productType.split(' - ').length > 1
                    ? '/cc_' + productType.split(' - ')[1].toLowerCase().replace(/&/g, 'and').replace(/ /g, '_') + '.json'
                    : '/cc_' + productType.toLowerCase().replace(/&/g, 'and').replace(/ /g, '_') + '.json';
                
                templateJsonUrl = `${currentEnvironment}${templateJsonUrl}`;
                axios.get(templateJsonUrl)
                    .then(res => {
                        const selectedTemplate = res.data;
                        if (selectedTemplate) {
                            let variantJson = selectedTemplate.Templates.find(template => template.ID === variantSku);
                            this.setState({
                                templateJson: selectedTemplate,
                                selectedVariantTemplate: variantJson
                            })
                        } else {
                            this.setState({
                                noTemplateFound: true
                            })
                        }
                    });
            } else {
                let variantJson = templateJson.Templates.find(template => template.ID === variantSku);
                this.setState({
                    selectedVariantTemplate: variantJson
                })
            }
        }
    }

    setSizeLimits = () => {
      let xHighLimit = 0;
      let xLowLimit = 0;
      let yHighLimit = 0;
      let yLowLimit = 0;
      this.state.productData.variants.edges.map((variant, i) => {
        const size = variant.node.selectedOptions.filter(option => {
          return option.name == "Size";
        })
        const sizeSplit = size[0].value.split(' x ').map(sizeStr => {
          return sizeStr.substring(0, sizeStr.length - 1);
        });
        const width = parseFloat(sizeSplit[0]);
        const height = parseFloat(sizeSplit[1]);
        if (i === 0) {
          xHighLimit = width;
          xLowLimit = width;
          yHighLimit = height;
          yLowLimit = height;
        } else {
          if (width > xHighLimit) {
            xHighLimit = width;
          } else if (width < xLowLimit) {
            xLowLimit = width;
          } 
          if (height > yHighLimit) {
            yHighLimit = height;
          } else if (height < xLowLimit) {
            yLowLimit = height;
          }
        }
      });
      this.setState({
        xHighLimit: xHighLimit,
        xLowLimit: xLowLimit,
        yHighLimit: yHighLimit,
        yLowLimit: yLowLimit
      })
    }

    handleCloudURL = (url) => {
        const transformationBleeds = {
            'small': 0,
            'medium': 18,
            'large': 36
        }
        let urlArr = [];
        urlArr.push(url);
        urlArr.push(url.replace(`outline:fill:${transformationBleeds.small}`, `outline:fill:${transformationBleeds.medium}`));
        urlArr.push(url.replace(`outline:fill:${transformationBleeds.small}`, `outline:fill:${transformationBleeds.large}`));
        let imageResp = cloudinaryMethods.cloudinaryDownload(url)
        imageResp
            .then(data => {
                let formData = new FormData();
                formData.append('attachmentName', data);
                let uploadResp = canvas.initCanvasUpload(formData, this.state.customerId);
                uploadResp
                    .then(uploadResp => {
                        if (uploadResp[0]) {
                            urlArr[0] = uploadResp[0].items[0].path;
                            this.setState({
                                customShapeUrls: urlArr,
                                image_path: uploadResp[0].items[0].path,
                                image_height: uploadResp[0].items[0].height,
                                image_width: uploadResp[0].items[0].width,
                                token: uploadResp[1]
                            })
                        } else {
                            this.handleError();
                        }
                    })
            })
    }

    handleCustomShape() {
        let input = document.getElementById('cloudinaryFileUpload');
        var initialized = cloudinaryMethods.initOnProductView(cloudinaryMethods.cloudinaryConfig(), this.handleCloudURL, this.handleError, this.onOpenModal, this.state.xHighLimit);
        if (initialized){
            input.click();
        }
    }

    handleFileUpload = (e) => {
        e.preventDefault();
        if (this.state.customShape) {
            this.handleCustomShape();
        } else {
            this.onOpenModal();
        }
    }

    updateVariantProperties(properties) {
        let currentProps = this.state.selectedVariantProperties;
        let newProps = Object.assign(currentProps, properties)
        this.setState({
            selectedVariantProperties: newProps
        })
    }

    setStateAndPricing(state, fn) {
        if (typeof state != "object") {
            return false;
        } else {
            this.setState(state, function () {
                this.setPricing();
                if (typeof (fn) == 'function') {
                    fn();
                }
            });
        }
    }

    setQtyOptions() {
        let { priceObject, quantity, selectedQty } = this.state;
        var canvasData = JSON.parse(localStorage.getItem('customers_canvas'));
        if(canvasData){
            this.setState({white_halo: canvasData.white_halo});
        }
        let qtyOptions = [];
        let initialQty = 1;
        let showCustomQtyField = true;
        if (typeof priceObject == "object") {
            Object.keys(priceObject.prices).forEach(function (qtyBreak, i) {
                qtyOptions.push({ value: parseInt(qtyBreak), label: qtyBreak });
            });
            if (!this.state.lot_pricing) {
                qtyOptions.push({ value: 'custom', label: 'Custom Quantity' });
            }

            showCustomQtyField = false;
            if (Object.keys(priceObject.prices).includes(String(quantity))) {
                initialQty = quantity;
                selectedQty = initialQty;
            } else if (selectedQty == 'custom') {
                initialQty = canvasData && canvasData.custom_qnty ? canvasData.quantity : quantity;
                showCustomQtyField = true;
            } else {
                initialQty = qtyOptions[0].value;
                selectedQty = initialQty;
            }

        } else {
            qtyOptions.push({ value: 'custom', label: 'Custom Quantity' });
        }

        this.setStateAndPricing({ showCustomQtyField, qtyOptions, quantity: initialQty, selectedQty: selectedQty });
    }

    onPriceJsonFetch() {
        let { selectedVariantBarcode, priceJson } = this.state;
        const priceObject = Util.getPricingObject(selectedVariantBarcode, priceJson);
        this.setStateAndPricing({ priceObject }, this.setQtyOptions.bind(this));
    }

    preselectDropdowns() {

        const { productDropdownOptions } = this.props;
        let defaultVals = {};
        Object.keys(productDropdownOptions).map(optName => {
            if (optName == "Size") {
                const dimensions = this.getDimensions(productDropdownOptions[optName][0].value);
                if (dimensions) {
                    const { optionWidth, optionHeight } = dimensions;
                    defaultVals['customWidth'] = optionWidth;
                    defaultVals['customHeight'] = optionHeight;
                    if (this.state.customDieCut) {
                        defaultVals[optName] = customSizeOption.value;
                    } else {
                        defaultVals[optName] = productDropdownOptions[optName][0].value;
                    }
                } else {
                    return;
                }
            } else {
                defaultVals[optName] = productDropdownOptions[optName][0].value;
            }
        });

        const selectedVariantProperties = {
            ...this.state.selectedVariantProperties,
            ...defaultVals
        };

        this.setState({ selectedVariantProperties }, () => {
            this.setVariant();
        });
    }

    validateDecimalPlaces(val, numOfPlaces) {
        val = String(val);
        if (val.indexOf('.') > -1) {
            const splits = val.split('.');
            if (splits[1].length > numOfPlaces) {
                return false;
            }
        }
        return true;
    }

    getDimensions(value) {
        let splits = value.split('x');
        splits = splits.map(split => {
            return Number(split.replace(/"|”| /g, ''));
        });
        if (splits.length < 2) {
            console.error('at least 1 size is wrongly formatted');
            return;
        }
        return { optionWidth: Number(splits[0]), optionHeight: Number(splits[1]) };
    }

    setVariant() {

        const { selectedVariantProperties, productData, selectedCustomSize } = this.state;
        const { productDropdownOptions } = this.props;

        if (!this.isset(productData.variants)) {
            return;
        }

        let sizeErrors = [];
        let allowAddToCart = true;
        this.setState({ 'sizeerror': '' });
        let real_size = productDropdownOptions.Size ? productDropdownOptions.Size[0].value : false;
        if (selectedVariantProperties.Size == 'custom') {
            const width = Number(selectedVariantProperties.customWidth);
            const height = Number(selectedVariantProperties.customHeight);
            const square = width * height;

            if (!this.validateDecimalPlaces(width, 2) || !this.validateDecimalPlaces(height, 2)) {
                sizeErrors.push('Only 2 decimals are allowed for custom size.');
            }




            let allApplicationOptions = [];
            let allOptions = [];
            productDropdownOptions.Size.map(option => {
                const { optionWidth, optionHeight } = this.getDimensions(option.value);
                const optionSquare = optionWidth * optionHeight;
                allOptions.push({ w: optionWidth, h: optionHeight, square: optionSquare, option: option });
                if (optionSquare >= width * height) {
                    allApplicationOptions.push({ w: optionWidth, h: optionHeight, square: optionSquare, option: option });
                }
            });

            allApplicationOptions.sort((a, b) => (a.square > b.square) ? 1 : ((b.square > a.square) ? -1 : 0));
            allOptions.sort((a, b) => (a.square > b.square) ? 1 : ((b.square > a.square) ? -1 : 0));

            let selectThisSize = { w: 999, h: 999, square: 999 * 999, option: false };

            allApplicationOptions.forEach(function (thisOption) {
                if (selectThisSize.square > thisOption.square) {
                    selectThisSize = thisOption;
                }
            });

            const allWidths = allOptions.map(opt => opt.w);
            const allHeights = allOptions.map(opt => opt.h);
            var allValues = allWidths.concat(allHeights);

            const minWidth = Math.min(...allWidths);
            const maxWidth = Math.max(...allWidths);
            const minHeight = Math.min(...allHeights);
            const maxHeight = Math.max(...allHeights);
            var minSquare = minWidth*minHeight;
            var maxSquare = 93; // There is a max of 93 square inches on all custom die cut stickers.
            var minValue = Math.min.apply(Math, allValues);
            var maxValue = Math.max.apply(Math, allValues);

            real_size = selectThisSize.option;
            if (!real_size ||
                width < minValue || width > maxValue || height < minValue || height > maxValue ||
                square < minSquare || square > maxSquare) {
                sizeErrors.push('Your custom size exceeds our minimum or maximum values.');
            }

            if (sizeErrors.length) {
                this.setState({ 'sizeerror': sizeErrors.join(' ') });
                allowAddToCart = false;
            } else {
                this.setState({
                    selectedCustomSize: [width, height]
                })
            }

        } else {
            if (selectedCustomSize) {
                this.setState({
                    selectedCustomSize: null
                })
            }
        }

        const variants = productData.variants.edges;

        let selectedVariant = variants.filter(variant => {
            const { selectedOptions, availableForSale } = variant.node;
            if (!availableForSale) {
                return;
            }
            let selected = true;

            selectedOptions.map(option => {
                if (option.name == "Size" && selectedVariantProperties.Size == 'custom') {
                    if (option.value != real_size.value) {
                        selected = false;
                    }
                } else if (selectedVariantProperties[option.name] != option.value) {
                    if(option.value == 'Custom'){
                        selected = true;
                    }else{
                        selected = false;
                    }
                    
                }
            })
            return selected;
        });
        if (selectedVariant.length && this.isset(selectedVariant[0].node)) {
            selectedVariant = selectedVariant[0].node;
        } else {
            allowAddToCart = false;
        }
        this.setState({ availableForSale: allowAddToCart });
        if (!allowAddToCart) {
            this.setState({ disableAddToCart: true });
            return;
        }

        let barcode = false;
        if (this.isset(selectedVariant.sku)) {
            const split = selectedVariant.sku.split('row');
            if (split.length > 1) {
                barcode = 'row' + split[split.length - 1];
            }
        }

        this.setStateAndPricing({
            selectedVariant,
            selectedVariantBarcode: barcode,
            //HARDCODED: other properties can be added like this: 
            // selectedVariantProperties: { ...selectedVariantProperties, 'Artwork': 'this_is_hardcoded.pdf' },
            selectedVariantProperties: { ...selectedVariantProperties },
        }, () => {
            this.onPriceJsonFetch();
            this.getTemplate();
            //Checks when setting pricing if it needs to convert to CAD
            this.conversionCheck();
        });
    }

    setProductProperties() {
        const { productData } = this.state;
        let fullErrorMessage = "";

        if (!this.isset(productData.variants)) {
            return;
        }

        let price_file_url = false;
        let priceGridTag = productData.tags.find(tag => tag.indexOf('$=') > -1);
        if (priceGridTag && priceGridTag != "") {
            priceGridTag = priceGridTag.replace('$=', '');
            const currentEnvironment = process.env.REACT_APP_URL_PRICING_JSON ? process.env.REACT_APP_URL_PRICING_JSON : "https://designer.readysetprint.com/apps/shopify-json/pricing";
            price_file_url = currentEnvironment + "/" + priceGridTag + '.json';
        } else {
            fullErrorMessage = `The product ${productData.title} doesn't contain the tag $=\nPlease add tag in order to retrieve JSON pricing file.`;
            if (process.env.REACT_APP_URL_PRICING_JSON) {
                toast.error(fullErrorMessage, { className: "pricing-error" });
            } else {
                console.log(fullErrorMessage);
            }
        }

        let markup = 0;
        let markupTag = productData.tags.find(tag => tag.indexOf('markup=') > -1);
        if (markupTag && markupTag != "") {
            markup = markupTag.replace('markup=', '');
            markup = Number(markup);
            if (isNaN(markup)) {
                markup = 0;
            }
        }
        if (productData.tags.includes(custom_shape_tag)) {
            this.setSizeLimits();
        }
        this.setState({
            hasOverlamination: productData.tags.includes(overlamination_tag),
            hasFoilStamping: productData.tags.includes(foil_stamping_tag),
            hasMatte: productData.tags.includes(matte_tag),
            hasTape: productData.tags.includes(tape_tag),
            hasImprint: productData.tags.includes(imprint_tag),
            hasNumbering: productData.tags.includes(numbering_tag),
            lot_pricing: productData.tags.includes(lot_pricing_tag),
            customSize: productData.tags.includes(custom_size_tag),
            customDieCut: productData.tags.includes(custom_die_cut_tag),
            customShape: productData.tags.includes(custom_shape_tag),
            customizable: productData.tags.includes(customizable_tag),
            clearBackground: productData.tags.includes(clear_background_tag),
            halo: productData.tags.includes(halo_tag),
            markup,
        }, () => {
            this.preselectDropdowns();
        });
        if (price_file_url) {
            axios.get(price_file_url)
                .then(res => {
                    const { isJsonValid, errorMessage } = Util.validatePricingJson(res.data, productData.variants.edges);
                    if (!isJsonValid) {
                        fullErrorMessage = `Pricing JSON contains errors: \n${errorMessage}`;
                        if (process.env.REACT_APP_URL_PRICING_JSON) {
                            toast.error(fullErrorMessage, { className: "pricing-error" });
                        } else {
                            console.log(fullErrorMessage);
                        }
                    } else {
                        this.setState({ priceJson: res.data }, () => {
                            this.onPriceJsonFetch();
                        });
                    }
                })
                .catch(() => {
                    fullErrorMessage = `Couldn't retrieve pricing JSON.\nPlease check that JSON pricing grid exists with name ${priceGridTag}.json`;
                    if (process.env.REACT_APP_URL_PRICING_JSON) {
                        toast.error(fullErrorMessage, { className: "pricing-error" });
                    } else {
                        console.log(fullErrorMessage);
                    }
                });
        } else {
            console.error('pricing tag unavailable');
        }

        client.send(storefrontQuerys.getProductsByType("Optional Extra")).then(({ model, data }) => {
           if(data){
                for (let i = 0; i < data.products.edges.length; i++) {
                    var prod = data.products.edges[i].node;
                    let tooltipText = productTypeTitles[prod.title];
                    this.setState({
                        [tooltipText]: prod.description
                    });
                };
            }
        });
    }

    handlePropertyDropdownChange(selection, node) {
        let selectedVariantProperties = this.state.selectedVariantProperties; 
        if(node.name == "Size" && selection.value != 'custom'){
            if(typeof selectedVariantProperties.customWidth != "undefined"){
                delete selectedVariantProperties.customWidth;
            }if(typeof selectedVariantProperties.customHeight != "undefined"){
                delete selectedVariantProperties.customHeight;
            }
        }
        this.setState({ selectedVariantProperties: { ...selectedVariantProperties, [node.name]: selection.value } }, () => {
            this.setVariant();
        });
    }

    handleQtyChange(selection) {
        let qty = selection.value;

        if (qty == 'custom') {
            this.setState({ showCustomQtyField: true });
        } else {
            this.setState({ showCustomQtyField: false });
        }
        if (isNaN(qty) || qty == "") {
            qty = 1;
        } else {
            qty = parseInt(qty);
        }
        this.setStateAndPricing({ quantity: qty, selectedQty: selection.value });
    }

    handleInputChange(e) {
        const ele = e.target;
        this.setStateAndPricing(
            {
                selectedVariantProperties: { ...this.state.selectedVariantProperties, [ele.name]: ele.type == "checkbox" ? ele.checked : ele.value }
            }, () => {
                this.setVariant();
            });
    }

    handleCustomSizeInputChange(properties, value){ // changes the main variant and state based off the cc custom height and width
        this.setStateAndPricing(
            {selectedVariantProperties:{...properties, customWidth: value}
        }, () => {
            this.setVariant();
        });
    }

    isset(v){
      return typeof(v) != "undefined" && v != null;
    }

    isEquivalent(a, b) {
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);
        if (aProps.length != bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    }

    setCartItems() {
        const { cartItems } = this.props;
        this.setState({ cartItems });
    }

    getQty(currentOnly, type) { // calculates qty entered in field in addition to the related qty in cart (if any)
        let qty = this.state.quantity;
        if (!currentOnly) {
            const { cartItems, selectedVariant } = this.state;
            type = typeof type != "undefined" ? type : 'product';
            let lineItems = [];
            if (type == 'product') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id);
            } else if (type == 'overlamination') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id && item.properties.overlamination);
            } else if (type == 'foil_stamping') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id && item.properties.foil_stamping);
            } else if (type == 'matte') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id && item.properties.matte);
            } else if (type == 'tape') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id && item.properties.tape);
            } else if (type == 'imprint') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id && item.properties.imprint);
            } else if (type == 'numbering') {
                lineItems = cartItems.filter(item => item.id == selectedVariant.id && item.properties.numbering);
            }
            lineItems.map(item => qty += item.qty);
        }
        return qty;
    }

    getDiscountedPrice(variantQty, type) { // calculates discounted price if applicable else returns 0
        let discountedPrice = 0;
        let lotSize = 0;
        const { priceObject, lot_pricing, markup } = this.state;

        if (priceObject) {
            if (!this.isset(type)) {
                type = "prices";
            }
            if (this.isset(Object.keys(priceObject[type])[0])) {
                // Set the lot size
                if (variantQty >= 500) {
                    lotSize = 500; // If quantity is 500 or more, set lot size to 500
                } else {                    
                    lotSize = Object.keys(priceObject[type])[0]; // Set lot size to smallest quantity break
                }
            }
            // Loop through the prices of the applicable price grid
            Object.keys(priceObject[type]).forEach(function (qtyBreak) {
                // Find the highest qualifying quantity break
                if (Number(qtyBreak) <= variantQty) {
                    // Set discountedPrice to the net price that matches the current quantity break
                    discountedPrice = priceObject[type][qtyBreak];
                    if (typeof (priceObject['msrp_markup_multiplier'][qtyBreak]) != 'undefined') {
                        // Core pricing formula used to calculate the bulk discount
                        // Retail = (1 + markup%) * msrp_markup_multiplier * net_row_x
                        discountedPrice = (1 + markup / 100) * priceObject['msrp_markup_multiplier'][qtyBreak] * discountedPrice;
                    }
                }
            });
            if (lot_pricing && lotSize) {
                // Adjust discount for lot pricing
                discountedPrice = discountedPrice / lotSize;
            }
        }
        return parseFloat(discountedPrice.toFixed(2));
    }

    validateQty() {
        const { priceObject } = this.state;
        this.setState({ qtyerror: '' });
        const currentQty = this.getQty(true);
        let allowAddToCart = true;
        if (priceObject) {
            const minQty = Object.keys(priceObject.prices)[0];
            if (this.state.quantity < 1) { // checking if current qty is more than 0
                allowAddToCart = false;
            } else if (currentQty < minQty) { // checking if cart qty + current qty is less than min price object qty
                this.setState({ qtyerror: 'Minimum order quantity for this product is ' + minQty + '.<br>Add ' + (minQty - currentQty) + ' more.' });
                allowAddToCart = false;
            }
        }
        if (allowAddToCart) {
            this.setState({ disableAddToCart: false });
        } else {
            this.setState({ disableAddToCart: true });
        }
    }

    setPricing() {
        const { selectedVariant, priceObject, selectedVariantProperties, cartItems } = this.state;
        const qty = this.getQty();
        let currentQty = this.getQty(true);
        const overlaminationQty = this.getQty(false, 'overlamination');
        const foilStampingQty = this.getQty(false, 'foil_stamping');
        const matteQty = this.getQty(false, 'matte');
        const tapeQty = this.getQty(false, 'tape');
        const imprintQty = this.getQty(false, 'imprint');
        const numberingQty = this.getQty(false, 'numbering');
        let discountedPrice = this.getDiscountedPrice(qty, 'prices');
        let overlaminationPrice = 0;
        let foilPrice = 0;
        let mattePrice = 0;
        let tapePrice = 0;
        let imprintPrice = 0;
        let numberingPrice = 0;
        if (selectedVariantProperties.overlamination) {
            overlaminationPrice = this.getDiscountedPrice(overlaminationQty, 'overlamination');
        }
        if (selectedVariantProperties.foil_stamping) {
            foilPrice = this.getDiscountedPrice(foilStampingQty, 'foil');
        }
        if (selectedVariantProperties.matte) {
            mattePrice = this.getDiscountedPrice(matteQty, 'matte');
        }
        if (selectedVariantProperties.tape) {
            tapePrice = this.getDiscountedPrice(tapeQty, 'tape');
        }
        if (selectedVariantProperties.imprint) {
            imprintPrice = this.getDiscountedPrice(imprintQty, 'imprint');
        }
        if (selectedVariantProperties.numbering) {
            numberingPrice = this.getDiscountedPrice(numberingQty, 'numbering');
        }
        currentQty = (typeof currentQty != "number" || isNaN(currentQty) || currentQty <= 0) ? 1 : currentQty;
        let finalPrice = 0;
        let unitPrice = 0;
        let inclusivenUnitPrice = 0;
        if (discountedPrice <= 0) {
            discountedPrice = parseFloat(selectedVariant.price);
        }

        unitPrice = 1 * discountedPrice;
        inclusivenUnitPrice = discountedPrice + overlaminationPrice + foilPrice + mattePrice + tapePrice + imprintPrice + numberingPrice;
        finalPrice = currentQty * inclusivenUnitPrice;
        finalPrice = Math.round(finalPrice * 100) / 100;
        //Checks if cadConversion is true, and converts to CAD if it is, adds a 2.3% conversion fee, then appends '(CAD)' to end.
        const formattedFinalPrice = isNaN(finalPrice) ? '' : this.state.cadConversion ? 
            '$' + (window.Currency.convert((Math.round(finalPrice * 100) / 100), 'USD', 'CAD')*1.023).toFixed(2) + ' (CAD)'
            : '$' + (finalPrice.toFixed(2));
        //First checks if inclusivenUnitePrice is NaN, then checks if cadConversion is true, then converts and adds a 2.3% conversion fee.
        const formattedInclusiveUnitPrice = isNaN(inclusivenUnitPrice) ? '' : this.state.cadConversion ? 
            (window.Currency.convert((Math.round(inclusivenUnitPrice * 1000) / 1000), 'USD', 'CAD')*1.023).toFixed(2)  
            :
            (Math.round(inclusivenUnitPrice * 1000) / 1000).toFixed(2);
        const discountApplied = discountedPrice < parseFloat(selectedVariant.price.amount) && currentQty < qty;
        //console.log(formattedFinalPrice);
        this.setState({
            formattedFinalPrice,
            formattedInclusiveUnitPrice,
            unitPrice,
            overlaminationPrice,
            foilPrice,
            mattePrice,
            tapePrice,
            imprintPrice,
            numberingPrice,
            inclusivenUnitPrice,
            discountApplied,
            finalPrice
        });

        this.validateQty();
    }

    addToCart() {

        const { isPunchout } = this.props;

        const {
            quantity,
            selectedVariant,
            selectedVariantProperties,
            inclusivenUnitPrice,
            lot_pricing,
            priceObject,
            productData,
            selectedVariantBarcode,
            markup,
            finalPrice,
            white_halo
        } = this.state;

        let newCartItem = {
            ...this.props.item,
            lineItemId: Date.now(),
            id: selectedVariant.id,
            selectedVariant,
            barcode: selectedVariantBarcode,
            properties: selectedVariantProperties,
            lot_pricing,
            priceObject,
            productData,
            markup,
            white_halo
        };
        this.props.addToCartClicked(newCartItem, quantity).then(async () => {
            if (isPunchout) {
                punchout_addToCart(newCartItem, quantity, finalPrice);
            } else {
                this.props.history.push("/cart")
            }
        });
    }

    getSizeOptions() {
        const sizeOptions = this.state.customDieCut ? [] : this.props.productDropdownOptions.length ? this.props.productDropdownOptions["Size"] : [];

        this.setState((state) => ({
            sizeOptions: state.customSize ? [...sizeOptions, customSizeOption] : sizeOptions
        }))
    }

    toggleToolTip = (ev, b) => {
        const tooltipId = ev.target.id;
        this.setState((state) => ({
            [tooltipId]: !state[tooltipId]
        }))
    }
    onHaloChange = (e) => {
        this.setState({[e.target.name]: e.target.checked});
    }

    render() {
        const { symbol, item, addToCartClicked, productData, BuynowClicked, addToWishlistClicked, productDropdownOptions, isStaging, translate } = this.props;
        let sizeOptions = this.state.customDieCut ? [] : productDropdownOptions["Size"] ? productDropdownOptions["Size"] : [];
        sizeOptions = this.state.customSize ? [...sizeOptions, customSizeOption] : sizeOptions
        const { selectedVariantProperties } = this.state;

        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };
        let sizeExists = false;
        return (
            <div className="col-lg-6 rtl-text">
                <div>
                </div>
                <div className="product-right">
                    <div className="product-info">
                        <h2> {productData.title} </h2>
                        <p className="sub-heading">
                            {translate("details")}
                    </p>
                        <p className="info-detail" dangerouslySetInnerHTML={{ __html: productData.descTruncated }} />
                        <div className="row">
                            {
                                Object.keys(productDropdownOptions).map((val, index) => {
                                    const hasWhiteVinylOptions = productDropdownOptions[val].find(p => p.value === "White Vinyl" || p.value === "White Vinyl (Removable)");
                                    if (val == "Size") {
                                        sizeExists = true;
                                        return;
                                    }
                                    return (
                                        <div className="col-md-6" key={index}>
                                            <div className="form-group">
                                        {val === "Material" && hasWhiteVinylOptions  ?
                                            <div style={{ display: "flex" }}>
                                                <p className="sub-heading"> {translate(val)} <IconInformation id={`materialTooltip`}/></p>
                                                {/* <button className="tooltip-info" id={`materialTooltip`} style={{ marginTop: "1.6rem" }}>i</button> */}
                                                {/* <IconInformation id={`materialTooltip`}/> */}
                                                <Tooltip trigger="click hover" placement="right" isOpen={this.state.materialTooltip} target={`materialTooltip`} toggle={this.toggleToolTip}>
                                                <div><b>{translate("White Vinyl")}:</b></div>
                                                    <div>{translate("Outdoor durable with standard permanent adhesive")}</div>
                                                    <div><b>{translate("Removable White Vinyl")}:</b></div>
                                                    <div>{translate("Outdoor durable with low-tack adhesive")}</div>
                                                </Tooltip>
                                            </div>
                                            : <p className="sub-heading"> {translate(val.toLowerCase())}</p>
                                            }
                                            <Select
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        height: '3rem',
                                                        minHeight: '30px',
                                                    }),
                                                    indicatorSeparator: () => { }, // removes the "stick"
                                                    dropdownIndicator: defaultStyles => ({
                                                        ...defaultStyles,
                                                        color: 'black' // your changes to the arrow
                                                    })
                                                }}
                                                components={{ IndicatorSeparator }}
                                                value={productDropdownOptions[val].filter(({ value }) => value == this.state.selectedVariantProperties[val])}
                                                onChange={this.handlePropertyDropdownChange}
                                                name={val}
                                                options={productDropdownOptions[val]}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                );

                            })
                        }
                    </div>
                    <div className="row bottom_row">
                        {
                            sizeExists ? (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p className="sub-heading"> {translate("Size")} </p>
                                        <Select
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    height: '3rem',
                                                    minHeight: '30px',
                                                }),
                                                indicatorSeparator: () => { }, // removes the "stick"
                                                dropdownIndicator: defaultStyles => ({
                                                    ...defaultStyles,
                                                    color: 'black' // your changes to the arrow
                                                })
                                            }}
                                            components={{ IndicatorSeparator }}
                                            value={
                                                !this.state.selectedVariantProperties["Size"] || this.state.selectedVariantProperties["Size"] == 'custom' || this.state.customDieCut ?
                                                    (this.state.customSize ? customSizeOption : productDropdownOptions["Size"][0])
                                                    :
                                                    productDropdownOptions["Size"].filter(({ value }) => value == this.state.selectedVariantProperties["Size"])
                                            }
                                            onChange={this.handlePropertyDropdownChange}
                                            name="Size"
                                            options={sizeOptions}
                                            isSearchable={false}
                                        />
                                        {
                                            this.state.selectedVariantProperties["Size"] == 'custom' ?
                                                (
                                                    <div className="custom-sizes" style={this.state.customShape ? {display: "none"} : { display: "flex", justifyContent: "space-between", flexWrap: "wrap", position: "relative" }}>
                                                        <label style={{ marginTop: 6, position: "relative", width: "46%" }}>
                                                            <p className="sub-heading">Width</p>
                                                <input type="text"
                                                                style={{ borderRadius: "5px", height: "3rem", minHeight: 30, display: "block", width: "100%", fontSize: "14px" }}
                                                                className="form-control"
                                                                name="customWidth"
                                                                placeholder="Width"
                                                                onChange={this.handleInputChange}
                                                                value={this.state.selectedVariantProperties.customWidth || ""}
                                                            /> <span style={{ position: "absolute", right: "6px", top: "66px" }}>in</span>
                                                        </label>
                                                        <span className="custom-sizes-x" style={{ paddingTop: "67px" }}> x </span>
                                                        <label style={{ marginTop: 6, position: "relative", width: "46%" }}>
                                                        <p className="sub-heading">Height</p>
                                                <input type="text"
                                                                style={{ borderRadius: "5px", height: "3rem", minHeight: 30, display: "block", width: "100%", fontSize: "14px" }}
                                                                className="form-control"
                                                                name="customHeight"
                                                                placeholder="Height"
                                                                onChange={this.handleInputChange}
                                                                value={this.state.selectedVariantProperties.customHeight || ""}
                                                            /> <span style={{ position: "absolute", right: "6px", top: "66px" }}>in</span>
                                                        </label>
                                                        <div className="error" style={{ color: '#f00', width: "100%" }} dangerouslySetInnerHTML={{ __html: this.state.sizeerror }}></div>
                                                    </div>
                                                ) : ""
                                        }
                                    </div>
                                </div>
                            ) : ''
                        }

                        <div className="col-md-6" style={!this.state.availableForSale ? {'display': 'none'} : {}}>
                            <div className="form-group">
                                <p className="sub-heading">{translate("Quantity")} </p>
                                <Select
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            height: '3rem',
                                            minHeight: '30px',
                                        }),
                                        indicatorSeparator: () => { }, // removes the "stick"
                                        dropdownIndicator: defaultStyles => ({
                                            ...defaultStyles,
                                            color: 'black' // your changes to the arrow
                                        })
                                    }}
                                    value={this.state.qtyOptions.filter(({ value }) => value === this.state.selectedQty)}
                                    onChange={this.handleQtyChange}
                                    options={this.state.qtyOptions}
                                    components={{ IndicatorSeparator }}
                                    isSearchable={false}
                                />
                            </div>

                            <div style={{ marginBottom: 6 }}>
                                <input name="quantity" style={{ borderRadius: "5px", height: "3rem", minHeight: 30, marginBottom: 6, fontSize: "14px" }} className="form-control" type={this.state.showCustomQtyField ? "text" : "hidden"} onChange={this.changeQty} value={this.state.quantity} />
                                <div className="error" style={{ color: '#f00' }} dangerouslySetInnerHTML={{ __html: this.state.qtyerror }}></div>
                            </div>
                            {
                                !this.state.discountApplied ? (
                                    <p className="info-tip">{translate("Order more, save more!")}</p>
                                ) : ""
                            }
                        </div>
                    </div>
                    {
                        this.state.discountApplied ? (
                            <div className="col-md-12">
                                <p className="discount-tip text-center mb-3">{translate("quantity_discount")}</p>
                            </div>
                        ) : ""
                    }
                    {
                        this.state.hasOverlamination ? (
                            <div className="form-check">
                                <label className="form-check-label sub-heading">
                                    <input className="form-check-input" type="checkbox" name="overlamination" checked={this.state.selectedVariantProperties.overlamination} onChange={this.handleInputChange} />
                                    Overlamination
                                    {this.state.overlaminationTooltipText !== "" && <IconInformation id={`overlaminationTooltip`}/>}
                                </label>
                                {this.state.overlaminationTooltipText !== "" && <Tooltip trigger="click hover" placement="right" isOpen={this.state.overlaminationTooltip} target="overlaminationTooltip" toggle={this.toggleToolTip}>
                                    {this.state.overlaminationTooltipText}
                                </Tooltip>}
                            </div>
                        ) : ''
                    }
                    {/* Commenting for now. This code might need to come back later upon client's request
                    {
                        this.state.hasFoilStamping ? (
                            <div className="form-check">
                                <label className="form-check-label sub-heading">
                                    <input className="form-check-input" type="checkbox" name="foil_stamping" checked={this.state.selectedVariantProperties.foil_stamping} onChange={this.handleInputChange} />
                                    Foil Stamping
                                    {this.state.foilStampingTooltipText !== "" && <IconInformation id={`foilStampingTooltip`}/>}
                                </label>
                                {this.state.foilStampingTooltipText !== "" && <Tooltip trigger="click hover" placement="right" isOpen={this.state.foilStampingTooltip} target="foilStampingTooltip" toggle={this.toggleToolTip}>
                                    {this.state.foilStampingTooltipText}
                                </Tooltip>}
                            </div>
                        ) : ''
                    }
                    {
                        this.state.hasMatte ? (
                            <div className="form-check">
                                <label className="form-check-label sub-heading">
                                    <input className="form-check-input" type="checkbox" name="matte" checked={this.state.selectedVariantProperties.matte} onChange={this.handleInputChange} />
                                    Matte Varnish
                                    {this.state.matteVarnishTooltipText !== "" && <IconInformation id={`matteVarnishTooltip`}/>}
                                </label>
                                {this.state.matteVarnishTooltipText !== "" && <Tooltip trigger="click hover" placement="right" isOpen={this.state.matteVarnishTooltip} target="matteVarnishTooltip" toggle={this.toggleToolTip}>
                                    {this.state.matteVarnishTooltipText}
                                </Tooltip>}
                            </div>
                        ) : ''
                    } */}
                    {
                        this.state.hasTape ? (
                            <div className="form-check">
                                <label className="form-check-label sub-heading">
                                    <input className="form-check-input" type="checkbox" name="tape" checked={this.state.selectedVariantProperties.tape} onChange={this.handleInputChange} />
                                    Double Sided Tape
                                    {this.state.doubleSidedTapeTooltipText !== "" && <IconInformation id={`doubleSidedTapeTooltip`}/>}
                                </label>
                                {this.state.doubleSidedTapeTooltipText !== "" && <Tooltip trigger="click hover" placement="right" isOpen={this.state.doubleSidedTapeTooltip} target="doubleSidedTapeTooltip" toggle={this.toggleToolTip}>
                                    {this.state.doubleSidedTapeTooltipText}
                                </Tooltip>}
                            </div>
                        ) : ''
                    }
                    {
                        this.state.hasImprint ? (
                            <div className="form-check">
                                <label className="form-check-label sub-heading">
                                    <input className="form-check-input" type="checkbox" name="imprint" checked={this.state.selectedVariantProperties.imprint} onChange={this.handleInputChange} />
                                    Back Imprint
                                    {this.state.backImprintTooltipText !== "" && <IconInformation id={`backImprintTooltip`}/>}
                                </label>
                                {this.state.backImprintTooltipText !== "" && <Tooltip trigger="click hover" placement="right" isOpen={this.state.backImprintTooltip} target="backImprintTooltip" toggle={this.toggleToolTip}>
                                    {this.state.backImprintTooltipText}
                                </Tooltip>}
                            </div>
                        ) : ''
                    }
                    {
                        this.state.hasNumbering ? (
                            <div className="form-check">
                                <label className="form-check-label sub-heading">
                                    <input className="form-check-input" type="checkbox" name="numbering" checked={this.state.selectedVariantProperties.numbering} onChange={this.handleInputChange} />
                                    Numbering
                                    {this.state.numberingTooltipText !== "" &&  <IconInformation id={`numberingTooltip`}/>}
                                </label>
                                {this.state.numberingTooltipText !== "" && <Tooltip trigger="click hover" placement="right" isOpen={this.state.numberingTooltip} target="numberingTooltip" toggle={this.toggleToolTip}>
                                    {this.state.numberingTooltipText}
                                </Tooltip>}
                            </div>
                        ) : ''
                    }
                    <div className="info-price">
                        <span className="info-price-main">
                            {this.state.formattedFinalPrice }
                        </span>
                        <span className="info-price-sub">
                            {translate("$## /unit", {value: this.state.formattedInclusiveUnitPrice})}
                    </span>
                    </div>
                    {
                        !this.state.availableForSale ? (
                            <div className="error mt-2" style={{ color: '#f00' }}>This item is not available for sale.</div>
                        ) : ""
                    }
                    {
                        this.state.halo &&
                        <div className="extras">
                          <p className="extras_title">EXTRAS</p> 
                          <label className="extras_doc">
                            <input type="checkbox" id="halo_input" className="addons" data-var="halo" name="white_halo" onChange={this.onHaloChange} checked={this.state.white_halo || false} /> <span className="extras_sub_title">Put a white halo around the art</span>
                            <IconInformation id={`whiteHaloTooltip`}/>
                            <Tooltip trigger="click hover" placement="right" isOpen={this.state.whiteHaloTooltip} target={`whiteHaloTooltip`} toggle={this.toggleToolTip}>
                                <div><b>{translate("See the picture in the images section to see an example")}</b></div>
                            </Tooltip>
                          </label>
                        </div>
                    }
                    <div className="product-buttons mb-0" >
                        <input type='file'
                            name='file'
                            id='cloudinaryFileUpload'
                            className='cloudinary-fileupload'
                            data-cloudinary-field='image_value'
                            style={{display: 'none'}}
                            accept='.jpeg,.jpg,.png,.pdf,.ai,.eps,.ai'
                        />
                        <form id="upload_archive" encType="multipart/form-data" className="pt-2">
                            <input type="text" id="image_file" style={{ display: 'none' }}></input>
                            <label htmlFor="attachmentName" className="btn-file">
                                <input type="file" id="attachmentName" name="attachmentName" style={{ display: 'none' }} accept='.gif,.jpeg,.jpg,.png,.pdf,.svg'></input>
                            </label>
                            <input type="button" id="upload_button" disabled={this.state.disableAddToCart || !this.state.availableForSale} onClick={this.state.customizable ? this.handleFileUpload : this.addToCart} value={this.state.customizable ? translate("DESIGN NOW") : translate("ADD TO CART")} className="btn btn-primary d-block mt-1"></input>
                        </form>
                    </div>
                </div>
            </div>
            <Modal
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                classNames={{
                    modal: "canvas-modal"
                }}
            >
                <ProductCanvasModal
                    modalProps={{
                        anonymousUser: this.state.anonymousUser,
                        customerId: this.state.customerId,
                        token: this.state.token,
                        canvasTemplate: this.state.selectedVariantTemplate,
                        uploadedImagePath: this.state.image_path,
                        uploadedImageHeight: this.state.image_height,
                        uploadedImageWidth: this.state.image_width,
                        customShape: this.state.customShape,
                        customShapeUrls: this.state.customShapeUrls,
                        customShapeSize: this.state.customShapeSize,
                        selectedCustomSize: this.state.selectedCustomSize,
                        selectedCustomQnty: this.state.quantity,
                        productTitle: productData.title,
                        productData: productData
                    }}
                    selectedVariantProperties = {this.state.selectedVariantProperties}
                    addToCartClicked={this.addToCart}
                    updateVariantProperties={this.updateVariantProperties}
                    item={item}
                    onCloseModal={this.onCloseModal}
                    qtyOptions={this.state.qtyOptions}
                    selectedQty={this.state.selectedQty}
                    handleQtyChange={this.handleQtyChange}
                    handlePropertyDropdownChange={this.handlePropertyDropdownChange}
                    sizeOptions={productDropdownOptions.Size}
                    selectedSize={this.state.selectedVariantProperties.Size}
                    formattedFinalPrice={this.state.formattedFinalPrice}
                    formattedInclusiveUnitPrice={this.state.formattedInclusiveUnitPrice}
                    canvasError={this.state.canvasError}
                    handleError={this.handleError}
                    setStateAndPricing={this.setStateAndPricing}
                    productVariants={productData.variants}
                    handleCustomSizeInputChange={this.handleCustomSizeInputChange}
                    xHighLimit={this.state.xHighLimit}
                    xLowLimit={this.state.xLowLimit}
                    yHighLimit={this.state.yHighLimit}
                    yLowLimit={this.state.yLowLimit}
                    isStaging={isStaging}
                    clearBackground={this.state.clearBackground}
                    customerId = {this.props.customerId}
                    history = {this.props.history}
                    white_halo = {this.state.white_halo}
                />
            </Modal>
        </div >
        )
    }
}

const mapStateToProps = state => ({
    customerId: state.customer.loggedInUserDetails.id
});

export default connect(mapStateToProps)(withRouter(withTranslate(DetailsWithPrice)));
