import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { client } from "../../../index";
import { getCustomerOrderByIdQuery } from '../../../graphql/querys';
import Util from "../../../util";
import { addToCart } from "../../../actions";
import { connect } from "react-redux";
import withTranslate from "react-redux-multilingual/lib/withTranslate";

const OrderDetail = ({ history, addToCart, translate, match }) => {
    const { id } = useParams();
    const [order, setOrder] = useState(undefined);
    const search = useLocation().search;
    const key = new URLSearchParams(search).get('key');
    useEffect(() => {
        document.querySelector(".loader-wrapper").style = "display: block";
        client.send(getCustomerOrderByIdQuery(`gid://shopify/Order/${id}?key=${key}`)).then(({ model }) => {
            model && setOrder(model.node);
            document.querySelector(".loader-wrapper").style = "display: none";
        });
        setTimeout(() => window.analytics.page(), 1000);
    }, []);

    const renderLineItem = (lineItem, index) => {
        const attributes = lineItem.customAttributes;
        const properties = {};
        if (attributes) {
            for (const { key, value } of attributes)
                properties[key] = value;
        }


        return (
            <div className="product-row" key={index}>
                <div className="row">
                    <div className="col-xl-7 col-sm-7">
                        <LineItemDescription lineItem={lineItem} properties={properties} />
                    </div>
                    <div className="col-xl-3 col-sm-3 product-action">
                        {
                            lineItem.customAttributes && lineItem.customAttributes.fulfillmentStatus && (
                                <p>{lineItem.customAttributes.fulfillmentStatus}</p>)
                        }
                        {properties.print_ready_image && <a href={properties.print_ready_image} className="text-primary">
                            View artwork
                        </a>
                        }
                    </div>
                    <div className="col-xl-2 col-sm-2 block-product-price text-right">
                        {Util.getCurrencySymbolFromCode(lineItem.discountedTotalPrice.currencyCode)}
                        {Util.renderAmount(lineItem.discountedTotalPrice.amount)}
                        {lineItem.discountedTotalPrice.currencyCode === 'USD' ? ' (USD)' : lineItem.discountedTotalPrice.currencyCode === 'CAD' ? ' (CAD)' : ''}
                    </div>
                </div>

                {
                    (index === order.lineItems.length - 1) && renderSubTotals()
                }
            </div>
        );
    }

    const renderProductDescription = () => {
        if (order.lineItems && order.lineItems.length > 0) {
            return (
                order.lineItems.map((lineItem, index) => (
                    renderLineItem(lineItem, index)
                ))
            );
        }
    };

    const renderSubTotals = () => (<div className="subtotals">
        {order.subtotalPriceV2 && (<div className="row">
            <div className="col-xl-7 col-sm-7" />
            <span className="sub-heading col-xl-3 col-sm-3">{translate("Subtotal")}:</span>
            <span
                className="col-xl-2 col-sm-2 text-right">{Util.getCurrencySymbolFromCode(order.subtotalPriceV2.currencyCode)}{Util.renderAmount(order.subtotalPriceV2.amount)}</span>
        </div>)}
        {order.discountApplications && (<div className="row">
            <div className="col-xl-7 col-sm-7" />
            <span className="sub-heading col-xl-3 col-sm-3">Total savings:</span>
            <span
                className="col-xl-2 col-sm-2 text-right">{Util.getCurrencySymbolFromCode(order.discountApplications.value.currencyCode)}{Util.renderAmount(order.discountApplications.value.amount)}</span>
        </div>)}
        {order.totalShippingPriceV2 && (<div className="row">
            <div className="col-xl-7 col-sm-7" />
            <span className="sub-heading col-xl-3 col-sm-3">Shipping:</span>
            <span
                className="col-xl-2 col-sm-2 text-right">{Util.getCurrencySymbolFromCode(order.totalShippingPriceV2.currencyCode)}{Util.renderAmount(order.totalShippingPriceV2.amount)}</span>
        </div>)}
        {order.totalTax && (<div className="row">
            <div className="col-xl-7 col-sm-7" />
            <span className="sub-heading col-xl-3 col-sm-3">Sales tax:</span>
            <span
                className="col-xl-2 col-sm-2 text-right">{Util.getCurrencySymbolFromCode(order.totalTax.currencyCode)}{Util.renderAmount(order.totalTax.amount)}</span>
        </div>)}
        {order.totalPriceV2 && (<div className="row">
            <div className="col-xl-7 col-sm-7" />
            <span className="sub-heading col-xl-3 col-sm-3">Order total:</span>
            <span
                className="col-xl-2 col-sm-2 text-right">{Util.getCurrencySymbolFromCode(order.totalPriceV2.currencyCode)}{Util.renderAmount(order.totalPriceV2.amount)}</span>
        </div>)}
    </div>)

    const reorder = () => {

        let promises = [];

        for (let lineItem of order.lineItems) {
            if (lineItem.variant) promises.push(new Promise((resolve, reject) => {
                Util.reorderLineItem(lineItem, addToCart)
                    .then(() => resolve())
                    .catch(err => {
                        alert("Couldn't complete reorder")
                        reject(err)
                    })
            }));
        }

        Promise.all(promises).then(() => history.push("/cart"));
    }


    return order ? (
        <div className="section-order-summary">
            <h3 className="mobile-menu text-center">
                Orders &nbsp; &nbsp;
                <i className="fa fa-chevron-down"></i>
            </h3>
            <div className="order-detail   collection-collapse-block">
                <div className="row">
                    <div className="col-sm-9">
                        <h1 className="order-title">Order #: {order.orderNumber}</h1>
                        <p className="order-name">{order.name}</p>
                        <p className="order-status">
                            Order date: &nbsp;
                            <span className="sub-heading">{new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                        }).format(new Date(order.processedAt))}</span>
                        </p>
                        <p className="order-status">
                            This order is currently &nbsp;
                            <span className="sub-heading">{order.fulfillmentStatus}</span>
                        </p>
                    </div>
                    <div className="col-sm-3 block-btn-reorder">
                        <button className="btn btn-primary rounded w-100" style={{ opacity: 1 }} onClick={reorder}>ReOrder</button>
                    </div>
                </div>
                <div className="div-table order-detail-table">
                    <div className="div-header">
                        <div className="row">
                            <div className="col-xl-7 col-sm-7 sub-heading text-capitalize">{translate("description")}</div>
                            <div className="col-xl-3 col-sm-3 sub-heading"></div>
                            <div className="col-xl-2 col-sm-2 sub-heading text-right">
                            {translate("Total")}
                            </div>
                        </div>
                    </div>
                    {renderProductDescription()}
                </div>
                {order.shippingAddress && (<div className="row">
                    <div className="col-xl-8 col-sm-8">
                        <div className="row">
                            <div className="col-sm-6 block-info-address">
                                <p className="sub-heading">Shipping Info</p>
                                <p>{order.shippingAddress.firstName} {order.shippingAddress.lastName}</p>
                                <p>{order.shippingAddress.address1}</p>
                                <p>{order.shippingAddress.address2}</p>
                                <p>{order.shippingAddress.city && order.shippingAddress.city}, {order.shippingAddress.province && order.shippingAddress.province} {order.shippingAddress.zip && order.shippingAddress.zip}</p>
                                <p>{order.shippingAddress.country}</p>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    ) : null;
};

const LineItemDescription = withTranslate(({ lineItem, properties, translate }) => {
    const renderImage = () => {
        let imageSrc;
        if (properties.thumbnail)
            imageSrc = properties.thumbnail
        else if (lineItem && lineItem.variant && lineItem.variant.image)
            imageSrc = lineItem.variant.image.src;

        if (imageSrc)
            return (<img src={imageSrc} style={{ width: '90px' }} />)
        else return null;
    }
    return (
        <div>
            <div className="section-product-description">
                <div className="d-flex align-items-center">
                    {renderImage()}
                    <div className="descrition-info ">
                        <p className="info-title">{lineItem.title}</p>
                        {lineItem.currentQuantity !== undefined && <p>{translate("Quantity")}: {lineItem.currentQuantity}</p>}
                        {properties.material && <p>Material: {properties.material}</p>}
                        {properties.cut && <p>{translate("Cut")}: {properties.cut}</p>}
                        {properties.size && properties.size.x && properties.size.y &&
                            <p>{translate("Size")}: {`${properties.size.x}`}{` x ${properties.size.y}`}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
});

const mapStateToProps = (_state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, { addToCart })(withTranslate(OrderDetail)));
