import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

import {getBestSeller} from "../../services";
import withTranslate from 'react-redux-multilingual/lib/withTranslate';


class NewProduct extends Component {
  
    constructor(props) {
        super(props);
    }
    
    render (){
        const {symbol, relatedProducts, translate} = this.props;
        
        return (
            <div className="theme-card">
                <h5 className="title-border">{translate("similar products")}</h5>
                <Slider className="offer-slider slide-1">
                    {relatedProducts.map((product, index) =>
                        <div key={index}>
                                <div className="media" key={index}>
                                    <Link to={`${process.env.PUBLIC_URL}/products/${product.handle}`}><img className="img-fluid" src={`${product.image}`} alt={product.title} /></Link>
                                    <div className="media-body align-self-center">
                                        <Link to={`${process.env.PUBLIC_URL}/products/${product.handle}`}><h6>{product.title}</h6></Link>
                                    </div>
                                </div>
                        </div>
                    )}
                </Slider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, null)(withTranslate(NewProduct));
