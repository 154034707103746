import React from 'react'
import Modal from "react-responsive-modal";
 
const DeleteConfirmation = ({ showModal, hideModal, confirmModal }) => {
    return (
        <Modal open={showModal} center onClose={hideModal}
        styles={{
            modal: {
                borderRadius: "5px",
                overflow: "hidden",
                padding: '30px'
            },

        }}
        >
          <div className='text-center'>
            <p>
              Are you sure you want to delete this file?
            </p>
            <button className='btn btn-primary rounded  mr-3' onClick={hideModal}>
              Cancel
            </button>
            <button className='btn btn-primary rounded'  onClick={() => confirmModal() }>
              Delete
            </button>
          </div>
      </Modal>
    )
}
 
export default DeleteConfirmation;