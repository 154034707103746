import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import ProductItem from './product-item';

import { client } from '../../../../index';
import * as storefrontQuerys from '../../../../graphql/querys';
import store from '../../../../store';
import { receivePopularProducts } from "../../../../actions"
import { get } from "lodash";

import Util from "../../../../util";

const PopularProducts = () => {

    const [loading, setLoading] = useState(true);
    const { popularProducts, symbol } = useSelector(state => ({
        popularProducts: state.popularProducts.products,
        symbol: state.popularProducts.symbol,
    }));
    const [productGrid, setProductGrid] = useState({});

    useEffect(() => {
        let productGrid = window.productGrid;
        if (process.env.REACT_APP_RESELLER_ID) {
            Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Product Grid", "productGrid").then(response => setProductGrid(response));
        } else {
            setProductGrid(productGrid);
        }
    });
    
    useEffect(() => {
        if (productGrid && productGrid.categoryId) {
            setProductGrid(productGrid);
            client.send(storefrontQuerys.getCollectionByIdQuery(productGrid.categoryId)).then(({ model }) => {
                if (model && model.node) {
                    let popularProducts = []
                    const products = model.node.products;
                    popularProducts = products.slice(0, productGrid.count)
                    store.dispatch(receivePopularProducts(popularProducts))
                    setLoading(false);
                }
            }).catch(e => {
                console.log(e);
                setLoading(false);
            })
        }
    }, [productGrid]);

    if (loading) return <></>
    else
        return (
            <>
                {popularProducts.length > 0 &&
                    < div className="pt-lg-5">
                        <div className="title1">
                            {/* <h4>exclusive products</h4> */}
                            <h2>{productGrid.headline}</h2>
                        </div>
                        <section className="section-b-space p-t-0 popular-products">
                            <div className="container">
                                <div className="no-slider row">
                                    {popularProducts.map((product, index) =>
                                        <ProductItem
                                            product={{
                                                imgSrc: get(product, 'images[0].src'),
                                                price: product.variants[0].price,
                                                name: product.title,
                                                id: product.id,
                                                handle: product.handle

                                            }}
                                            classNames="col-xl-4 col-lg-4 col-md-6 col-6"
                                            symbol={symbol}
                                            key={index} />)
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                }
            </>
        )

}

export default PopularProducts