import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Util from "../../../../util"

class PromoBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      promoBar: {},
    }
  }

  componentDidMount = async () => {
    let promoBar = window.promoBar;
    if (process.env.REACT_APP_RESELLER_ID) {
      promoBar = await Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Promo Bar", "promoBar");
    }

    if (promoBar) {
      this.setState({ promoBar });
    }
  }

  render() {

    const { promoBar } = this.state

    return (
      <>
        {promoBar && promoBar.on && <div className="container">
          <div className="promo-bar">
            <div className="row justify-content-center promo-bar-inline-content" style={{ marginRight: 0, marginLeft: 0, width: "100%" }}>
              {promoBar.slots.map((slot, index) => {
                return <React.Fragment key={`${slot.headline}-${index}`}>
                  {
                    slot.headline !== "" &&
                    <div className="col-md-4 service-block" key={`${slot.headline}-${index}`}>
                      <div className="media">
                        <img src={slot.iconUrl ? slot.iconUrl : `${process.env.PUBLIC_URL}/assets/images/computer.svg`} style={{ maxWidth: "50px", maxHeight: "50px", marginRight: "20px" }} />
                        <div className="media-body text-left">
                          <h4 style={{ textTransform: "none" }}>{slot.headline}</h4>
                          <p style={{ textTransform: "none" }}>{slot.headlineSubtitle}</p>
                        </div>
                      </div>
                    </div>
                  }
                </React.Fragment>
              })}
            </div>
            <Carousel className="promo-bar-slider" autoPlay showIndicators={false} showStatus={false} infiniteLoop={true} showThumbs={false}>
              {promoBar.slots.map((slot, index) => {
                return <div key={`${slot.headline}-${index}`}>
                  {
                    slot.headline !== "" &&
                    <div className="col-md-4 d-flex align-items-center justify-content-center pl-0 pr-0 ml-0 mr-0">
                      <img src={slot.iconUrl ? slot.iconUrl : `${process.env.PUBLIC_URL}/assets/images/computer.svg`} style={{ maxWidth: "50px", maxHeight: "50px", marginRight: "20px" }} />
                      <div className="text-left">
                        <h4 style={{ textTransform: "none" }}>{slot.headline}</h4>
                        <p style={{ textTransform: "none" }}>{slot.headlineSubtitle}</p>
                      </div>
                    </div>
                  }
                </div>
              })}
            </Carousel>
          </div>
        </div>
        }
      </>
    )
  }
}

export default PromoBar;