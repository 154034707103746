import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";

import { useSelector } from 'react-redux';

const ContactInformationModal = ({ modalFirstName, modalLastName, modalEmail, open, onClose, onSave, translate }) => {
    const customer = useSelector(state => state.customer);
    const [firstName, setFirstName] = useState(modalFirstName);
    const [lastName, setLastName] = useState(modalLastName);
    const [email, setEmail] = useState(modalEmail);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);

    useEffect(() => {
        setFirstName(modalFirstName);
        setLastName(modalLastName);
        setEmail(modalEmail);
    }, [modalFirstName, modalLastName, modalEmail]);

    const onSubmit = (event) => {
        event.preventDefault();
        if(recaptchaVerified){
            onSave({ firstName, lastName, email });
        }else{
            toast.error('Please verify recaptcha');
            return;
        }
    }
    const onChange = (value)=>{
        setRecaptchaVerified(value);
    }
    return <Modal open={open} center onClose={onClose} styles={{ width: '800px' }}>
        <div className="modal-dialog add-update-modal modal-lg modal-dialog-centered" role="document">
            <div className="modal-content quick-view-modal">
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12 rtl-text">
                            <div className="product-right">
                                <h2>{translate("Edit Contact Information")} </h2>
                                <form onSubmit={onSubmit}
                                 name="contact"
                                >
                                    <div className="border-product">
                                        <div className="form-group">
                                            <label>{translate("First Name")}: <span className="req">*</span></label>
                                            <input type="text"
                                                className="form-control"
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                placeholder={translate("First Name")} required />
                                        </div>
                                        <div className="form-group">
                                            <label>{translate("Last Name")}: <span className="req">*</span></label>
                                            <input type="text"
                                                className="form-control"
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                placeholder={translate("Last Name")} required />
                                        </div>
                                        <div className="form-group">
                                            <label>{translate("Email")}: <span className="req">*</span></label>
                                            <input type="email"
                                                className="form-control"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                placeholder={translate("Email")} required />
                                        </div>
                                    </div>
                                    <ReCAPTCHA
                                        sitekey={customer.recaptchaKey}
                                        onChange={onChange}
                                    />
                                    <div className="product-buttons mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-solid">
                                            Save
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>

};

export default withTranslate(ContactInformationModal);