import React from "react";
import { Link, withRouter } from "react-router-dom";

function MobileSwitcher({ match }) {
  const [show, setShow] = React.useState(false);
  const options = [
    {
      name: "Artwork",
      link: "/account/artwork"
    },
    {
      name: "Orders",
      link: "/account/orders/summary"
    },
    {
      name: "Account",
      link: "/account"
    }
  ];
  const selected = options.find(el =>
    match.path.toLowerCase().match(el.name.toLowerCase())
  ).name;
  return (
    <div className="dropdown mobile-menu text-center">
      <h3 className="mobile-menu text-center" onClick={() => setShow(!show)}>
        {selected} &nbsp; &nbsp;
        <i className={`fa fa-chevron-${show ? "up" : "down"}`}></i>
      </h3>
      <div
        className={`dropdown-menu ${show ? "show" : ""}`}
        aria-labelledby="dropdownMenu2"
      >
        {options.map(el => (
          <Link className="dropdown-item" key={el.link} to={el.link}>
            {el.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
export default withRouter(MobileSwitcher);
