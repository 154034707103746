const CHECKOUT_URLS = [
    {
        "customSubdomain": "www.gfsimpress.com",
        "checkoutUrl": "checkout.gfsimpress.com"
    },
    {
        "customSubdomain": "www.range-digital-checkout-test.com",
        "checkoutUrl": "checkout.rangempls.com"
    },
    {
        "customSubdomain": "ca.gfsimpress.com",
        "checkoutUrl": "checkout.ca.gfsimpress.com"
    },
    {
        "customSubdomain": "fr.gfsimpress.com",
        "checkoutUrl": "checkout.fr.gfsimpress.com"
    }
]

export { CHECKOUT_URLS };