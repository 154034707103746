import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import CartPage from '../components/common/headers/common/cart-header'
import { IconCart } from "../components/common/svg-icons"
import { withTranslate } from 'react-redux-multilingual';

const CartContainer = ({cartList, translate}) => (
     <li  className="onhover-div mobile-cart"><div className="cart-qty-cls">{cartList.length}</div>
        <Link to={`${process.env.PUBLIC_URL}/cart`} title={translate("Cart")}>
            <IconCart />
        </Link>
    </li>
)


function mapStateToProps(state) {
    return {
        cartList: state.cartList.cart,
    }
}

export default connect(mapStateToProps)(withTranslate(CartContainer));
