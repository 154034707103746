import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MobileSwitcher from "./artwork/mobile-switcher";
import { CustomerAction } from "../../../reducers/customer";
import Util from "../../../util";
import { addToCart } from "../../../actions";
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import InfiniteScroll from 'react-infinite-scroller';
var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

const OrderSummary = ({ addToCart, history, translate }) => {
    const { customerAccessToken } = useSelector(state => state.customer);
    const { orders } = useSelector(state => state.customer.loggedInUserDetails);
    const [hasNextPage, setHasNextPage] = useState(true);

    useEffect(() => {
        //CustomerAction.fetchCustomerOrders(customerAccessToken.accessToken,1);
        setTimeout(() => window.analytics.page(), 1000);
    }, []);

    const reorder = (order) => {

        if (order && order.lineItems) {
            let promises = [];

            for (let lineItem of order.lineItems) {
                if (lineItem.variant) promises.push(new Promise((resolve, reject) => {
                    Util.reorderLineItem(lineItem, addToCart)
                        .then(() => resolve())
                        .catch(err => {
                            alert("Couldn't complete reorder")
                            reject(err)
                        })
                }));
            }

            Promise.all(promises).then(() => history.push("/cart"));
        }
    }

    const renderOrder = (order, index) => {
        return (
            <div className="div-row" key={index}>
                <div className="div-cell div-cell-number">
                    <div className="cell-title">Order Number</div>
                    <div className="cell-content"><Link to={`/account/orders/summary/${base64regex.test(order.id) ? atob(order.id) : order.id.split("/")[4]}`}
                        className="text-primary">{order.orderNumber}</Link></div>
                </div>
                <div className="div-cell div-cell-name">
                    <div className="cell-title">Order Name</div>
                    <div className="cell-content">{order.name}</div>
                </div>

                <div className="div-cell div-cell-date">
                    <div className="cell-title">Order Date</div>
                    <div className="cell-content">{
                        new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                            // hour: 'numeric',
                            // minute: 'numeric'
                        }).format(new Date(order.processedAt))}</div>
                </div>
                <div className="div-cell div-cell-status">
                    <div className="cell-title">Status</div>
                    <div className="cell-content">{order.fulfillmentStatus}</div>
                </div>
                <div className="div-cell div-cell-total">
                    <div className="cell-title">Total</div>
                    <div className="cell-content">
                        {order.totalPrice ? Util.getCurrencySymbolFromCode(order.totalPrice.currencyCode) : ''}
                        {order.totalPrice ? Util.renderAmount(order.totalPrice.amount) : "--"}
                    </div>
                </div>
                <div className="div-cell div-cell-action">
                    <div className="cell-title" />
                    <div className="cell-content">
                        <Link to="#" className="text-primary" onClick={() => reorder(order)}>Reorder</Link>
                    </div>
                </div>
            </div>
        )
    };
    async function  handleLoadMore(page) {
        console.log(page);
        let pageinfo = await CustomerAction.fetchCustomerOrders(customerAccessToken.accessToken,page);
        if(pageinfo){
            setHasNextPage(pageinfo.hasNextPage);
        }
        
    }
    const renderOrders = () => {
        if (orders && orders.length > 0) {
            return (
                orders.map((order, index) => (
                    renderOrder(order, index)
                ))
            );
        } else {
            return (
                <p><br />Your Orders will appear here</p>
            )
        }
    };

    return (
        <div className="section-order-summary">

            <MobileSwitcher />
            <div className="order-summary border" style={{ borderRadius: '8px' }}>
                <h3 className="page-heading"> Orders </h3>
                <div className="div-table">
                    <div className="div-row div-header">
                        <div className="div-cell div-cell-number">
                            <div className="cell-content">Order #</div>
                        </div>
                        <div className="div-cell div-cell-name">
                            <div className="cell-content">Order Name</div>
                        </div>
                        <div className="div-cell div-cell-date">
                            <div className="cell-content">Order Date</div>
                        </div>
                        <div className="div-cell div-cell-status">
                            <div className="cell-content">Status</div>
                        </div>
                        <div className="div-cell div-cell-total">
                            <div className="cell-content">{translate("Total")}</div>
                        </div>
                        <div className="div-cell div-cell-action">
                        </div>
                    </div>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMore}
                        hasMore={hasNextPage}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                        {renderOrders()}
                    </InfiniteScroll>
                    
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (_state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, { addToCart })(withTranslate(OrderSummary)));
