import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Slider from 'react-slick';
import '../common/index.scss';
import {connect} from "react-redux";
// import custom Components
import Service from "./common/service";
import NewProduct from "../common/new-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import {addToCart, addToCartUnsafe, addToWishlist} from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import PopularCategories from '../../components/layouts/fashion/components/popular-categories';
import * as storefrontQuerys from '../../graphql/querys';
import {client} from '../../index';
import Util from '../../util.js';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

var productImages = [];
let sliderMainHeight = 0;
let sliderNavHeight = 0;
let updates = 0

class LeftSideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open:false,
            nav1: null,
            nav2: null,
            productData: {},
            productDropdownOptions: {},
            relatedProducts: [],
            item: {variants: []},
            isStaging: window.location.hostname === "staging.readysetprint.com",
            isMobile: window.matchMedia("only screen and (max-width: 1025px)").matches
        };
        this.getProductData = this.getProductData.bind(this);
        this.getProductData(this.props.match.params.id);
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id) {
            this.getProductData(nextProps.match.params.id);
        }
    }

    // Truncate a string to 350 characters or less while preserving full sentences
    myTruncate(str){
        console.log(str.length);
        // If string is already less than 350 characters, just return it as-is
        if (str.length < 350) {
            return str;
        }
        var ret = [];
        var strs = str.split('.');
        while(ret.join('. ').length < 350 && strs.length){
            ret.push(strs.shift());
        }
        // Add a trailing period if description was truncated
        let trailingPeriod = strs.length ? "." : "";
        return ret.join('. ') + trailingPeriod;
    }

    getProductData = (handle) => {
        productImages=[];
        client.send(storefrontQuerys.getProductByHandle(`${handle}`)).then(({ model, data }) => {
            // If no product found by handle, send user to 404 page
            if(!data.productByHandle) {
                this.props.history.push("/pages/404");
                return;
            }
            // Build the specs tab content with list elements
            var matches = data.productByHandle.descriptionHtml.match(/<li>.*<\/li>/gi);
            if(matches){
                data.productByHandle.specs = matches.join('');
            }else{
                data.productByHandle.specs = null;
            }
            // Build the truncated product description by stripping list elements and truncating
            data.productByHandle.desc = data.productByHandle.descriptionHtml.replace(/<li>.*<\/li>/gi,'');
            data.productByHandle.descTruncated = this.myTruncate(data.productByHandle.desc);
            this.setState({productData: {...data.productByHandle, handle}});
            for(let i = 0; i < data.productByHandle.images.edges.length; i++){
                var img = {
                    "image":  {
                        src: data.productByHandle.images.edges[i].node.src
                    }
                };
                productImages.push(img);
            }

            let productDropdownOptions = {};
            for(let i = 0; i < data.productByHandle.variants.edges.length; i++){
                var variant = data.productByHandle.variants.edges[i];
                if(!variant.node.availableForSale){
                    continue;
                }
                for(let j = 0; j < variant.node.selectedOptions.length; j++){
                    var opt = variant.node.selectedOptions[j];
                    if(!Object.keys(productDropdownOptions).includes(opt.name)){
                        productDropdownOptions[opt.name] = [];
                    }
                    if(!productDropdownOptions[opt.name].some(elem =>{return JSON.stringify({label: opt.value, value: opt.value}) === JSON.stringify(elem);})){
                        productDropdownOptions[opt.name].push({label: opt.value, value: opt.value});
                    }
                };
            }
            this.setState({productDropdownOptions, item: { variants: productImages }});
            this.getRelatedProductData();
        });
    }

    getRelatedProductData = () => {
      var array = [];
      let pType = this.state.productData.productType;
      let vendor = this.state.productData.vendor;
      let title = this.state.productData.title;
      client.send(storefrontQuerys.getRelatedProducts(pType,vendor, title)).then(({ model, data }) => {
       if(data) {
            for(let i = 0; i < data.products.edges.length; i++){
            var prod = data.products.edges[i].node;
            if(prod.images.edges.length){
                prod.image = prod.images.edges[0].node.src;
            }
            prod.price = prod.variants.edges[0].node.price;
            array.push(prod);
            }
            this.setState({relatedProducts: array});
        }
      });
    }


    filterClick() {
        document.getElementById("filter").style.left = "-15px";
    }
    backClick() {
        document.getElementById("filter").style.left = "-450px";
    }

    componentDidUpdate = () => {
        setTimeout(() => {
            const slides = $(".product-slick .slick-slide");
            const images = $(".product-slick .slick-slide img");

            const sliderNav =  $(".slider-nav .slick-track");
            const sliderNavSlides = $(".slider-nav .slick-slide");
            const sliderNavImages = $(".slider-nav .slick-slide img");

            if (slides.length > 0) {
                for (let i=0; i < images.length; i++) {
                    let currentImg = $(images[i]);
                    if (currentImg.outerHeight() > sliderMainHeight) {
                        sliderMainHeight = currentImg.outerHeight();
                    }
                }

                for (let slide of slides) {
                    let currentSlide = $(slide);
                    currentSlide.height(sliderMainHeight);
                    currentSlide.css("display", "flex");
                    currentSlide.css("justify-content", "center");
                    currentSlide.css("align-items", "center");
                }
            }

            for (let i=0; i < sliderNavImages.length; i++) {
                let currentImg = $(sliderNavImages[i]);
                let outerHeight = currentImg.outerHeight();
                if (outerHeight > sliderNavHeight) {
                    sliderNavHeight = outerHeight;
                    currentImg.css("border", "none");
                }
            }

            for (let slide of sliderNavSlides) {
                let currentSlide = $(slide);
                currentSlide.height(sliderNavHeight);
                currentSlide.css("display", "flex");
                currentSlide.css("justify-content", "center");
                currentSlide.css("align-items", "center");
                currentSlide.css("border", "1px solid #dddddd");
                currentSlide.css("margin-right", "10px");
            }

            if (sliderNavSlides.length === 2) {
                sliderNav.css("max-width", "50%");
                sliderNav.css("margin", "0");
            }
        }, 1000);
    }


    render(){
        const {symbol, addToCart, addToCartUnsafe, addToWishlist, cartItems, isPunchout,translate } = this.props
        const {item, isStaging} = this.state;
         var products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true
        };
        var productsnav = {
            slidesToShow: 3,
            swipeToSlide:true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };
        let title, description;
        let { specs } = this.state.productData;
        if(specs){
            specs = Util.getSpecsObj(specs);
        }
        if (this.state.productData){
            const productType  = this.state.productData ? this.state.productData.productType : 'Product';
            const productTitle = this.state.productData ? this.state.productData.title : '';
            title = Util.cropSEOTitle(`${productType} | ${productTitle}`);
            description = Util.cropSEODescription(this.state.productData.description);

            if (this.state.productData.metafields && this.state.productData.metafields.edges.length > 0) {
                this.state.productData.metafields.edges.map(({node}) => {
                    if ((node.key === "description" || node.key === "description_tag") && node.value)
                        description = Util.cropSEODescription(node.value);
                    if ((node.key === "title" || node.key === "title_tag") && node.value)
                        title = Util.cropSEOTitle(node.value);
                })
            }
        }


        return (
            <div>
                {/* SEO Support and CC script load starts */}
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <script id="CcIframeApiScript" type="text/javascript" 
                  src={`https://${ isStaging ? "designerqa" : "designer"}.readysetprint.com/apps/design-editor/stable/Resources/Generated/IframeApi.js`} async>
                  </script>
                </Helmet>
                {/*SEO Support and CC Script load End */}

                {/* Hide Breadcrumbs for Punchout2Go users */}
                {isPunchout ? "" : <Breadcrumb  parent={'Product'} title={this.state.productData.title} /> }

                {/*Section Start*/}
                {(item)?
                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                {/* Hide the left sidebar for Punchout2Go users */}
                                {isPunchout  ? "" :
                                    !this.state.isMobile &&
                                        <div className="col-sm-3 collection-filter" id="filter">	
                                            <Service/>
                                            {/*side-bar single product slider start*/}
                                            <NewProduct relatedProducts={this.state.relatedProducts} />
                                            {/*side-bar single product slider end*/}	
                                        </div>
                                }
                                <div className={(isPunchout ? 'col-lg-12' : 'col-lg-9') + " col-sm-12 col-xs-12"}>
                                    <div className="">
                                        <div className="row">
                                            <div className="col-lg-6 product-thumbnail">
                                                <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                                    {item.variants?
                                                    item.variants.map((vari, index) =>
                                                       <div key={index}>
                                                           <ImageZoom image={vari.image} alt={this.state.productData.title}/>
                                                       </div>
                                                    ):
                                                    item.images.map((vari, index) =>
                                                        <div key={index}>
                                                            <ImageZoom image={vari} alt={this.state.productData.title} />
                                                        </div>
                                                    )}
                                                </Slider>
                                                <SmallImages item={item} alt={this.state.productData.title} settings={productsnav} navOne={this.state.nav1} />
                                            </div>
                                            <DetailsWithPrice symbol={symbol} item={item} navOne={this.state.nav1} addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe} addToWishlistClicked={addToWishlist} cartItems={cartItems}  productData={this.state.productData} productDropdownOptions={this.state.productDropdownOptions} isStaging={isStaging} isPunchout={isPunchout}/>
                                        </div>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-lg-4  text-center border-right">
                                            <h3 className="tagline_title">{specs && specs[translate('Material')]}</h3>
                                            <p className="tagline_desc">{translate('Material')}</p>
                                        </div>
                                        <div className="col-lg-4  text-center border-right">
                                            <h3 className="tagline_title">{specs && specs[translate('Print Method')]}</h3>
                                            <p className="tagline_desc">{translate('Print Method')}</p>
                                        </div>
                                        <div className="col-lg-4  text-center">
                                            <h3 className="tagline_title">{specs && specs[translate('Production Time')]}</h3>
                                            <p className="tagline_desc">{translate('Production Time')}</p>
                                        </div>
                                    </div>
                                    <DetailsTopTabs item={item} productData={this.state.productData}/>
                                </div>
                            </div>
                            <hr className="description-sperator" style={{display: 'none'}} />
                            {/* Hide Popular Categories for Punchout2Go users */}
                            {isPunchout  ? "" :
                                <PopularCategories
                                    title="Related Categories"
                                />
                            }
                        </div>
                    </div>
                </section> : ''}
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    return {
        cartItems: state.cartList.cart,
        item: {
            title: "Product Title",
            productType: "Product Type",
            id: productId,
            "variants": productImages
        },
        symbol: state.data.symbol
    }
}

export default withRouter(withTranslate(connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist }) (LeftSideBar)));
