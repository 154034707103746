import {client} from "../index";

export const createCustomerMutation = (email, password, firstName = '', lastName = '') => {
    const credentials = {
        "email": email,
        "password": password,
        "firstName": firstName,
        "lastName": lastName
    };
    const input = client.variable(credentials, 'CustomerCreateInput!');
    const mutation = client.mutation('customerCreate', input, (root) => {
        root.add('customerCreate', { args: { input: credentials } }, (customerCreate) => {
            customerCreate.add('customer', (customer) => {
                customer.add('id');
            });
            customerCreate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
};

export const createCustomerAddress = (accessToken, {firstName, lastName, address1, address2, city, country, phone, province, zip}) => {
    const addr = {firstName, lastName, address1, address2, city, country, phone, province, zip};
    const address = client.variable(addr, 'CustomerAddressCreatePayload!');
    const mutation = client.mutation('customerAddressCreate', address, (root) => {
        root.add('customerAddressCreate', {args : {customerAccessToken: accessToken, address: addr }}, (customerAddressCreate) => {
            customerAddressCreate.add('customerAddress', customerAddress => {
                customerAddress.add('address1');
                customerAddress.add('address2');
                customerAddress.add('city');
                customerAddress.add('country')
            });
            customerAddressCreate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
};

export const deleteCustomerAddress = (accessToken, id) => {
    const mutation = client.mutation('customerAddressDelete', (root) => {
        root.add('customerAddressDelete', {args : {customerAccessToken: accessToken, id: id }}, (customerAddressDelete) => {
            customerAddressDelete.add('deletedCustomerAddressId');
        });
    });
    return mutation;
};

export const updateCustomerAddress = (accessToken, id, {firstName, lastName, address1, address2, city, country, phone, province, zip}) => {
    const addr = {firstName, lastName, address1, address2, city, country, phone, province, zip};
    const mutation = client.mutation('customerAddressUpdate', (root) => {
        root.add('customerAddressUpdate', {args : {customerAccessToken: accessToken, id: id, address: addr }}, (customerAddressUpdate) => {
            customerAddressUpdate.add('customerAddress', customerAddress => {
                customerAddress.add('id');
            });
            customerAddressUpdate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
};

export const updateCustomerDefaultAddress = (accessToken, id) => {
    const mutation = client.mutation('customerDefaultAddressUpdate', (root) => {
        root.add('customerDefaultAddressUpdate', {args : {customerAccessToken: accessToken, addressId: id}}, (customerDefaultAddressUpdate) => {
            customerDefaultAddressUpdate.add('customer', customer => {
                customer.add('id');
            });
            customerDefaultAddressUpdate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
};

export const updateCustomer = (accessToken, customer) => {
    const mutation = client.mutation('customerUpdate', (root) => {
        root.add('customerUpdate', {args : {customerAccessToken: accessToken, customer: customer}}, (customerUpdate) => {
            customerUpdate.add('customer', customer => {
                customer.add('id');
            });
            customerUpdate.add('customerAccessToken', (customerAccessToken) => {
                  customerAccessToken.add('accessToken');
                  customerAccessToken.add('expiresAt');
              }
            );
            customerUpdate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
};
