import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ProductItem = (props) => {
  const classNames = props.classNames;

  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', imageHeight());
  }, []);

  useEffect(() => {
    height===0 && imageHeight();
  });

  const imageHeight = () => {
    const productImage = document.querySelector('.product-img');
    productImage && setHeight(productImage.clientWidth);
  };

  const { product: {name, imgSrc='', handle} } = props;
  // let RatingStars = []
  // for (var i = 0; i < product.rating; i++) {
  //   RatingStars.push(<i className="fa fa-star" key={i}></i>)
  // }
  return (
    <div className={classNames? "product-box" + " " + classNames: "product-box"}>
      <div className="img-wrapper">
        <div className="front">
          <Link to={`${process.env.PUBLIC_URL}/products/${handle}`} >
            <div className="product-img" style={{minHeight: height, display: 'flex', alignItems: 'center'}}>
              <img
                src={imgSrc || '/assets/images/product/1.jpg'}
                className="img-fluid"
                alt={name} />
            </div>
          </Link>
        </div>
      </div>
      <div className="product-detail">
        <div>
          <Link to={`${process.env.PUBLIC_URL}/products/${handle}`}>
            <h6 style={{ color: 'black', fontSize: '16px',marginBottom: "0px", textAlign: "center" }}>{name}</h6>
          </Link>
          {/*<h4 style={{textTransform: "none", fontSize: '14px'}}>starting at{" "}<span className="item-price">{symbol}{price - (price * discount / 100)}</span>*/}
          {/*  <span style={{ fontSize: '12px', color: '#777777' }}>&#47;unit</span>*/}
          {/*</h4>*/}
        </div>
      </div>
    </div>
  )
};

export default ProductItem;
