import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router';

import Breadcrumb from "../common/breadcrumb";
import {client} from "../../index";
import * as storefrontQuerys from "../../graphql/querys";
import {Helmet} from "react-helmet";
import Util from "../../util";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from 'react-redux';

const page = ({translate}) => {
    const customer = useSelector(state => state.customer);
    const params = useParams();

    const [title, setTitle] = useState(undefined);
    const [body, setBody] = useState(undefined);
    const [bodySummary] = useState(undefined);
    const [isContactUsPage, setIsContactUsPage] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let {handle} = params;
        const resellerId = Util.getReseller();
        if (resellerId !== "" && !handle.toLowerCase().includes(`${resellerId.toLowerCase()}-`)) {
            handle = `${resellerId}-${handle}`;
        }
        client.send(storefrontQuerys.getPageByHandleQuery(handle)).then(({ model }) => {
            if (model.pageByHandle) {
                const {title, body} = model.pageByHandle;
                setTitle(title);
                setBody(body);
                if (handle.includes("contact-us")) {
                    setIsContactUsPage(true);
                } else {
                    setIsContactUsPage(false);
                }
            } else {
                history.push("/404");
                return;
            }
        }).catch(e => {
            console.log(e);
        })
    }, [params]);

    useEffect(() => {
        setTimeout(() => window.analytics.page(), 1000);
    });
    const onChange = (value)=>{
        setRecaptchaVerified(value);
    }
    const description = bodySummary ? Util.cropSEODescription(bodySummary) : Util.cropSEODescription(Util.stripHtmlTags(body));

    const onSubmit = (e) => {
        if(!recaptchaVerified){
            toast.error('Please verify recaptcha');
            return;
        }
        let data = { "form-name": "contact-form", ...e };

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode(data)
        })
            .then(() => {
                toast.success(translate("form_submitted_correctly"));
                clearForm();
            });
    };

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    };

    const clearForm = () => {
        document.getElementById("contact-form").reset();
    };

    return (
        <div>
            {/*SEO Support*/}
            <Helmet>
                <title>{title ? Util.cropSEOTitle(title) : ''}</title>
                <meta name="description" content={description} />
            </Helmet>
            {/*SEO Support End */}

            <Breadcrumb title={title} />
            {/*about section*/}
            <section className={`custom-page section-b-space`}>
                <div className={`container ${isContactUsPage ? "contact-us" : ""}`}>
                    <div className={`${isContactUsPage ? "col-sm-6" : ""}`}>
                        <h2>{title}</h2>
                        <div className='text-left' dangerouslySetInnerHTML={{ __html: body }} />
                    </div>
                    {isContactUsPage &&
                        <form className="col-sm-6" name="contact-form" onSubmit={handleSubmit(onSubmit)} id="contact-form">
                            <input type="hidden" name="form-name" value="contact-form" />
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label htmlFor="name">{translate("Name")}: <span className="req">*</span></label>
                                    <input type="text" name="name" className="form-control round-input"
                                        placeholder={translate("Name")} required="" ref={register({ required: true })} />
                                    {errors && errors.name && <p style={{ color: "red" }}>{translate("Name is required.")}</p>}
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="name">{translate("Email")}: <span className="req">*</span></label>
                                    <input type="email" name="email" className="form-control round-input"
                                        placeholder={translate("Email")} required="" ref={register({ required: true })} />
                                    {errors && errors.email && <p style={{ color: "red" }}>{translate("Email is required.")}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label htmlFor="name">{translate("Phone number")}:</label>
                                    <input type="text" name="phoneNumber" className="form-control round-input"
                                        placeholder={translate("Phone number")} ref={register} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-12">
                                    <label htmlFor="name">{translate("Message")}: <span className="req">*</span></label>
                                    <textarea name="message" className="form-control round-input" rows="5"
                                        style={{ height: "auto" }}
                                        placeholder={translate("Message")} required="" ref={register({ required: true })}></textarea>
                                    {errors && errors.message && <p style={{ color: "red" }}>{translate("Message is required.")}</p>}
                                </div>
                            </div>
                            <ReCAPTCHA
                                sitekey={customer.recaptchaKey}
                                onChange={onChange}
                            />
                            <div className="row mt-3">
                                <div className="col-sm-6 d-flex justify-content-between">
                                    <button className="btn btn-primary btn-block mr-3 btn-lg" type="submit" style={{borderRadius: 8}}>{translate("Send")}</button>
                                    <button className="btn btn-light btn-lg" type="button" onClick={clearForm} style={{borderRadius: 8}}>{translate("Clear")}</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </section>
        </div>
    )
};

export default withTranslate(page);
