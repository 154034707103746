import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { SlideUpDown } from "../../../services/script"
import LogoImage from "../headers/common/logo"
import { SlideToggle } from "react-slide-toggle";
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import Util from "../../../util"

const CompanyInfo = ({ isMobile, logoName, footer }) => {

    const [logoSrc, setLogoSrc] = useState(logoName)

    useEffect(() => {
        var customLogoFile = window.logoUrl;
        if (customLogoFile) {
            const logoSrc = customLogoFile;
            setLogoSrc(logoSrc)
        }
    })

    return (
        <div
            className={classnames({
                "col-md-3 ": true,
                "footer-mobile-disabled": isMobile,
            })}
        >
            <div
                className={classnames({
                    "footer-mobile-title": true,
                })}>
            </div>
            <div className="footer-contant">
                <div className="footer-logo">
                    <LogoImage logo={logoSrc} />
                </div>
                <p
                    className="logo-description">
                    {footer.companyDescription}</p>
                <div className="footer-social">
                    <ul>
                        {footer.facebook &&
                            <li>
                                <a target="_blank" title="Facebook" href={`https://facebook.com/${footer.facebook}`} ><i className="fa fa-facebook"></i></a>
                            </li>
                        }
                        {footer.twitter &&
                            <li>
                                <a target="_blank" title="Twitter" href={`https://twitter.com/${footer.twitter}`}><i className="fa fa-twitter"></i></a>
                            </li>
                        }
                        {footer.instagram &&
                            <li>
                                <a target="_blank" title="Instagram" href={`https://instagram.com/${footer.instagram}`}><i className="fa fa-instagram"></i></a>
                            </li>
                        }
                        {footer.linkedIn &&
                            <li>
                                <a target="_blank" title="Linkedin" href={`https://linkedin.com/${footer.linkedIn}`} ><i className="fa fa-linkedin"></i></a>
                            </li>
                        }
                        {footer.pinterest &&
                            <li>
                                <a target="_blank" title="Pinterest" href={`https://pinterest.com/${footer.pinterest}`} ><i className="fa fa-pinterest"></i></a>
                            </li>
                        }
                        {footer.youtube &&
                            <li>
                                <a target="_blank" title="YouTube" href={`https://youtube.com/${footer.youtube}`} ><i className="fa fa-youtube"></i></a>
                            </li>
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
};


const Footer =  withTranslate(({ isMobile, logoName, translate }) => {

    const [footer, setFooter] = useState({})
    const [footerMenu1, setFooterMenu1] = useState({ items: [] })
    const [footerMenu2, setFooterMenu2] = useState({ items: [] })

    const [footerMenu1HasLinks, setFooterMenu1HasLinks] = useState(false);
    const [footerMenu2HasLinks, setFooterMenu2HasLinks] = useState(false);

    useEffect(() => {
        var footer = window.Footer;
        if (process.env.REACT_APP_RESELLER_ID) {
            Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Footer", "Footer").then(result=>{
                footer = result;
            });
        }
        if (footer) {
            setFooter(footer);
        }
    }, [])

    useEffect(() => {
        let menu = window.Menu;
        if (process.env.REACT_APP_RESELLER_ID) {
            Util.fetchAndParseNetlifySnippet(process.env.REACT_APP_RESELLER_ID, "Menu", "Menu").then(result => {
                menu = result;
            });
        }

        if (menu) {

            const f1 = menu.find(m => m.id === "footer-menu1")
            const f2 = menu.find(m => m.id === "footer-menu2")

            if (f1) {
                setFooterMenu1(f1);
                if (f1.items.findIndex(i => i.hasOwnProperty("link")) > -1) setFooterMenu1HasLinks(true);
            }
            if (f2) {
                setFooterMenu2(f2);
                if (f2.items.findIndex(i => i.hasOwnProperty("link")) > -1) setFooterMenu2HasLinks(true);
            }
        }
    }, [])

    return (<footer
        className={classnames({
            "footer-mobile-disabled": !isMobile,
            "footer-mobile-enabled": isMobile
        })}
    >
        <section
            className={classnames({
                "light-layout": !isMobile,
                "footer-dark": isMobile,
                "pb-2": true,
                "pt-5": !isMobile,
                "pt-3": isMobile
            })}
        >
            <div className="container">
                <div className="row footer-theme">
                    <CompanyInfo
                        isMobile={isMobile}
                        logoName={logoName}
                        footer={footer}
                    />
                    <div className="col-md-3"></div>

                    {footerMenu1HasLinks && <div className="col-md-3">
                        <SlideToggle key="footerMenu1">
                            {({ onToggle, setCollapsibleElement, toggleState }) => (
                                <div className={classnames({
                                    "footer-title": isMobile,
                                    "collection-collapse-block sub-title": true
                                })}>
                                    <h4
                                        className={classnames({
                                            "collapse-block-title": true,
                                            "collapse-block-title-expanded":
                                                toggleState.search("EXPAND") >= 0 || !isMobile,
                                            "collapse-block-title-collapsed":
                                                toggleState.search("COLLAP") >= 0
                                        })}
                                        onClick={(ev) => {if (isMobile) {
                                            onToggle(ev);
                                        }}}
                                    >
                                        {" "}
                                        {footerMenu1.title}{" "}
                                    </h4>
                                    <div
                                        className="collection-collapse-block-content"
                                        ref={setCollapsibleElement}
                                    >
                                        <ul className="block-menu-content">
                                            {footerMenu1.items.map((item, index) => {
                                                return item.link ? <li className="left-account-sub-menu" key={`${item.title}-${index}`}>
                                                    {item.link && item.link.includes("http") ? <a href={item.link}  key={`${item.title}-${index}`}>{item.title}</a> :
                                                        <Link to={item.link}>
                                                            {item.title}</Link>
                                                    }
                                                </li> : <></>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>
                    </div>
                    }

                    {footerMenu2HasLinks && <div className="col-md-3">
                        <SlideToggle key="footerMenu2">
                            {({ onToggle, setCollapsibleElement, toggleState }) => (
                                <div className={classnames({
                                    "footer-title": isMobile,
                                    "collection-collapse-block sub-title": true
                                })}>
                                    <h4
                                        className={classnames({
                                            "collapse-block-title": true,
                                            "collapse-block-title-expanded":
                                                toggleState.search("EXPAND") >= 0,
                                            "collapse-block-title-collapsed":
                                                toggleState.search("COLLAP") >= 0
                                        })}
                                        onClick={(ev) => {if (isMobile) {
                                            onToggle(ev);
                                        }}}
                                    >
                                        {" "}
                                        {footerMenu2.title}{" "}
                                    </h4>
                                    <div
                                        className="collection-collapse-block-content"
                                        ref={setCollapsibleElement}
                                    >
                                        <ul className="block-menu-content">
                                            {footerMenu2.items.map((item, index) => {
                                                return item.link ? <li className="left-account-sub-menu" key={`${item.title}-${index}`}>
                                                    {item.link && item.link.includes("http") ? <a href={item.link}  key={`${item.title}-${index}`}>{item.title}</a> :
                                                        <Link to={item.link} key={`${item.title}-${index}`}>
                                                            {(item.title.toLowerCase() === "terms and conditions" || item.title.toLowerCase() === "privacy policy") ? translate(item.title) : item.title}</Link>
                                                    }
                                                </li> : <></>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>
                    </div>
                    }
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-end">
                            {isMobile && <CompanyInfo
                                isMobile={!isMobile}
                                logoName={logoName}
                                footer={footer}
                            />}
                            <p className="pt-2 footer-copyright"><i className="fa fa-copyright" title="Copyright"></i>{footer.legalCopy}</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </footer >
    )
})

class FooterOne extends Component {

    componentDidMount() {
        SlideUpDown('footer-title');
    }
    render() {
        return (
            <div>
                <Footer isMobile={true} logoName={this.props.logoName} />
                <Footer isMobile={false} logoName={this.props.logoName} />
            </div>
        )
    }
}

export default FooterOne;
