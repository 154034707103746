import * as types from '../constants/ActionTypes';
import * as querys from '../graphql/querys';
import {client} from '../index';
import {toast} from 'react-toastify';
/* * * Products * * */
export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});
export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
});

export const receivePopularProducts = products => ({
    type: types.RECEIVE_POPULAR_PRODUCTS,
    products
});

export const getAllShopDetails = (reseller_id = null) => dispatch => {
    dispatch(fetchProductsBegin());
    const query = querys.getAllShopDetailsQuery(reseller_id);
    client.send(query).then(({model}) => {
        if (model && model.products && model.collections) {
            dispatch(receiveProducts(model.products));
            dispatch(updateCategoriesList(model.collections));
        }
    });
}

export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
});


/* * * Customers * * */
// export const getCustomer = () => dispatch => {
//     const accessTokenMutation = graphql.getCustomerAccessTokenMutation('test1@rangempls.com', 'Password01'); // These are just test values; they will eventually be captured and made available during user authentication
//     client.send(accessTokenMutation).then(({model, data}) => { // Get customer access token
//         console.log(data);
//         if (data.customerAccessTokenCreate.customerAccessToken !== null) {
//             const getCustomerQuery = graphql.getCustomerQuery(data.customerAccessTokenCreate.customerAccessToken.accessToken);
//             client.send(getCustomerQuery).then(({model, data}) => { // Use customer access token to get customer info
//                 console.log(data.customer);
//                 dispatch(fetchSingleCustomer());
//             });
//         } else {
//             console.log("An invalid email + address combination was provided.");
//         }
//     });
// };
//
// export const fetchSingleCustomer = customerId => ({
//     type: types.FETCH_SINGLE_CUSTOMER,
//     customerId
// });


/* * * Cart * * */
export const addToCart = (product,qty) => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Produit ajouté au panier" : "Item Added to Cart";
    toast.success(text);
    return new Promise((resolve) => {
      dispatch(addToCartUnsafe(product, qty));
      resolve()
    });
}
export const updateCart = (cart) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.UPDATE_CART,
            cart
        });
        resolve()
    });
}
export const addToCartAndRemoveWishlist = (product,qty) => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Produit ajouté au panier" : "Item Added to Cart";
    toast.success(text);
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
}
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});
export const removeFromCart = product => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Produit retiré du panier" : "Item Removed from Cart";
    toast.success(text);
    return new Promise((resolve) => {
        dispatch({
            type: types.REMOVE_FROM_CART,
            product
        });
        resolve();
    });
}

export const clearCart = () => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.CLEAR_CART
        });
        resolve()
    });
}
export const incrementQty = (product,qty) => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Produit ajouté au panier" : "Item Added to Cart";
    toast.success(text);
    dispatch(addToCartUnsafe(product, qty))

}
export const decrementQty = productId => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Quantité décrémentée du produit" : "Item Decrement Qty to Cart";
    toast.success(text);

    dispatch({
    type: types.DECREMENT_QTY,
    productId})
    }

export const getCheckoutUrl = (checkoutFields, customerToken, fn) => () => {
    const checkoutCreateMutation = querys.getCheckoutCreateMutation(checkoutFields);
    client.send(checkoutCreateMutation).then(({data}) => {
        if(typeof data != "undefined" && typeof data.checkoutCreate != "undefined") {
            const errors = data.checkoutCreate.checkoutUserErrors;
            if (errors && errors.length > 0) {
                let promises = [];
                for(const e of errors) {
                    switch(e.code) {
                        // There are deleted products in the cart.
                        case "INVALID":
                            for (const l  of checkoutFields.lineItems) {
                                const productVariantQuery = querys.getProductVariantByIdQuery(l.variantId);
                                const p = new Promise((resolve) => {
                                    client.send(productVariantQuery).then(({ data }) => {
                                        if (!data.node) {
                                            resolve({ code: e.code, variantId: l.variantId });
                                        } else {
                                            resolve(null);
                                        }
                                    });
                                });
                                promises.push(p);
                            }
                            break;
                        default: 
                            break;
                    }
                    console.log(e.message);
                }
                Promise.all(promises).then(values => {
                    if (values) {
                        values = values.filter(v => v !== null);
                        if(typeof(fn) == 'function'){
                            fn({errors: values});
                        }
                    } else {
                        if(typeof(fn) == 'function'){
                            fn({errors: []});
                        }
                    }
                });
            } else {
                if(data.checkoutCreate.checkout && typeof data.checkoutCreate.checkout.webUrl != "undefined"){
                    if(customerToken){
                        const checkoutCustomerAssociateMutation = querys.getCheckoutCustomerAssociateMutation(data.checkoutCreate.checkout.id, customerToken);
                        client.send(checkoutCustomerAssociateMutation).then((cca) => {
                            if(typeof cca.data == "undefined" || typeof cca.data.checkoutCustomerAssociateV2 == "undefined" || typeof cca.data.checkoutCustomerAssociateV2.checkout.id == "undefined"){
                                console.log('checkout customer association not done. fetched data follows:');
                                console.log(cca.data);
                            }
                            if(typeof(fn) == 'function'){
                                fn(data.checkoutCreate.checkout);
                            }
                        });
                    }else{
                        if(typeof(fn) == 'function'){
                            fn(data.checkoutCreate.checkout);
                        }
                    }
                }else{
                    if(typeof(fn) == 'function'){
                        fn(false);
                    }
                    console.log('checkout weburl not found. fetched data follows:');
                    console.log(data);
                }
            }
        } else {
            if(typeof(fn) == 'function'){
                fn(false);
            }
            console.log('checkout weburl not found. fetched data follows:');
            console.log(data);
        }
    });
}

/* * * Wishlist * * */
export const addToWishlist = (product) => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "" : "Item Added to Wishlist";
    toast.success(text);
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Produit ajouté à la liste de souhaits" : "Item Removed from Wishlist";
    toast.success(text);
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    });
}


/* * * Compare Products * * */
export const addToCompare = (product) => (dispatch) => {
    const locale = localStorage.getItem("locale");
    const text = locale === "fr" ? "Produit ajouté au comparateur" : "Item Added to Compare";
    toast.success(text);
    dispatch(addToCompareUnsafe(product))
}
export const addToCompareUnsafe= (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


/* * * Filters * * */
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


/* * * Currency * * */
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});

/* * * Category * * */
export const updateCategoryAndProducts = ({products, category}) => ({
    type: types.UPDATE_CATEGORY_AND_PRODUCTS,
    payload: {products, category}
});

export const updateCategoryProducts = (products) =>({
    type: types.UPDATE_CATEGORY_PRODUCTS,
    payload: {products}
})

export const updateCategoriesList = (categories) => ({
    type: types.UPDATE_CATEGORIES_LIST,
    payload: {categories}
})

/* * * orderNotes * * */
export const updateOrdernotes = (notes) => (dispatch) => {
    dispatch(updateNotesActionCreator(notes))
}
export const updateNotesActionCreator = (notes) => ({
    type: types.UPDATE_ORDER_NOTES,
    notes
});

export const updateShowTextBox = (value) => (dispatch) => {
    dispatch(updateShowTextBoxActionCreator(value))
}
export const updateShowTextBoxActionCreator = (value) => ({
    type: types.UPDATE_SHOW_TEXTBOX,
    value
});

export const updateShowReturnAddress = (value) => (dispatch) => {
    dispatch(updateShowReturnAddressActionCreator(value))
}
export const updateShowReturnAddressActionCreator = (value) => ({
    type: types.UPDATE_SHOW_RETURN_ADDRESS,
    value
});

export const updateReturnAddress = (value) => (dispatch) => {
    dispatch(updateReturnAddressActionCreator(value))
}
export const updateReturnAddressActionCreator = (address) => ({
    type: types.UPDATE_RETURN_ADDRESS,
    address
});
