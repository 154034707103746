var module = {
    fr: {
        locale: 'fr',
        messages: {
            "Account Information": "Information Du Compte",
            "Menu": "Menu",
            "Register": "Inscription",
            "Cart": "Panier",
            "Cart List": "Liste du Panier",
            "Search Products": "Recherche de produits",
            "CLOSE": "FERMER",
            "MY ACCOUNT": "MON COMPTE",
            "My Account": "Mon Compte",
            "Login / Signup": "Connexion / Inscription",
            "Logout": "Déconnexion",
            "HOME": "ACCUEIL",
            "create account": "création d'un compte",
            "First name": "Prénom",
            "Last Name": "Nom de famille",
            "Email": "Adresse courriel",
            "Password": "Mot de passe",
            "Enter your password": "Mot de passe",
            "Confirm your password": "Confirmation du mot de passe",
            "Already have an account?": "Vous avez déjà un compte?",
            "Login here!": "Connectez-vous ici!",
            "Enter your First name": "Prénom requis",
            "Enter your Last Name": "Nom de famille requis",
            "Enter your e-mail": "Adresse courriel requise",
            "Enter Password": "Mot de passe requis",
            "Enter password again": "Entrer à nouveau le mot de passe",
            "Enter a new password": "Entrer un nouveau mot de passe",
            "Confirm Password is required": "Confirmation du mot de passe requise",
            "Password and Confirm Password do not match": "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
            "Email has already been taken": "Cette adresse courriel est déjà utilisée",
            "login": "connexion",
            "New Customer": "Nouveau client",
            "Remember Me": "Enregistrer mes identifiants",
            "Forgot your password?": "Mot de passe oublié?",
            "Create an Account": "Création d’un compte",
            "registration_description": "Créez un compte gratuitement pour notre magasin. L’inscription est rapide et facile. Le compte vous permet de commander dans notre boutique. Pour commencer à magasiner, cliquez sur « Création d’un compte ».",
            "Invalid Credentials": "Identifiants non valides",
            "Please Enter an Email and Password": "Veuillez entrer un e-mail et un mot de passe",
            "Please Enter a Valid Email" : "Veuillez entrer un e-mail valide",
            "Please Enter a Password" : "Veuillez entrer un mot de passe",
            "Logged in successfully": "Connexion réussie",
            "submit": "soumettre",
            "Please enter an email": "Adresse courriel requise",
            "reset_password_error": "Une erreur s’est produite lors de la réinitialisation de votre mot de passe. Veuillez vérifier votre adresse courriel et réessayer.",
            "reset_password_check_email": "Consultez vos courriels pour un lien permettant de récupérer votre mot de passe.",
            "404 Page": "Erreur 404",
            "404_error_title": "Désolé, mais nous n’arrivons pas à trouver ce que vous cherchez.",
            "404_error_subtitle": "L’adresse URL ne semble pas exister sur ce site.",
            "back to home": "retour à l'accueil",
            "Showing # Products": "{count} produits",
            "Sort products": "Trier les produits",
            "High to Low": "Prix élevé à bas",
            "Low to High": "Prix bas à élevé",
            "Newest Items": "Articles les plus récents",
            "Sort By Name Asc": "A à Z",
            "Sort By Name Desc": "Z à A",
            "PRODUCT": "PRODUIT",
            "similar products": "produits similaires",
            "Order more, save more!": "Commandez plus, économisez plus!",
            "$## /unit": "{value} $/unité",
            "DESIGN NOW": "PERSONNALISER",
            "description": "déscription",
            "specs": "spécifications",
            "See the picture in the images section to see an example":"Voir l'image dans la section images pour voir un exemple",
            "Print Method": "Procédé d’impression",
            "Production Time": "Temps de fabrication",
            "ADD TO CART": "AJOUTER AU PANIER",
            "Add to cart": "Ajouter au panier",
            "Item Added to Cart": "Article ajouté au panier",
            "White Vinyl": "Vinyle blanc",
            "Removable White Vinyl": "Vinyle blanc amovible",
            "Outdoor durable with standard permanent adhesive": "utilisation extérieure longue durée et adhésif permanent standard",
            "Outdoor durable with low-tack adhesive": "utilisation extérieure longue durée et adhésif peu collant",
            "Size": "Taille",
            "Quantity": "Quantité",
            "Review Design": "Révision de l'image",
            "SAVE PROJECT": "ENREGISTRER LE PROJET",
            "Project Name": "nom du projet",
            "CANCEL": "ANNULER",
            "Save Artwork": "Enregistrer l'illustration",
            "DOWNLOAD PROOF": "TÉLÉCHARGER LA PREUVE",
            "Caution Zone": "Marge De Sécurité",
            "Cut": "Coupe",
            "Bleed": "Fond perdu",
            "approve_artwork_printing": "En sélectionnant « Ajouter au panier », vous approuvez votre illustration pour l’impression.",
            "GO BACK TO DESIGN": "RETOUR À LA CONCEPTION",
            "Your Cart": "Votre Panier",
            "Total": "Total",
            "Subtotal": "Sous-total",
            "$##": "{amount} $",
            "Continue Shopping": "Continuer à magasiner",
            "Contact Us": "Contact",
            "help_with_questions": "Notre équipe se fera un plaisir de répondre à vos questions. Remplissez le formulaire et nous vous contacterons dans les plus brefs délais.",
            "Phone number": "Numéro de téléphone",
            "Message": "Message",
            "Send": "Envoyer",
            "Clear": "Effacer",
            "Form submitted correctly.": "Le formulaire a été soumis correctement.",
            "Name is required": "Nom requis",
            "form_submitted_correctly": "Le formulaire a été soumis avec succès",
            "Email is required": "Adresse courriel requise",
            "Message is required": "Message requis",
            "or": "ou",
            "Account": "Compte",
            "CREATE ACCOUNT": "CREATION D'UN COMPTE",
            "Contact Information": "Coordonnées",
            "Address Book": "Carnet d'addresses",
            "Add New Address": "Ajouter une nouvelle addresse",
            "Default Shipping Address": "Adresse de livraison par défaut",
            "Tax Exempt Status": "Statut d’exonération fiscale",
            "Status": "Status",
            "Fill out tax form": "Remplir le formulaire de déclaration fiscale",
            "Edit": "Modifier",
            "order history": "historique de commande",
            "Orders": "Commandes",
            "Artwork": "Illustration",
            "Address Line 1": "Ligne d’adresse 1",
            "Address Line 2": "Ligne d’adresse 2",
            "City": "Ville",
            "Zip Code": "Code postal",
            "Add address": "Ajouter une adresse",
            "Change Password": "Changer le mot de passe",
            "Address deleted": "Adresse supprimée",
            "Delete": "Annuler",
            "non-exempt": "non exonéré",
            "Edit Contact Information": "Modifier les coordonnées",
            "Set as Default": "Définir comme valeur par défaut",
            "Update address": "Mettre à jour l’adresse",
            "Certificate of Exemption": "Certificat d’exonération",
            "File is required and must be a PDF": "Le fichier est obligatoire et doit être en format PDF.",
            "A valid phone number is required": "Un numéro de téléphone valide est obligatoire.",
            "Country is required": "Le pays est obligatoire.",
            "A valid zip is required": "Un code postal valide est obligatoire.",
            "State is required": "Une province est obligatoire.",
            "City is required": "La ville est obligatoire.",
            "Address is required": "L’adresse est obligatoire.",
            "ein_error": "Un numéro d’identification de l’employeur (EIN) valide est obligatoire. Format : xx-xxxxxxx",
            "Company name": "Nom de l’entreprise",
            "State": "Province",
            "Zip": "Code postal",
            "Country": "Pays",
            "Tax form": "Formulaire de déclaration fiscale",
            "Tax form submitted": "Formulaire de déclaration fiscale soumis",
            "Submitting tax form": "Soumission du formulaire de déclaration fiscale...",
            "Shipping Address": "Adresse de livraison",
            "User Name": "Nom d’utilisateur",
            "Sign Out": "Se déconnecter",
            "details": "détails",
            "Material": "Matériau",
            "Need tax exemption?": "Besoin d’une exonération fiscale?",
            "Request tax exemption": "Demander une exonération fiscale",
            "Contact information updated correctly!": "Les coordonnées ont été mises à jour correctement.",
            "Your Cart is Empty": "Votre Panier est Vide",
            "artwork_error": "There appears to be an issue with the artwork for this product. Please complete your purchase and we will contact you with next steps.",
            "Sides": "Côtés",
            "sides": "côtés",
            "quantity_discount": "Vous recevez un rabais sur ce produit en fonction des articles similaires dans votre panier!",
            "overlamination": "laminage",
            "material": "matériau",
            "taille": "taille",
            "matériau": "matériau",
            "côtés": "côtés",
            "pack size & type": "Type et format d'emballage",
            "unwind direction": "Direction dérouleur",
            "type et format d’emballage": "Type et format d'emballage",
            "sens du déroulement": "Sens du déroulement",
            "Terms and Conditions": "Termes et Conditions",
            "Privacy Policy": "Politique de confidentialité",
            "Name": "Nom",
            "checkout": "paiement",
            "gfs_customer_number": "Rep des ventes GFS ou #client"
        }
    },
    en: {
        locale: 'en_US',
        messages: {
            "Account Information": "Account Information",
            "Menu": "Menu",
            "Register": "Register",
            "Cart": "Cart",
            "Cart List": "Cart List",
            "Search Products": "Search Products",
            "CLOSE": "CLOSE",
            "MY ACCOUNT": "MY ACCOUNT",
            "My Account": "My Account",
            "Login / Signup": "Login / Signup",
            "Logout": "Logout",
            "HOME": "HOME",
            "create account": "create account",
            "CREATE ACCOUNT": "CREATE ACCOUNT",
            "First name": "First Name",
            "Last Name": "Last Name",
            "Email": "Email",
            "Password": "Password",
            "Enter your password": "Enter your password",
            "Confirm your password": "Confirm your password",
            "Already have an account?": "Already have an account?",
            "Login here!": "Login here!",
            "Enter your First name": "Enter your First Name",
            "Enter your Last Name": "Enter your Last Name",
            "Enter your e-mail": "Enter your e-mail",
            "Enter Password": "Enter Password",
            "Enter password again": "Enter password again",
            "Enter a new password": "Enter a new password",
            "Confirm Password is required": "Confirm Password is required",
            "Password and Confirm Password do not match": "Password and Confirm Password do not match",
            "Email has already been taken": "Email has already been taken",
            "login": "login",
            "New Customer": "New Customer",
            "Remember Me": "Remember Me",
            "Forgot your password?": "Forgot your password?",
            "Create an Account": "Create an Account",
            "registration_description": "Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.",
            "Invalid Credentials": "Invalid Credentials",
            "Please Enter an Email and Password": "Please Enter an Email and Password",
            "Please Enter a Valid Email" : "Please Enter a Valid Email",
            "Please Enter a Password" : "Please Enter a Password",
            "Logged in successfully": "Logged in successfully",
            "submit": "submit",
            "Please enter an email": "Please enter an email",
            "reset_password_error": "There was an error resetting your password. Please check your email and try again",
            "reset_password_check_email": "Check your email for a link to recover your password",
            "404 Page": "404 Page",
            "404_error_title": "Sorry, but we can't seem to find what you're looking for.",
            "404_error_subtitle": "You've landed on a URL that doesn't seem to exist on this site",
            "back to home": "back to home",
            "Showing # Products": "Showing {count} Products",
            "Sort products": "Sort products",
            "High to Low": "High to Low",
            "Low to High": "Low to High",
            "Newest Items": "Newest Items",
            "Sort By Name Asc": "Sort By Name Asc",
            "Sort By Name Desc": "Sort By Name Desc",
            "PRODUCT": "PRODUCT",
            "similar products": "similar products",
            "Order more, save more!": "Order more, save more!",
            "$## /unit": "${value} /unit",
            "DESIGN NOW": "DESIGN NOW",
            "description": "description",
            "specs": "specs",
            "See the picture in the images section to see an example": "See the picture in the images section to see an example",
            "Print Method": "Print Method",
            "Production Time": "Production Time",
            "ADD TO CART": "ADD TO CART",
            "Add to cart": "Add to cart",
            "Item Added to Cart": "Item Added to Cart",
            "White Vinyl": "White Vinyl",
            "Removable White Vinyl": "Removable White Vinyl",
            "Outdoor durable with standard permanent adhesive": "Outdoor durable with standard permanent adhesive",
            "Outdoor durable with low-tack adhesive": "Outdoor durable with low-tack adhesive",
            "Size": "Size",
            "Quantity": "Quantity",
            "Review Design": "Review Design",
            "SAVE PROJECT": "SAVE PROJECT",
            "Project Name": "Project Name",
            "CANCEL": "CANCEL",
            "Save Artwork": "Save Artwork",
            "DOWNLOAD PROOF": "DOWNLOAD PROOF",
            "Caution Zone": "Caution Zone",
            "Cut": "Cut",
            "Bleed": "Bleed",
            "approve_artwork_printing": "By selecting add to cart, you approve your artwork for printing.",
            "GO BACK TO DESIGN": "GO BACK TO DESIGN",
            "Your Cart": "Your Cart",
            "Total": "Total",
            "Subtotal": "Subtotal",
            "$##": "${amount}",
            "Continue Shopping": "Continue Shopping",
            "Contact Us": "Contact Us",
            "help_with_questions": "Our team is happy to help with your questions. Fill out the form and we'll be in touch as soon as possible.",
            "Phone number": "Phone Number",
            "Message": "Message",
            "Send": "Send",
            "Clear": "Clear",
            "Form submitted correctly.": "Form submitted correctly.",
            "Name is required": "Name is required.",
            "form_submitted_correctly" : "Form submitted correctly.",
            "Email is required": "Email is required",
            "Message is required.": "Message is required.",
            "or": "or",
            "First name is required": "First name is required",
            "Last name is required": "Last name is required",
            "Account": "Account",
            "ACCOUNT": "ACCOUNT",
            "Contact Information": "Contact Information",
            "Address Book": "Address Book",
            "Add New Address": "Add New Address",
            "Default Shipping Address": "Default Shipping Address",
            "Tax Exempt Status": "Tax Exempt Status",
            "Status": "Status",
            "Fill out tax form": "Fill out tax form",
            "Edit": "Edit",
            "order history": "order history",
            "Orders": "Orders",
            "Artwork": "Artwork",
            "Address Line 1": "Address Line 1",
            "Address Line 2": "Address Line 2",
            "City": "City",
            "Zip Code": "Zip Code",
            "Add address": "Add address",
            "Change Password": "Change Password",
            "Address deleted": "Address deleted",
            "Delete": "Delete",
            "non-exempt": "non-exempt",
            "Edit Contact Information": "Edit Contact Information",
            "Set as Default": "Set as Default",
            "Update address": "Update address",
            "Certificate of Exemption": "Certificate of Exemption",
            "File is required and must be a PDF": "File is required and must be a PDF.",
            "A valid phone number is required": "A valid phone number is required.",
            "Country is required": "Country is required.",
            "A valid zip is required": "A valid zip is required.",
            "State is required": "State is required.",
            "City is required": "City is required.",
            "Address is required": "Address is required.",
            "ein_error": "A valid EIN is required. Format: xx-xxxxxxx",
            "Company name": "Company name",
            "State": "State",
            "Zip": "Zip",
            "Country": "Country",
            "Tax form": "Tax form",
            "Tax form submitted": "Tax form submitted",
            "Submitting tax form": "Submitting tax form...",
            "Shipping Address": "Shipping Address",
            "User Name": "User Name",
            "Sign Out": "Sign Out",
            "details": "details",
            "Material": "Material",
            "Need tax exemption?": "Need tax exemption?",
            "Request tax exemption": "Request tax exemption",
            "Contact information updated correctly!": "Contact information updated correctly!",
            "Your Cart is Empty": "Your Cart is Empty",
            "artwork_error": "Il semble y avoir un problème avec l'illustration de ce produit. Veuillez compléter votre achat et nous vous contacterons pour les prochaines étapes.",
            "Sides": "Sides",
            "quantity_discount": "You're receiving a quantity discount on this product based on similar items in your cart!",
            "overlamination": "overlamination",
            "material": "material",
            "size": "size",
            "sides": "sides",
            "taille": "size",
            "matériau": "material",
            "côtés": "sides",
            "pack size & type": "pack size & type",
            "unwind direction": "unwind direction",
            "type et format d’emballage": "pack size & type",
            "sens du déroulement": "unwind direction",
            "Terms and Conditions": "Terms and Conditions",
            "Privacy Policy": "Privacy Policy",
            "Name": "Name",
            "checkout": "checkout",
            "gfs_customer_number": "GFS Sales Rep or Customer #"
        }
    }

}

export default module;