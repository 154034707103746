import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import { Switch, Route } from "react-router-dom";
import LeftAccountMenu from "./left-account-menu";
import OrderSummary from "./order-summary";
import OrderDetail from "./order-detail";
import ArtworkList from "./artwork/list";
import AccountSettings from "../account/account-settings"
import withTranslate from "react-redux-multilingual/lib/withTranslate";

const AccountLayout = ({translate}) => {

    const isClient = typeof window === 'object';
    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getSize());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const renderMobileView = () => {
        return <>
            <div className="row">
                <div className="col">
                    {renderSwitch()}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className="dashboard-right col-lg-12">
                        <div className="dashboard">
                            <LeftAccountMenu />
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const renderDesktopView = () => {
        return <div className="row">
            <div className="col-lg-4 pr-lg-3">
                <div>
                    <LeftAccountMenu />
                </div>
            </div>
            <div className="col">
                {renderSwitch()}
            </div>
        </div>
    }

    const renderSwitch = () => {
        return <Switch>
            <Route
                exact
                path={`/account/orders/summary`}
                component={OrderSummary}
            />
            <Route
                exact
                path={`/account/orders/summary/:id`}
                component={OrderDetail}
            />
            <Route
                exact
                path={`/account/artwork`}
                component={ArtworkList}
            />
            <Route
                exact
                path={`/account`}
                component={AccountSettings}
            />
        </Switch>
    }

    return (
        <div>
            {/*SEO Support*/}
            <Helmet>
                <title>{translate("My Account")}</title>
                <meta name="description" content="" />
            </Helmet>
            {/*SEO Support End */}

            <Breadcrumb title={translate("MY ACCOUNT")} />

            <section className="section-b-space account-page">
                <div className="collection-wrapper">
                    <div className="container">
                        {windowSize.width <= 1024 ? renderMobileView() : renderDesktopView()}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withTranslate(AccountLayout);
