import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import store from '../../../store';
import {filterSort} from '../../../actions';
import { useSelector } from 'react-redux';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';

const SortingBar = ({onProductsSorted, products, sortProducts, translate}) => {

  // const [sortBy, setSortBy] = useState('');
  const {sortBy} = useSelector(state =>  state.filters);

  useEffect(() => {
    const newProducts = sortProducts(products);
    onProductsSorted(newProducts);
  }, [sortBy]);

  const setSortBy = (value) => {
    store.dispatch(filterSort(value));
  }

  return (
    <div className="product-filter-content d-flex justify-content-between"
    >
      <h5 className="desktop-title"
          style={{fontSize: '14px', color: "#777777", lineHeight: "0", fontFamily: "Roboto", paddingTop: '10px'}}>
        {translate("Showing # Products", {count: products.length})}
      </h5>

      <div className="product-page-filter" style={{marginTop: '-20px '}}>
        <select style={{padding: '0px !important'}}
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}>
          <option value="">{translate("Sort products")}</option>
          <option value="HighToLow">{translate("High to Low")}</option>
          <option value="LowToHigh">{translate("Low to High")}</option>
          <option value="Newest">{translate("Newest Items")}</option>
          <option value="AscOrder">{translate("Sort By Name Asc")}</option>
          <option value="DescOrder">{translate("Sort By Name Desc")}</option>
        </select>
      </div>
    </div>
  );
};

SortingBar.propTypes = {
  onLayoutViewClicked: PropTypes.func.isRequired,
  onProductsSorted: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withTranslate(SortingBar);
