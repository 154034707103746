import React, {useState, useEffect} from 'react';
import Modal from "react-responsive-modal";
import {client} from "../../index";
import {createCustomerAddress, updateCustomerAddress} from "../../graphql/mutations";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import {CustomerAction} from "../../reducers/customer";
import Select from 'react-select';
import withTranslate from 'react-redux-multilingual/lib/withTranslate';
import { getCountries, getCountryByName } from '../../utils/countries';
const XMLParser = require('react-xml-parser');


const AddOrUpdateAddressModal = ({addressId, open, onClose, translate}) => {

    const {loggedInUserDetails, customerAccessToken} = useSelector(state => ({
        loggedInUserDetails: state.customer.loggedInUserDetails,
        customerAccessToken: state.customer.customerAccessToken
    }));

    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity,] = useState('');
    const [country, setCountry,] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [province, setProvince] = useState('');
    const [zip, setZip] = useState('');
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const customer = useSelector(state => state.customer);

    useEffect(() => {
        const countries = getCountries();
        setCountries(countries);
        let countryName = "United States";
        let provinces = [];
        if (addressId){
            const index = loggedInUserDetails.addresses.findIndex((address) => address.id === addressId);
            if (index >= 0){
                const address = loggedInUserDetails.addresses[index];
                setAddress1(address.address1);
                setAddress2(address.address2);
                setFirstName(address.firstName);
                setLastName(address.lastName);
                setPhone(address.phone);
                setCity(address.city);
                setProvince(address.province);
                setCountry(address.country);
                setZip(address.zip);
                countryName = address.country;
            }
        } else {
            setAddress1("");
            setAddress2("");
            setFirstName("");
            setLastName("");
            setPhone("");
            setCity("");
            setCountry(countryName);
            setZip("");
            const country = getCountryByName(countryName);
            if (country) {
                setProvinces(country.provinces);
                setProvince(country.provinces[0].name);
            }
        }
        const country = getCountryByName(countryName);
        if (country) {
            provinces = country.provinces;
            setProvinces(provinces);
            if (!addressId) {
                setProvince(country.provinces[0].name);
            }
        }
    }, [addressId]);
    const onChange = (value)=>{
        setRecaptchaVerified(value);
    }
    const countryOnChange = (country) => {

        if (country) {
            setCountry(country);
            const countryFound = getCountryByName(country);
            if (countryFound) {
                setProvinces(countryFound.provinces);
                setProvince(countryFound.provinces[0].name);
            }
        }

    }

    const addOrUpdateAddress = async() => {
        if (firstName === "" || lastName=== ""|| phone === ""|| address1 === ""|| country === ""|| zip === "") {
            toast.error('Please fill all mandatory fields.');
            return false;
        }
        if(!recaptchaVerified){
            toast.error('Please verify recaptcha');
            return false;
        }
        if (country === "United States") {
            try {
                const url = `https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID="${process.env.REACT_APP_USPS_USERID}"><ZipCode ID="0"><Zip5>${zip}</Zip5></ZipCode></CityStateLookupRequest>`;
                const result = await fetch(url);
                const resultText = await result.text();
                const parsedResult = await new XMLParser().parseFromString(resultText);
                if(parsedResult.getElementsByTagName("ZipCode")[0].getElementsByTagName("Error").length){
                    toast.error('Please provide a valid Zip and State');
                    return false;
                }
                const fetchedState = parsedResult.getElementsByTagName("ZipCode")[0].getElementsByTagName("State")[0].value;
                const selectedStateObj = provinces.find(state => state.name == province);
                if(fetchedState != selectedStateObj.abbreviation){
                    toast.error('Please provide a valid Zip and State');
                    return false;
                }
            } catch (err) {
                toast.error("Error while validating your address, please try again.");
                console.log(err);
                return false;
            }
        }
        if (!addressId) { // create address
            client.send(createCustomerAddress(customerAccessToken.accessToken, {
                firstName,
                lastName,
                address1,
                address2,
                city,
                country,
                phone,
                province,
                zip
            })).then(({model, data}) => {
                console.log(model);
                if (model.customerAddressCreate && model.customerAddressCreate.customerAddress){
                    toast.success("Address added Successfully");
                    CustomerAction.fetchCustomerDetails(customerAccessToken.accessToken);
                    onClose();
                }else if(model.customerAddressCreate && model.customerAddressCreate.customerUserErrors){
                    toast.error(model.customerAddressCreate.customerUserErrors[0].message);
                }else{
                    toast.error('There was a problem in updating your address.');
                }
            })

        } else {
            client.send(updateCustomerAddress(customerAccessToken.accessToken, addressId, {
                firstName,
                lastName,
                address1,
                address2,
                city,
                country,
                phone,
                province,
                zip
            })).then(({model, data}) => {
                if (model.customerAddressUpdate
                    && model.customerAddressUpdate.customerAddress
                    && model.customerAddressUpdate.customerAddress.id){
                    toast.success("Address updated Successfully");
                    CustomerAction.fetchCustomerDetails(customerAccessToken.accessToken);
                    onClose();
                }else if(model.customerAddressUpdate && model.customerAddressUpdate.customerUserErrors){
                    toast.error(model.customerAddressUpdate.customerUserErrors[0].message);
                }else{
                    toast.error('There was a problem in updating your address');
                }
            })
        }
    };


    return (
        <Modal open={open} center onClose={onClose} styles={{width: '800px'}}>
            <div className="modal-dialog add-update-modal modal-lg modal-dialog-centered" role="document">
                <div className="modal-content quick-view-modal">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 rtl-text">
                                <div className="product-right">
                                    <h2 className="text-capitalize"> {addressId ? translate('Update address') : translate('Add New Address')} </h2>
                                    <div className="border-product">

                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={firstName}
                                                   onChange={e => setFirstName(e.target.value)}
                                                   placeholder={translate("First name")} required/>
                                                   <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={lastName}
                                                   onChange={e => setLastName(e.target.value)}
                                                   placeholder={translate("Last Name")} required/>
                                                   <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={phone}
                                                   onChange={e => setPhone(e.target.value)}
                                                   placeholder={translate("Phone number")} required/>
                                                   <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="text" className="form-control"
                                                   value={address1}
                                                   onChange={e => setAddress1(e.target.value)}
                                                   placeholder={translate("Address Line 1")} required/>
                                                   <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={address2}
                                                   onChange={e => setAddress2(e.target.value)}
                                                   placeholder={translate("Address Line 2")} required/>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={city}
                                                   onChange={e => setCity(e.target.value)}
                                                   placeholder={translate("City")} required/>
                                                   <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div style={{width: "98%"}}>
                                            <Select
                                                value={{ label: province, value: province }}
                                                onChange={selection => setProvince(selection.value)}
                                                options={provinces.map(state => ({ label: state.name, value: state.name }))}
                                            />
                                            </div>
                                            <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div style={{width: "98%"}}>
                                                <Select 
                                                    value={{label: country, value: country}}
                                                    onChange={selection => countryOnChange(selection.value)}
                                                    options={countries.map(c => ({label: c.country, value: c.country}))}
                                                />
                                            </div>
                                            <span className="req">*</span>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={zip}
                                                   onChange={e => setZip(e.target.value)}
                                                   placeholder={translate("Zip Code")} required/>
                                                   <span className="req">*</span>
                                        </div>
                                    </div>
                                    <ReCAPTCHA
                                        sitekey={customer.recaptchaKey}
                                        onChange={onChange}
                                    />
                                    <div className="product-buttons mt-3">
                                        <button
                                            className="btn btn-primary text-capitalize"
                                            onClick={() => addOrUpdateAddress()}>
                                            {addressId ? translate('Update address') : translate('Add address')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default withTranslate(AddOrUpdateAddressModal);
